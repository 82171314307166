import { Typography,Box } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';

import './ErrorsField.scss';
import { useUser } from '../../services/userContext';

const ErrorsField = (props) => {

  const {isDebug} = useUser()
  const {field, errors} = props

  return (<>
  {field && field.errors && 
    <Box sx={{marginY:1,display:'flex',gap:0.5,alignItems:'center',color:'#ED5052'}}>
      <Box><InfoIcon sx={{width:'14px',height:'14px'}} fontSize='small' /></Box>
      <Box>
      {field && field.errors.map((error,key)=>(
        <Typography key={key} sx={{fontSize:'11px',fontWeight:400,color:'#ED5052'}}>{error}</Typography>
      ))}
      </Box>
    </Box>
  }
  {isDebug && JSON.stringify(errors)}
  {errors && !field?.errors && errors.length > 0 && 
    <Box sx={{marginY:1,display:'flex',gap:0.5,alignItems:'center',color:'#ED5052'}}>
      <Box><InfoIcon sx={{width:'14px',height:'14px'}} fontSize='small' /></Box>
      <Box>
      {errors.length > 0 &&
        <Typography sx={{fontSize:'11px',fontWeight:400,color:'#ED5052'}}>{errors[0]}</Typography>
      }
      </Box>
    </Box>
  }
  </>
)};



export default ErrorsField;
