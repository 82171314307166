
const navItems = [
    {
     title:'Tableau de bord',
     slug:'dashboard',
     link:'/dashboard',
    },
    {
      title:'Dossier administratif',
      slug:'dossier-administratif',
      link:'/dossier-administratif',
      form_group_id:1
     },
     {
      title:'Découverte point de vente',
      slug:'decouverte-point-de-vente',
      link:'/formulaire/decouverte-point-de-vente',
     },
     {
      title:'Dossier financier',
      slug:'dossier-financier',
      link:'/dossier-financier',
      form_group_id:3
     },
     {
      title:'Mes alertes',
      slug:'alertes',
      link:'/alertes',
     },
     {
      title:'Contactez-nous',
      slug:'contact',
      link:'/contact',
     }
  ]
  
export default navItems;

  