import React from 'react';
import './FieldInfo.scss';
import AlertInfoCustom from '../AlertInfoCustom/AlertInfoCustom';
import { Typography } from '@mui/material';

const FieldInfo = (props) => {
  const {field} = props
  return (
    <AlertInfoCustom>
      <Typography sx={{fontWeight:500,color:'#115479'}}>{field.label}</Typography>
    </AlertInfoCustom> 
)};

export default FieldInfo;
