import axios from "axios"
import { getTokenDataInLocalStorage } from "./localStorage";

const api = axios.create({
    baseURL : process.env.REACT_APP_API_URL
});



// Gestion de la validité des token

/*
const testIsValidToken = () => {
    const {saveDate} = getTokenDataInLocalStorage()
    console.log('saveDate',saveDate)
}
*/

/* API User */


export function updatePassword(email,token,password){
    const body = {email:email,token:token,password:password}
    return api.post("auth/reset-password",body).then((r) => r.data)
}


export function forgotPassword(email){
    const body = {email:email}
    return api.post("auth/forgot-password",body).then((r) => {return {message:r.data.message,success:true}}).catch((error)=>{return {message:error.response.data.message,error:true}})
}


export function getLoginToken(email,password){
    const body = {email:email,password:password}
    return api.post("auth/login",body).then(
        (r) => r.data
        ).catch(
            (error)=>{return error.response.data}
        )
}

export function logOutToken(token){
    return api.post("auth/logout",{},{headers:{'Authorization':'Bearer '+token}}).then((r) => r.data)
}

export async function getRefreshToken(mail){
    const {token} = getTokenDataInLocalStorage()
    let tokenRefreshed = null
    if(token){
        tokenRefreshed = await api.post("auth/refresh",{mail:mail},{headers:{'Authorization':'Bearer '+token}}).then((r) => r.data)
    }
    console.log('Refresh token',tokenRefreshed)
    return tokenRefreshed
}

export function getCandidateMe(){
    const {token} = getTokenDataInLocalStorage()
    return api.get("/candidates/me",{headers:{'Authorization':'Bearer '+token}}).then((r) => r.data)
}

export function getCandidacy(idCandidacy,tokenProps){
    const token = tokenProps || getTokenDataInLocalStorage().token
    return api.get("/candidacies/"+idCandidacy,{headers:{'Authorization':'Bearer '+token}}).then((r) => r.data)
}


// API Notifications

export function getNotifications(){
    const { token } = getTokenDataInLocalStorage()
    return api.get("/notifications",{headers:{'Authorization':'Bearer '+token}}).then((r) => r.data) 
}

export function markAsReadNotifications(id){
    const { token } = getTokenDataInLocalStorage()
    return api.patch("/notifications/"+id,{id:id,is_read:1},{headers:{'Authorization':'Bearer '+token}}).then((r) => r.data) 
}
/* API Form group */

export function getFormGroup(slugFormGroup){
    const {token} = getTokenDataInLocalStorage()
    return api.get(`form_groups/${slugFormGroup}`,{headers:{'Authorization':'Bearer '+token}}).then(
        (r) => r.data
        ).catch(
            (error)=>{return error.response.data}
        )
}

export function sendFormGroup(slugFormGroup){
    const {token} = getTokenDataInLocalStorage()
    return api.post(`form_groups/${slugFormGroup}`,{},{headers:{'Authorization':'Bearer '+token}}).then(
        (r) => r.data
        ).catch(
            (error)=>{return error.response.data}
        )
}

export async function getForm(idFormulaire,candidate_id){
    const {token} = getTokenDataInLocalStorage()
    let form = await api.get(`forms/${idFormulaire}`,{params: candidate_id ? {answers_target_id:candidate_id} : {},headers:{'Authorization':'Bearer '+token}}).then((r) => r.data).catch(
        (error)=>{return error.response.data}
    )
    if(form.message){
        return {...form, error:true}
    }else if(form){
        const formGroup = await getFormGroup(form.form_group_id)
        if(formGroup){
            form.form_group_name = formGroup.name
            form.form_group_description = formGroup.description
        }
        return form
    }else{
        return false
    }
    
}



export function sendForm(slug,data,onUploadProgress,maxSizeOfRequest){
    const {token} = getTokenDataInLocalStorage()

    const config = {
        headers:{
            'Authorization':'Bearer '+token,
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress: onUploadProgress,
        maxContentLength: maxSizeOfRequest || false,
        maxBodyLength: maxSizeOfRequest || false
    }
    
    return api.post(`forms/${slug}`,data,config).then(
        (r) => {    
        return r.data}
        ).catch(
            (error)=>{
                return error.response.data}
        )
}

/* API Area */

export function getArea(area_id){
    const {token} = getTokenDataInLocalStorage()
    return api.get("/areas/"+area_id,{headers:{'Authorization':'Bearer '+token}}).then((r) => r.data) 
}