import React, { useState,useEffect } from 'react';
import jMoment from 'moment-jalaali';
import TextFieldCustom from '../../TextFieldCustom/TextFieldCustom';
import ErrorsField from '../../ErrorsField/ErrorsField';
import { useUser } from '../../../services/userContext';
import { uniqueId } from 'lodash';


const FieldDate= (props) => {
  const {field,onChange} = props
  const {isDebug} = useUser()

  const [value,setValue] = useState(field.value)


  const [error,setError] = useState(field.errors)

  useEffect(()=>{
    setValue(field.value)
    if(field.errors){
      console.log('Le champ reçoit une erreur',field.errors);
      setError(field.errors)
    }
  },[field])


  // Tigger change in field
  useEffect(()=>{
    onChange(field.name,value)
    // eslint-disable-next-line
  },[value])

  const handleChange = (event) =>{
    const value = event.target.value
    const date = jMoment(value).format('Y-MM-DD')
    console.log('handleChangeDate',date)
    if(date !== 'Invalid date'){
      setError(null)
    }else{
      setError('Le champ '+field.label+' ne correspond pas au format requis')
      console.error('Invalid date !')
    }
    setValue(date)
    
  }


  return (
    <>
      {isDebug && field.name + ' : ' + field.value}
      
      <TextFieldCustom  name={uniqueId(field.name)} max={'2100-12-31'} isRequired={field.isRequired} type="date" isError={field.errors ? true : false} label={field.label} value={value} onChange={handleChange} fullWidth/>
      {error && 
      <>
        <ErrorsField field={field} errors={error ? [error] : null} />
      </>
      }

    </>
  );
};

export default FieldDate;

