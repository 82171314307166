import React, { useState } from 'react';
import './DebugStorage.scss';
import AppLayout from '../AppLayout/AppLayout';
import { TableContainer, TableHead, Typography, Table, TableRow, TableCell, TableBody, Paper, Box, Button} from '@mui/material';
import { emptyValuesInStorage, getValuesInStorage } from '../../services/localStorage';
import { useNotifications } from '../../services/notificationsContext';

const DebugStorage = () => {

  const [values,setValues] = useState(getValuesInStorage() || [])

  const {pushNotifications} = useNotifications()

  const handleRefresh = () => {
    setValues(getValuesInStorage())
    pushNotifications('Stockage rafraichi !','success')
  }

  const handleEmpty = () => {
    pushNotifications('Stockage vidé !','success')
    emptyValuesInStorage()
    setValues(getValuesInStorage())
  }
 
  return (
  <AppLayout>
    <Typography variant="h1">Debug du Stockage local</Typography>

    <Box sx={{marginY:3,display:'flex',gap:2}}>
      <Button onClick={handleRefresh} variant="contained">Actualiser le stockage local</Button>
      <Button onClick={handleEmpty}  variant="contained">Vider le stockage local</Button>

    </Box>

    <TableContainer sx={{marginTop:4}} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Form</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Source</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((val,key)=>(
            <TableRow key={key}>
              <TableCell >{val.name}</TableCell>
              <TableCell>{String(val.value)}</TableCell>
              <TableCell>{val.form}</TableCell>
              <TableCell>{val.type}</TableCell>
              <TableCell>{val.source}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Box sx={{marginY:3,display:'flex',gap:2}}>
      <Button onClick={handleRefresh} variant="contained">Actualiser le stockage local</Button>
      <Button onClick={handleEmpty}  variant="contained">Vider le stockage local</Button>

    </Box>

  </AppLayout>
)};

export default DebugStorage;
