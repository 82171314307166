import React from "react";
import { A, Box, Item, Span } from "react-html-email";
import MailContainer, { Button } from "./MailContainer";

const RenouvellementMDP = (props) => {

    return (
        <MailContainer>
            <Box align="center" style={{paddingTop:'35px'}}>
                <Item>
                    <Span style={{fontWeight:'600',fontSize:'18px',color:'black'}}>Bonjour [identite]</Span>
                </Item>
            </Box>

            <Box style={{width:'100%',paddingLeft:'10px',paddingRight:'10px'}}>
                <Item>
                    <Box style={{width:'100%',backgroundColor:'white',paddingTop:'50px',paddingBottom:'50px',paddingLeft:'40px',paddingRight:'40px',borderRadius:'12px',marginTop:'35px',marginBottom:'35px',boxShadow:'0px 6px 16px 0px #00000014',textAlign:'center',fontWeight:'400',fontSize:'18px'}}>
                        <Item>
                            <Box align="center" style={{paddingBottom:'20px'}}>
                                <Item>
                                    <Span style={{fontWeight:'600'}}>
                                    Vous trouverez ci-dessous le lien de réinitialisation de votre mot de passe à votre espace de recrutement :
                                    </Span>
                                </Item>
                            </Box>

                            <Box align="center" style={{paddingBottom:'20px'}}>
                                <Item>
                                    <Span>
                                        <A href={'[lien]'} style={{fontWeight:400,color:'#616161',textDecoration:'italic'}}>[lien]</A>
                                    </Span>
                                </Item>
                            </Box>

                            <Box align="center" style={{paddingTop:'20px',paddingBottom:'0px'}}>
                                <Item>
                                    <Button href={'[lien]'}>Réinitialiser</Button>
                                </Item>
                            </Box>
                        </Item>
                    </Box>
                </Item>
            </Box>
        </MailContainer>
    )
}

export default RenouvellementMDP;

