import { useTheme } from '@emotion/react';
import { Box } from '@mui/system';
import React from 'react';

import './CardRounded.scss';

const CardRounded = (props) => {
  const theme = useTheme()
  return(
  <Box sx={{marginY:1,padding:3,backgroundColor:'#F5F5F5',border:'1px solid #EDEDED',borderRadius:theme.shape.borderRadius}}>
    {props.children}
  </Box>
)};

export default CardRounded;
