import { ThemeProvider,createTheme  } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { defaultTheme } from "./assets/themes/defaultTheme";
import Alertes from "./pages/Protected/Alertes/Alertes";
import ConfirmationPassword from "./pages/Connexions/ConfirmationPassword/ConfirmationPassword";
import Connexion from "./pages/Connexions/Connexion/Connexion";
import ResetPassword from "./pages/Connexions/ResetPassword/ResetPassword";
import PremiereConnexion from "./pages/Connexions/PremiereConnexion/PremiereConnexion";
import Dashboard from "./pages/Protected/Dashboard/Dashboard";
import Contact from "./pages/Protected/Contact/Contact";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Dossier from "./pages/Protected/Dossier/Dossier";
import Formulaire from "./pages/Protected/Formulaire/Formulaire";
import { UserProvider} from "./services/userContext";
import './App.scss'
import GenerateEmail from "./pages/GenerateEmail/GenerateEmail";
import { NotificationsContainer, } from "./services/notificationsContext";
import MentionsLegales from "./pages/MentionsLegales/MentionsLegales";
import PolitiqueDonnees from "./pages/PolitiqueDonnees/PolitiqueDonnees";
import DebugStorage from "./components/DebugStorage/DebugStorage";

import Page404 from "./pages/Page404/Page404";
import DebugPage from "./components/debugPage/debugPage";

function App() {
  const themeMUI = createTheme(defaultTheme)

  return (
    <ThemeProvider theme={themeMUI}>
          <NotificationsContainer>
              <UserProvider>
                <BrowserRouter>
                    <Routes>
                      <Route index path="/" element={<Connexion  isHome />} />
                      <Route path="/connexion" element={<Connexion isHome />} />
                      <Route path="confirmation-password/:token" element={<ConfirmationPassword isPage/>} />
                      <Route path="premiere-connexion" element={<PremiereConnexion title={'Mon espace recrutement'} type="premiere-connexion" textButton={'Définir mon mot de passe'} isPage/>} />
                      <Route path="mot-de-passe-oublie" element={<PremiereConnexion title={'Mot de passe oublié'} type="mot-de-passe-oublie" textButton={'Réinitaliser mon mot de passe'} isPage/>} />
                      <Route path="reset-password/:type/:token/:mail" element={<ResetPassword />} />
                      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                      <Route path="/alertes" element={<ProtectedRoute><Alertes /></ProtectedRoute>} />                    
                      <Route path="/contact" element={<ProtectedRoute><Contact /></ProtectedRoute>}/>
                      <Route path="/mentions-legales" element={<ProtectedRoute><MentionsLegales /></ProtectedRoute>} />
                      <Route path="/politique-donnees" element={<ProtectedRoute><PolitiqueDonnees /></ProtectedRoute>} />
                      
                      <Route path="/dossier-administratif" element={<ProtectedRoute><Dossier name={'dossier-administratif'} /></ProtectedRoute>} />
                      <Route path="/dossier-financier" element={<ProtectedRoute><Dossier name={'dossier-financier'} /></ProtectedRoute>} />
                      <Route path="/formulaire/:idFormulaire" element={<ProtectedRoute><Formulaire /></ProtectedRoute>} />
                      <Route path="/formulaire/:idFormulaire/:idTarget" element={<ProtectedRoute><Formulaire /></ProtectedRoute>} />
                      
                      {process.env.REACT_APP_ENV === 'dev' && <Route path="generateEmails" element={<GenerateEmail />} /> }
                      {process.env.REACT_APP_ENV === 'dev' && <Route path="debug-storage" element={<DebugStorage />} /> }
                      {process.env.REACT_APP_ENV === 'dev' && <Route path="debug-page" element={<DebugPage />} />}
                      
                      <Route path="/*" element={<Page404 />} />
                      
                    </Routes>
                </BrowserRouter>
              </UserProvider>
        </NotificationsContainer>
    </ThemeProvider>
  );
}

export default App;
