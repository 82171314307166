import React from 'react';
import { Dialog,Box, Typography, LinearProgress } from '@mui/material';
import { useForm } from '../../services/formContext';


const ModalSendingFormulaire = (props) => {
  const {open} = props
  const {progress} = useForm()


  return (
  <Dialog maxWidth="md" open={open} fullWidth>
  <Box sx={{backgroundColor:'white',borderRadius:1,paddingY:[2,2,10],paddingBottom:[2,2,10],paddingX:[2,2,8],textAlign:'center'}}>
      <Box>
        <Typography variant='h1'>Envoi en cours de votre dossier</Typography>
      </Box>
      <Box sx={{marginTop: 1,marginBottom: 2}}>
        <Typography variant="body1">Veuillez ne pas quitter le site web le temps de l’envoi de votre dossier. </Typography>
      </Box>
      <Box sx={{display:'flex', alignItems:'center',gap:1,marginTop:4}}>
        <Box sx={{width:'calc(100% - 50px)'}}>
          <LinearProgress variant="determinate" value={progress ? progress : 0}  />
        </Box>
        <Box>
          <Typography variant="body1">{progress}%</Typography>
        </Box>
      </Box>
  </Box>
</Dialog>
)};



export default ModalSendingFormulaire;
