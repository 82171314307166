import React from 'react';
import './AppLayout.scss';
import { Box } from '@mui/system';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import LoadingFullPage from '../LoadingFullPage/LoadingFullPage';


const AppLayout = (props) => {

 
  const {isLoading} = props

  /*useEffect(()=>{
    if(!isConnect){
      navigate('/')
    }
    if(tokenIsRevoked()){
      refreshToken()
    }
    // eslint-disable-next-line
  },[])*/
  


  return(
  <Box className="app-layout">
    <Sidebar />
    <Box className="container">
      <Box className={isLoading ? "content loading" :"content" }>
        {isLoading && <LoadingFullPage />}
        {!isLoading && props.children}
      </Box>
      <Footer />
    </Box>
  </Box>
)};


export default AppLayout;
