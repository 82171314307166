import React,{useEffect, useState} from 'react';

import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import Field from '../Field/Field';
import { replaceIndex} from '../../../services/utils';
import { useForm } from '../../../services/formContext';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import getSizeField from '../../../services/getSizeField';

const SubFieldSetCard = (props) => {
  const {field,index, deleteRow} = props
  const {filterField} = useForm()

  const handleDelete = (index) => {
    deleteRow(index)
  }

  
  if(field.title){
    return(
      <Box sx={{backgroundColor:'#EDEDED', border:'1px solid', borderColor:'#E0E0E0', borderRadius:'6px',paddingX:[2,2,4],paddingY:3,marginY:3}}>
        <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:1}}>
          <Typography sx={{fontWeight:500, fontSize:'18px'}} variant="body2">{replaceIndex(field.title,(index+1).toString())}</Typography>
          {index > 0 &&
          <Box sx={{color:'#880000',cursor:'pointer',display:'flex',alignItems:'center',zIndex:3,gap:0.5}} onClick={()=>{handleDelete(index)}}>
            <DeleteOutlineIcon fontSize="small"/>
            <Typography sx={{color:'#880000',fontWeight:500}}>Supprimer</Typography>
          </Box>
          
          }
        </Box>

        {field.description && 
          <Typography sx={{marginY:2}}>{field.description}</Typography>
        }
        <Grid container spacing={2}>
        {field.fields.map((field,key)=>(
          <SubFieldSetCardField field={field} key={key} index={index}/>
        ))}
        </Grid>

      </Box>
    )
  }


  if(!field.title){
    return(
      <Box sx={{marginBottom:2}}>
        <Grid container spacing={2} sx={{display:'flex',alignItems:'flex-end'}}>
        {field.fields.map((field,key)=>(
          <Grid key={key} item xs={index > 0 ? 5 : 6}>
              <Field field={filterField(field,index)} parent="subFieldSetCard" />
          </Grid>
        ))}
        {index > 0 &&
          <Grid item xs={2} sx={{paddingTop:0,paddingBottom:0}} className='testtt'>          
            <Box sx={{color:'#880000',cursor:'pointer',display:'flex',alignItems:'center',zIndex:3,gap:0.5}} onClick={()=>{handleDelete(index)}}>
              <DeleteOutlineIcon fontSize="small"/>
              <Typography sx={{color:'#880000',fontWeight:500}}>Supprimer</Typography>
            </Box>
          </Grid>
          
        }
        </Grid>

      </Box>
    )
  }




};


export default SubFieldSetCard;


const SubFieldSetCardField = (props) => {

  const {field,index} = props
  const {isHiddenField,filterField,form,needReload} = useForm()
  const [isHidden,setIsHidden] = useState(isHiddenField(field))

  useEffect(()=>{
    const isHidden = isHiddenField(filterField(field,index))
    setIsHidden(isHidden)

    // eslint-disable-next-line
  },[needReload])


  return !isHidden && (
        <Grid item xs={12} md={getSizeField(field,'subFieldSetCard',form)}>
            <Field field={filterField(field,index)} parent="subFieldSetCard" />
        </Grid>
  )


}
