import React,{useState} from 'react';
import './FieldTextarea.scss';
import { Box, Typography } from '@mui/material';
import ErrorsField from '../../ErrorsField/ErrorsField';

const FieldTextarea = (props) => {
  const {field,onChange} = props

  const [errors,setErrors] = useState(null)

  const handleChange = (value) => {
    console.log(value,value.length,field)
    if(field.maxlength && field.maxlength > value.length){
      onChange(field.name,value)
      setErrors([])
    }else if(!field.maxlength){
      onChange(field.name,value)
    }else{
      setErrors([`Le champs ne peux pas dépasser ${field.maxlength} carractères.`])
    }
    
  }

  return (
    <Box className="FieldTextarea">
      <Typography sx={{marginBottom:4}} variant="inputLabel">{field.label} {field.isRequired && '*'}</Typography>
      <Box sx={{marginTop:0.5}} >
      <textarea placeholder={field.placeholder} value={field.value || ''} onChange={(e)=>{handleChange(e.target.value)}}>
      </textarea>
      </Box>
      {field.help && <Typography>{field.help}</Typography>}
      <ErrorsField errors={errors} />
    </Box>
  )
};

export default FieldTextarea;
