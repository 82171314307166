import React from "react";
import "./FieldText.scss";
import TextFieldCustom from "../../TextFieldCustom/TextFieldCustom";
import ErrorsField from "../../ErrorsField/ErrorsField";
import { useUser } from "../../../services/userContext";

const FieldText = (props) => {
  const { field, onChange } = props;
  const {isDebug} = useUser()
  const handleChange = (e) => {
    onChange(field.name, e.target.value ? e.target.value : undefined,'text');
  };

  return (
    <>
      {isDebug && field.name+' : '+field.value +' - '+(typeof field.value) + ' - '+field.type+ ' - '+field.source}
      <TextFieldCustom
        readOnly={field.read_only ? true : false}
        isError={field.errors ? true : false}
        onChange={handleChange}
        name={field.name}
        label={field.label}
        value={field.value}
        isRequired={field.isRequired}
        fullWidth
      />
      <ErrorsField field={field} />
    </>
  );
};

export default FieldText;
