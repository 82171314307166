import React from 'react';
import { Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const AlerteWarningCustom = (props) => (
    <Box sx={{marginY:2,backgroundColor:'#FFF0B2',border:'1px solid #EEC313',borderRadius:'12px',padding:[2,2,3],display:'flex',gap:2, alignItems:'center'}}>  
        <Box>
            <WarningIcon fontSize="small" style={{color:'#855C00'}} />
        </Box>
        <Box sx={{color:'#115479'}}>
        {props.children}
        </Box>
      </Box>
);


export default AlerteWarningCustom;
