import {useEffect,useState} from "react"

export const useCurrentWindow = (document) => {

    const [isCurrentWindow,setIsCurrentWindow] = useState(false)

    useEffect(()=>{
        document.addEventListener( 'visibilitychange' , function() {
            if (document.hidden) {
                setIsCurrentWindow(false)
            } else {
                setIsCurrentWindow(true)
            }
        }, false );

    },[setIsCurrentWindow,document])

    return isCurrentWindow
}