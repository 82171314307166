import React from 'react';
import { useUser } from "../../services/userContext";
import { TableContainer, TableHead, Typography, Table, TableRow, TableCell, TableBody, Paper, Box,Button} from '@mui/material';
import { copyFormatted, getBrowserName } from '../../services/utils';
import { useNotifications } from '../../services/notificationsContext';



export const DebugInfos = () => {

  const {isConnect,user,isDebug,candidacy,getTypeParcours} = useUser()

  const tab = {}

  // Infos du navigateurs
  tab.window_height = window.innerHeight
  tab.window_width = window.innerWidth
  tab.user_agent = window.navigator.userAgent;
  tab.navigator =  getBrowserName(window.navigator.userAgent);
  tab.os =  navigator?.userAgentData?.platform || window?.navigator?.platform;
  tab.current_url = window.location.href


  // Infos de contexte 
  tab.api_url = process.env.REACT_APP_API_URL;
  tab.env = process.env.REACT_APP_ENV;
  tab.server_timezone = process.env.REACT_APP_SERVER_TIMEZONE;
  tab.time = new Date()

  // Infos sur l'utilisateur
  tab.isConnect = isConnect
  //tab.token = token 

  tab.candidate_id = user.id
  tab.candidacy_id = user.candidacy_id
  tab.mercure_id = user.mercure_id
  tab.email = user.email
  tab.isDebug = isDebug
  tab.parcours = getTypeParcours()

  tab.candidacy_next_step = candidacy.next_step
  tab.php_max_size = String(Number(process.env.REACT_APP_PHP_MAX_SIZE)/1000000 +' mo')


  return tab
}

export const DebugInfosDipslayJSON = () => JSON.stringify(DebugInfos())

export const DebugInfosDisplayBasicInfos = () => {
  const debugInfos = DebugInfos()

  const basicsInfos = []
  basicsInfos.push({key:'Is Debug',value:debugInfos.isDebug.toString()})
  basicsInfos.push({key:'Mercure ID',value:debugInfos.mercure_id})
  basicsInfos.push({key:'Candidacy ID',value:debugInfos.candidacy_id})
  basicsInfos.push({key:'Parcours',value:debugInfos.parcours})
  basicsInfos.push({key:'Email',value:debugInfos.email})
  basicsInfos.push({key:'Env',value:debugInfos.env})


  return (
  <Box>
    {basicsInfos.map((infos,key)=>(
      <Box key={key}>
        <Typography variant="debugInfos"><Typography variant="debugInfosKey">{infos.key} : </Typography>{infos.value}</Typography>
      </Box>
    ))}
  </Box>
)}


export const DebugInfosDisplayTable = () => {
  const debugInfos = DebugInfos()
  return (
    <TableContainer sx={{marginTop:4}} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Clé</TableCell>
            <TableCell>Valeur</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(debugInfos).map((val,key)=>(
            <TableRow key={key}>
              <TableCell>{val}</TableCell>
              <TableCell>{JSON.stringify(debugInfos[val])}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  )
}


export const ButtonCopyDebugInfos = (props) => {
  const debugInfos = DebugInfos()
  const {pushNotifications} = useNotifications()

  

  const handleClick = () => {
    copyFormatted(DebugCopyInfosHTML(debugInfos))
    pushNotifications('Informations de debug copié dans le presse-papier','success')
  }

  return (
    <Button {...props} onClick={handleClick} variant="contained">Copier les infos</Button>
  )
}


export const DebugCopyInfosHTML = (debugInfos) => {

  return (
    `<div>Informations de debug généré le ${new Date().toLocaleDateString()} : <br/>
    ${Object.keys(debugInfos).map((val)=>(`<strong>${val}</strong>: ${JSON.stringify(debugInfos[val])} <br />`)).join('')}</div>`
  )

}

