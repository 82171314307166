import React from 'react';
import './Formulaire.scss';
import AppLayout from '../../../components/AppLayout/AppLayout';
import { useParams } from 'react-router-dom';
import { FormProvider} from '../../../services/formContext';
import FormulaireLayout from '../../../components/FormulaireLayout/FormulaireLayout';

const Formulaire = () =>{

  const {idFormulaire} = useParams()

  return(
    <AppLayout>
      <FormProvider>
        <FormulaireLayout idFormulaire={idFormulaire} />
      </FormProvider>
    </AppLayout>
  );
};


export default Formulaire;
