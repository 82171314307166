import React, { useContext, useEffect, useState } from "react";
import { getForm, sendForm } from "./api";
import {
  getValuesInStorage,
  saveValuesInStorage,
  setCurrentForm,
  setCurrentFormGroup,
} from "./localStorage";
import {
  copyObject,
  replaceIndex,
  dataURLtoFile,
  hasIndexSelector,
  debugFichierTemporaires,
  isIterable,
  findPath,
  replaceNumByIndex,
  getParentPath,
  getObjectPath,
  getIndexInName,
  isNumeric,
  convertNotationBracketToDot,
  convertNotationDotToHook,
  getNameInResponse,
} from "./utils";
import _, { isArray, uniqueId } from 'lodash';
import { useNotifications } from "./notificationsContext";
import { useUser } from "./userContext";

const FormContext = React.createContext();

export const useForm = () => {
  return useContext(FormContext);
};

export const FormProvider = ({ children }) => {
  const [form, setForm] = useState(false);
  const [nameForm, setNameForm] = useState("");
  const [targetId, setTargetId] = useState("");
  const [candidateTarget, setCandidateTarget] = useState(false);
  const [responseFormulaire, setResponseFormulaire] = useState("");
  const [filesInTemp, setFilesInTemp] = useState([]);
  const [fieldCountable, setFieldCountable] = useState([]);
  const [needReload, setNeedReload] = useState();
  const [formIsLoading, setFormIsLoading] = useState();
  const [progress,setProgress] = useState(0);
  const {pushNotifications} = useNotifications()

  const [fieldsLoading,setFieldsLoading] = useState(false)

  const [fieldToUpdate,setFieldToUpdate] = useState()

  const {isDebug} = useUser()

  const reloadField = (name) => {
    setFieldToUpdate(name)
  } 
  const finishReloadField = () => {
    setFieldToUpdate('')
  }




  // Utils 
  const initForm = async (idForm, idTarget) => {
    setFormIsLoading(true);
    const name = idTarget ? `form:${idForm}:${idTarget}` : `form:${idForm}`;
    setFilesInTemp([])
    setCandidateTarget(false)
    setNameForm(name);
    setTargetId(idTarget);
    setForm(null);
    const formLayout = await getForm(idForm, idTarget ? idTarget : false);
    if(!formLayout.error){
      setForm(formLayout);
      if (idTarget) {
        initCandidateTarget(formLayout, idTarget);
      }
      
      // Vidage et Enregistrement des réponses dans le local storage
      if (formLayout?.target_answers?.answers) { 
        // Cette ligne cause le bug de non enregistrement du local storage
        //emptyForm(name)

        saveValue('lastAnswersReload',new Date(),name,'updatingTime','answers')
        saveAnswers(formLayout,formLayout.target_answers.answers,name);
      }

      setCurrentForm(formLayout);
      setCurrentFormGroup(formLayout.form_group_id);
      setFormIsLoading(false);
    }else{
      pushNotifications(formLayout.message,'error')
      setForm(formLayout);
      setFormIsLoading(false);
    }

  };


  const saveAnswers = (form,answers,nameForm,keyRecursive) => {
    if(answers){  
      Object.entries(answers).forEach(
        ([key, value], i) => {
          // Gère le cas ou c'est plusieurs valeurs à l'intéreieur
          if(isArray(value)){
            // Gérer les entrée mutliples
            let parent
            value.forEach((val,k)=>{
              const newName = keyRecursive ? `${keyRecursive}.${key}` :  `${key}.${k}`
              // On doit gérer le cas si c'est un objet (avec des sous composant) ou juste des valeurs simples
              if(typeof val === 'object'){
                saveAnswers(form,val,nameForm,newName)
              }else{
                saveValue(convertNotationDotToHook(newName), val, nameForm, "answers_saved","answers_saved")
              }

              if(!parent){
                const nameInForm = replaceNumByIndex(convertNotationDotToHook(newName+'.'+Object.keys(val)[0]))
                parent = getParentObject(nameInForm,form)
              }

            })
            // Enregistrement de le taille des informations

            /* Gérer le cas où c'est le title qui permet de gérer le comptage */
            if(parent){
              saveValue(parent.title,value.length, nameForm, "count","answers_saved")
            }else{
              saveValue(`${key}[%index%]`,value.length, nameForm, "count","answers_saved")
            }

          }else if(!isArray(value) && typeof value === 'object'){
            saveAnswers(form,value,nameForm,keyRecursive)
          }else if(keyRecursive){
            let newName = keyRecursive+'.'+key;
            saveValue(convertNotationDotToHook(newName), value, nameForm, "answers_saved","answers_saved");
          }else{
            saveValue(convertNotationDotToHook(keyRecursive)|| key, value, nameForm, "answers_saved","answers_saved");
          }
        }
      );
    }

    //reload();
  }



  /* 
  *
  * Enregistre nos valeurs dans notre storage pour suavegarder les données et traiter l'envoi plus tard 
  * 
  */

  const saveValue = (name, value, form, type, source) => {

    const date = new Date().toISOString().toLocaleString("en-US", {timeZone:'Europe/London'})

    const object = {
      name: name,
      value: value,
      form: form,
      type: type ? type : "text",
      source: source,
      saveDate:date
    };
  
    // On garde en donnée le dernier enregistrements
    if(type !== 'updatingTime' && type !== 'answers_saved' && type !== 'count' && type !== 'boolean'){
      // On se cale sur les heures du serveurs
      //onst date = new Date().toISOString().toLocaleString("en-US", {timeZone:'Europe/London'})
      saveValue('lastInputSave',new Date(),form,'updatingTime','saveValue')
    }

    const valuesInStorage = getValuesInStorage() ? getValuesInStorage() : [];
    let newValues = [];
    const isExist = valuesInStorage.find((element) => element.form === form && element.name === name)? true : false;

    if (!isExist) {
      newValues = valuesInStorage;
      newValues.push(object);
    } else {
      newValues = valuesInStorage;
      const index = valuesInStorage.findIndex(
        (element) => element.form === form && element.name === name
      );

      if(value === false){
        newValues[index].value = false;
      }else if(value === 0){
        newValues[index].value = 0;
      }else{
        newValues[index].value = value || '';
      }
      
      //newValues[index].value = value || '';
      newValues[index].type = type;
      newValues[index].source = source;
      newValues[index].saveDate = date;
    }

    // Clean des infos vide
    newValues  = newValues.filter((value) => !(value.value === null || value.name === null || value.value === ''))

    if(source !== 'saveValue'){
      //console.log(name,value || null)
    }

    if(source !== 'emptyDataInStorage' && source !== 'saveValue' && type !== 'total'){
      reload();
    }else{
      //reloadField(name)
    }



    saveValuesInStorage(newValues);
  };

  const saveMultiplesValues = (name, values, form, type, source , model, newQuantity, lengthInitialParams) => {


    let response = {}
    // On récupère la valeur
    let lengthInitial
    if(lengthInitialParams){
      lengthInitial = lengthInitialParams
    }else if(newQuantity && newQuantity !== 0){ 
      lengthInitial = newQuantity + 1
    }else if(model){
      lengthInitial = getValue(model,nameForm) || 0
    }else{
      lengthInitial = getValue(name,nameForm) || 0
    }

    response.fieldsRemoved = []

    // On vide les fileds
    for(let i = 0; i < lengthInitial;i++){
      // Get Type of field
      saveValue(replaceIndex(name,i.toString()),'empty',form,type,source)
      response.fieldsRemoved.push(replaceIndex(name,i.toString()))
    }
    


    response.fieldsSaved = []
    // Remplissage des valeurs
    values.forEach((value,key)=>{
      // On récupère l'ancien type pour pas perdre l'info
      const oldType = getTypeValue(replaceIndex(name,key.toString()),form)
      saveValue(replaceIndex(name,key.toString()),value,form,oldType,source)

      response.fieldsSaved = replaceIndex(name,key.toString())
    })

    // Enregistrement de la quantité
    if(!model){
      saveValue(model,values.length,form,'count', source)
      saveValue(name,values.length,form,'count', source)
    }

    //console.log('Saving model ?',Boolean(model && (newQuantity || newQuantity === 0)))
    if(model && (newQuantity || newQuantity === 0)){
      response.newCount = newQuantity
      response.oldCount = lengthInitial
      saveValue(model,newQuantity,form,'count', source)
    }


    return response
  }

  
  const getValue = (name, formName,type = null) => {
    //console.log('getValue',name,formName,type)
    const valuesInStorage = getValuesInStorage() ? getValuesInStorage() : [];
    const tab = valuesInStorage.find((element) => element.form === formName && element.name === name);

    
    if(tab === undefined || tab.value === 'empty'){
      return null
    }

    if(tab.type === 'text'  || type === 'text'){
      return String(tab.value)
    }
    if(isNumeric(String(tab.value)) || tab.type === "number" || type === 'text'){
      return parseInt(tab.value)
    }
    return String(tab.value)
  };

  const getTypeValue = (name, formName) => {
    const valuesInStorage = getValuesInStorage() ? getValuesInStorage() : [];
    const tab = valuesInStorage.find((element) => element.form === formName && element.name === name);
    return tab?.type
  }

  const getSaveDateOfValue = (name, formName) => {
    const valuesInStorage = getValuesInStorage() ? getValuesInStorage() : [];
    const tab = valuesInStorage.find((element) => element.form === formName && element.name === name);
    return tab?.saveDate
  }


  const getRepetableLength = (field,flag = null) => {

    //console.log('Flag getRepetableLength',flag)
  
    const answers = form.target_answers.answers
    let countInAnswers

    if(field.type === 'field_set' && answers){

     


      const children = field.fields[0].name
      const childrenDot = convertNotationBracketToDot(children)
      const nameInAnswers = getNameInResponse(childrenDot)
      countInAnswers = answers.hasOwnProperty(nameInAnswers) ? answers[nameInAnswers].length : 1

      //console.log('field set in answers',countInAnswers)
    }
    //console.log('getNameOfRepetableField',field.name || field.title)
    const valueInStorage = getValue(field.name || field.title,nameForm)

    //console.log('getRepetableLength',getMostRecentDataOrigin(),countInAnswers,valueInStorage)

    if(valueInStorage > countInAnswers){
      return valueInStorage
    }

    return getMostRecentDataOrigin() === 'answers' ? (countInAnswers || valueInStorage) : valueInStorage
  }

  const getMultiplesValues = (fieldName,nameForm,model,lenghtOfRow) => {
    if(hasIndexSelector(fieldName) || model){
        let values = []

        //console.log('getMultiplesValues in '+fieldName)
        //console.log('Length of row ',lenghtOfRow)
        //const countValues = model ? getValue(model,nameForm) : getValue(fieldName,nameForm)
        const countValues = lenghtOfRow
        for(let i = 0; i < countValues; i++){
            const nameField = replaceIndex(fieldName,i.toString())
            const value =  getValue(nameField,nameForm)
            if(value){
              values.push(value)
            }else{
              values.push('')
            }
        }

        //console.log('values',values)
       
        return values
    }
    return []
  }

  const deleteRowInMultiplesValues = (idRow,form,fieldName,newCount) => {
    const field = getObject(fieldName) 

    let fieldsToRemoveInCountable = []
    field.fields.forEach((fieldR)=>{

      let values = getMultiplesValues(fieldR.name,nameForm,field.title,newCount+1)
      values = values.filter((item,i)=> {
        return i !== idRow
      })

      saveMultiplesValues(fieldR.name,values,nameForm,null,'form',field.title,newCount)

      // Delete in FieldCountable si c'est nécéssaire
      if(fieldR.to_total){
        fieldsToRemoveInCountable.push(replaceIndex(fieldR.name,idRow.toString()))
      }

      // Delete Last Row in FieldCountable
      // A refacto avec le response dedans
      if(fieldR.to_total){
        //console.log('newCount',newCount)
        fieldsToRemoveInCountable.push(replaceIndex(fieldR.name,newCount.toString()))
      }

    })
    // Enregistrement de la quantité
    saveValue(field.title,newCount,nameForm,'count','form')
    //saveValue(field.title,lengthFinal,nameForm,'count','form')
    reload()
    reloadField(field.title);
    removeFieldsCountable(fieldsToRemoveInCountable)

  }

  const getRegionsSelected = (field) => {

    const valuesNumber = field.value.map((val)=>{return Number(val)})
    const regionsSelected = field.options.filter(option => valuesNumber.includes(option.value))
    return regionsSelected?.length > 0 ? regionsSelected : false;

  };

  const initCandidateTarget = (form, id) => {
    const target =
      id && form.targets
        ? form.targets.find((target) => target.target_id === parseFloat(id))
        : false;
    setCandidateTarget(target);
  };

  const submitFormulaire = async (name_form,prefetch) => {
    // On initialise la barre de progresssion dans une valeur globale
    setProgress(0)

    // On récupère les valeurs de notre From a envoyer.
    const valuesInStorage = getValuesInStorage();
    let valuesInForm = valuesInStorage.filter((element) => element.form === name_form && !['lastAnswersReload','lastInputSave'].includes(element.name) && element.value !== 'empty');
    let tabToSend = [];
    if (targetId) {
      tabToSend.answers_target_id = targetId;
    }

    // On doit filtrer les champs répétables pour pas envoyer les données qui pourrait être en trop

    console.log('V1 - Champs disponible dans le local storage',valuesInForm)
    
    // Filtre les champs Répétables
    valuesInForm = filterRepeatableFieldsBeforeSend(valuesInForm)
    console.log('V2 - Filtre des champs répétables',valuesInForm)

    // Filtre les champs visibles
    valuesInForm = filterVisibleFieldBeforeSend(valuesInForm)
    console.log('V3 - Filtre des champs visibles',valuesInForm)

    // Filtre des valeurs booléenes
    valuesInForm = valuesInForm.filter(filterBooleanValues)
    console.log('V4 - Filtre des valeurs de booléenes',valuesInForm)


    valuesInForm.forEach((element) => {
      const isModel = element.name ? element.name.includes("%index%") : false;
      if (!isModel) {


        if (element.type === "file") {
          // Fichier qui vient d'être ajouté 
          const file = getFileinTemp(element.name, element.value);
          if(file){
            tabToSend[element.name] = dataURLtoFile(file.object, file.fieldName);
          }

          // On vide le champs pour un valeur qui a été supprimé
          if(element.value === 'to_empty_before_send'){
            //element.name] = ''
          }

        }else if(element.type === "answers_saved" && element.source !== "answers_saved"){
          // Champs qui a déja été rempli mais peut être modifier
           //Savoir si c'est un file ou non 
          // Pose problème lorsque je renvoi rien puisqu'il attend un fichier 
          const file = getFileinTemp(element.name, element.value) ? getFileinTemp(element.name, element.value) : false ;
          if(file){
            tabToSend[element.name] = dataURLtoFile(file.object, file.fieldName);
          }
        }else if(element.type === "answers_saved" && element.source === "answers_saved"){
          tabToSend[element.name] = element.value;
        }else {
           // Nouveau champ a envoyé 
          tabToSend[element.name] = element.value;
        }
      }
      
    });

    if(prefetch){
      return calculWeightOfRequest(tabToSend);
    }
  

    // On fait évoluer la réponse suivant la quantité d'octets envoyé.
    const progressEvent = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      setProgress(percentCompleted)
    }
    //console.log('tabToSend',tabToSend)
    let response = await sendForm(form.slug, { ...tabToSend },progressEvent,process.env.REACT_APP_PHP_MAX_SIZE);

    // Reset de la progression
    setProgress(0)
    setResponseFormulaire(response);
    return response;

  };

  const filterBooleanValues = (value) => {
    if(value.type === 'boolean'){
      if(value.value === '0' || value.value === 0){
        return false
      }else{
        return true
      }
    }else{
      return true
    }
  }


  const calculWeightOfRequest = (tab) => {
    const arrayValues = Object.values({...tab});
    const arrayKeys = Object.keys({...tab});
    let countBytes = 0;
    arrayValues.forEach((element)=>{
      if(typeof element === 'string') {
        countBytes += new Blob([element]).size;
      }
      if(element.size){
        countBytes += element.size
      }
    }) 
    arrayKeys.forEach((element)=>{
      countBytes += new Blob([element]).size;
    }) 
    return countBytes
  }

  const getParentObject = (name, formInParams) => {
    const path = findPath(formInParams || form,'name',replaceNumByIndex(name))
    const parent_path = getParentPath(path)
    const object_parent = _.get(formInParams || form,parent_path)
    return object_parent
  }

  const getObject = (name) => {
    let path = findPath(form,'name',replaceNumByIndex(name)) || findPath(form,'title',replaceNumByIndex(name)) 
    const object_path = getObjectPath(path)
    const object = _.get(form,object_path)
    return object
  }

  const filterRepeatableFieldsBeforeSend = (valuesInForm) => {

    // Teste si il y a des champs en dehors des nombre repetable
    let newValuesinForm = valuesInForm.filter((value)=>{
      // On récupère le parent pour retrouver le nombre de champs
      const path = findPath(form,'name',replaceNumByIndex(value.name))
      const parent_path = getParentPath(path)
      const object_path = getObjectPath(path)
      const object_parent = _.get(form,parent_path)

      // Tester si c'est un objet repetable

      const object = _.get(form,object_path) || null
      const isSelfRepeatable = object && hasIndexSelector(object.name) ? true : false
      // Si un champs a le champs index c'est qu'il peut avoir plusieurs valeurs donc on va aller filtrer les valeurs qu'on envoie
      
      //console.log('filterRepeatableFieldsBeforeSend')

      const parentIsRepetable = object_parent ? object_parent?.repeatable : false

      //console.log('Flag 1',value.name,value,object,object_parent,isSelfRepeatable)
      //console.log('Flag 2.1',value.name,isSelfRepeatable,parentIsRepetable,isSelfRepeatable && !parentIsRepetable)
      //console.log('Flag 2.2',value.name,object_parent?.repeatable)

     
      
      if(isSelfRepeatable && !parentIsRepetable){

        const length = getRepetableLength(object,'flag 2 ' + value.name)
        const position = getIndexInName(value.name)

        if(position+1 <= length){
          return true
        }else{
          return false
        }


      }else if(parentIsRepetable){

        
        const numberOfItems = getRepetableLength(object_parent,'flag 1 ' + value.name) || false
        const indexOfObject = getIndexInName(value.name)

       

        if(!numberOfItems){
          //console.log('Flag 4',true)
          return true 
        }
        if(indexOfObject+1 > numberOfItems){
          //console.log('Flag 4',false)
          return false
        }else{
          //console.log('Flag 4',true)
          return true
        }


      }else{
        return true
      }
    })

    return newValuesinForm
  }

  // On va tester si le champs doit être visible et si les champs qui determine cette info doivent l'être aussi
  const testFieldHiddenByVisibleIfRecursively = (field) => {
    
    // On teste si le champs est conditionné a un affichage, sinon on va juste tester sa visibilité
    if(field?.visible_if){
      // On va chercher des informations sur son le champs qui le conditionne
    
      const conditionnableField = copyObject(reconstituteField(field.visible_if.field_name))
      const valueOfconditionnableField = getValue(field.visible_if.field_name,nameForm)
      
      let isVisible
      if(field.visible_if.value === 0){
        isVisible = valueOfconditionnableField === 0 || valueOfconditionnableField === null
      }else{
        isVisible = field.visible_if.value === valueOfconditionnableField 
      }

      if(isVisible){
        return testFieldHiddenByVisibleIfRecursively(conditionnableField)
      }else{
        return true
      }
    }else{
      //console.log('Test is hidden',isHiddenField(field))
      return isHiddenField(field)
    }
  } 


  const reconstituteField = (value) => {
      
      const nameWithNumber = value.name || value
      const numInName = getIndexInName(value.name || value)
      const nameWithoutNumber = replaceNumByIndex(value.name || value)

      let field = copyObject(getObject(nameWithoutNumber))

      if(field){
        if(field.visible_if?.field_name){
          if(numInName !== null){
            field.visible_if.field_name = replaceIndex(field.visible_if.field_name,String(numInName))
          }
        }
        field.index = numInName
        field.name = nameWithNumber
      }
      return field
    }
 

  const filterVisibleFieldBeforeSend = (valuesInForm) => {
    let newValuesInForm = valuesInForm.filter((value)=>{
      const field = reconstituteField(value)
      if(field){
        const isHidden = testFieldHiddenByVisibleIfRecursively(field)
        return !isHidden
      }else{
        return true
      }
    })

    return newValuesInForm
  }



  const getErrorsMessage = (nameField) => {
    if(nameField && nameField.includes('[%index%]')){
      nameField = nameField.replace('[%index%]','')
    }
    nameField = convertNotationBracketToDot(nameField)

    //console.log(nameField,responseFormulaire.errors)

    const isError =
      responseFormulaire &&
      responseFormulaire.errors &&
      (responseFormulaire.errors.hasOwnProperty(nameField) || 
      responseFormulaire.errors.hasOwnProperty(getNameInResponse(nameField))
      );

    if(isError){
      if(responseFormulaire.errors[nameField]){
        return responseFormulaire.errors[nameField]
      }else if(responseFormulaire.errors[getNameInResponse(nameField)]){
        return responseFormulaire.errors[getNameInResponse(nameField)]
      }else{
        return false
      }
    }else{
      return false
    }
  };

  const filterField = (oldField, index) => {
    let newField = copyObject(oldField);
      newField["index"] = index;
      newField["value"] = "";
      if (newField["name"]) {
        newField["name"] = replaceIndex(newField["name"], index.toString());
      }
      if (newField["label"]) {
        newField["label"] = replaceIndex(
          newField["label"],
          (index + 1).toString()
        );
   }

    //console.log('NEW FIELD',newField)
    return newField;
  };

  /*const emptyForm = (form) => {
    const valuesInStorage = getValuesInStorage() ? getValuesInStorage() : [];
    const newValues = valuesInStorage.filter(
      (element) =>  element.form !== form
    );
    saveValuesInStorage(newValues);
    reload();
  };*/

  const addFileinFileTemp = (fieldName, fileName, object) => {
    const file = { fieldName: fieldName, fileName: fileName, object: object };
    setFilesInTemp((arr) => [...arr, file]);
  };

  const removeFileinFileTemp = (fieldName, fileName) => {
    const newFileTemps = filesInTemp.filter(
      (element) =>
        element.fieldName !== fieldName && element.fileName !== fileName
    );
    setFilesInTemp(newFileTemps);
  };

  const getFileinTemp = (fieldName, fileName) => {
    const file = filesInTemp.find(
      (element) =>
        element.fieldName === fieldName && element.fileName === fileName
    );
    return file; 
  };

  const addFieldCountable = (field) => {
    //console.log('addFieldCountable',field.name)
    const isExist = fieldCountable.find(
      (element) => element.name === field.name
    )
      ? true
      : false;
    if (isExist) {
      setFieldCountable((fieldCountable) => {
        return fieldCountable.map((element) => {
          if (element.name === field.name) {
            element.value = isNaN(field.value) ? 0 : field.value;
            if (
              (element.to_total && element.index) ||
              (element.to_total && element.index === 0)
            ) {
              element.to_total = replaceIndex(
                element.to_total,
                element.index.toString()
              );
            }
          }
          return element;
        });
      });
    } else {
      setFieldCountable((arr) => {
        return [...arr, field];
      });
    }
  };

  const removeFieldsCountable = (fieldNames) => {
    const newFields = fieldCountable.filter(
      (element) => fieldNames.includes(element.name) === false
    );
    console.log('removeFieldsCountable',fieldCountable,fieldNames,newFields)

    setFieldCountable([...newFields])
  }

  const getTotal = (field) => {
    let total = 0
    if(getFieldCountableLength(field) > 0 || fieldTotalHasDependency(field)){
      total = getTotalinFieldCountable(field)
    }else if(getValueFromAnswers(field)){
      total = getValueFromAnswers(field)
    }
    //console.log('getTotal of ' + field.name,field,fieldTotalHasDependency(field),getTotalinFieldCountable(field),getValueFromAnswers(field),total)
    return total
  }

  const getTotalinFieldCountable = (field) => {
    // Récupère les fieldCountable existant -> Fontionne pas avec les champs enregistrés

    let fieldInRef = fieldCountable.filter(
      (element) => element.to_total === field.name
    );

    //console.log('getTotalInFieldCountable',field.name,fieldInRef,fieldCountable)

    const tabAlreadyCount = [];
    let count = 0;

    fieldInRef.forEach((field) => {
      const isAlreadyCount = tabAlreadyCount.find(
        (element) => element === field.name
      );
      if (!isAlreadyCount) {
        const value = field.value || getValue(field.name,nameForm) || 0
        const valueToAdd = field.to_total_operation === "add" ? parseInt(value) : parseInt(value) * -1;
        count += parseInt(valueToAdd);
        tabAlreadyCount.push(field.name);
      }
    });

    return count
  };

  // Retourne si le champs a au moins une valeur
  const fieldTotalHasDependency = (field) => {
    const path = findPath(form,'to_total',replaceNumByIndex(field.name))
    return path ? true : false
  }
  
  const getFieldCountableLength = (field) => fieldCountable.filter((element) => element.to_total === field.name).length;


  const reload = () => {
    setNeedReload(uniqueId());
  };


  // TO DO : Refacto cette fonction
  const getValueFromAnswers = (field) => {
    const answers = form.target_answers.answers;

    if(answers.hasOwnProperty(field.name)){
      return answers[field.name]

    }else if(!answers[field.name]){
      const path = convertNotationBracketToDot(field.name)
      let pathValue = _.get(answers,path)

      //console.log('GetAnwsers flag',path,answers,pathValue)

      if(pathValue === 'true' || pathValue === 'false'){
        pathValue = !!parseInt(pathValue)
      }
      if(Number.isInteger(pathValue)){
        return pathValue.toString()
      }
      if(pathValue || pathValue === false){
        return pathValue;
      }else{
        return null
      }
    }
    return null;
  };

  const hasFieldsReadOnly = () => {
    if (form) {
      if (form.fields) {
        form.fields.forEach((fieldSet) => {
          if (fieldSet.read_only) {
            return true;
          }

          if (fieldSet.fields && isIterable(fieldSet.fields)) {
            fieldSet.fields.forEach((field) => {
              if (field.read_only) {
                return true;
              }
            });
          }
        });
      }
    }
    return false;
  };

  const isHiddenField = (fieldParams) => {

    let field = copyObject(fieldParams)
    field.name = hasIndexSelector(field.name) ? replaceIndex(field.name,'0') : field.name

  
    const isHidden = testIsHiddenField(field) ? true : false
    const parentsIsHidden = testParentsIsHidden(field)

    const isEmpty = getValue(field.name,nameForm) ? false : true

    if((isHidden || parentsIsHidden) && !isEmpty){
      if(hasIndexSelector(fieldParams.name)){
        emptyDataInStorage(fieldParams)
      }
      emptyDataInStorage(field)
    }

    return isHidden || parentsIsHidden
  }

  const testIsHiddenField = (field) => {

    //console.log('testIsHiddenField',field.name,field)

    if(field.visible_if && field.visible_if.field_name){
      let visible_if_name 
      if(hasIndexSelector(field.visible_if.field_name)){
        if(field.index || field.index === 0){
          //console.log('A')
          visible_if_name = replaceIndex(field.visible_if.field_name,field.index.toString())
        }else{
          //console.log('B')
          const index = getIndexInName(field.name)
          visible_if_name = replaceIndex(field.visible_if.field_name,index.toString())
        }

      }else{
        visible_if_name = field.visible_if.field_name
      }
      
      //field.index || field.index === 0 ? replaceIndex(field.visible_if.field_name,field.index.toString()) : field.visible_if.field_name
      //console.log(field?.name,visible_if_name)
    
      let val = getValue(visible_if_name,nameForm) || 0

      if(val === 'false'){
        val = 0
      }

      if(val === 'true'){
        val = 1
      }

      if(typeof field.visible_if.value === 'boolean'){
        val = Boolean(val)
      }

      if(typeof field.visible_if.value === 'string'){
        val = String(val)
      }

      if(typeof field.visible_if.value === 'number'){
        val = Number(val)
      }

      const isHidden = field.visible_if.value === val ? false : true
      return isHidden
    }

    return false
  }

  const testParentsIsHidden = (field) => {
    // Logique pour tester les parents
    const path = findPath(form,'name',replaceNumByIndex(field.name))
    const parent_path = getParentPath(path)
    const object_parent = _.get(form,parent_path)

    return object_parent ? testIsHiddenField(object_parent) : false
  }


  const emptyDataInStorage = (field,fieldTitle,lengthOfFieldSet) => {
    const isMultiple = hasIndexSelector(field.name) || field.repeatable
    let response = null

    if(isMultiple){
      if(field.title){

        response = saveMultiplesValues(field.title,[],nameForm,'text','emptyDataInStorage',null,0,lengthOfFieldSet)
      }else if(field.name){

        response = saveMultiplesValues(field.name,[],nameForm,'text','emptyDataInStorage',fieldTitle || null,0,lengthOfFieldSet)
        
      }
    }else{
      saveValue(field.name,'',nameForm,'text','emptyDataInStorage')
    }


    return response
  }

  const emptyDataInStorageFieldSet = (field,fieldTitle,lengthOfFieldSetParams) => {

    let lengthOfFieldSet = lengthOfFieldSetParams || getValue(field.title,nameForm)

    if(field.fields){
      field.fields.forEach((f)=>{
        if(f.type === 'field_set'){
          lengthOfFieldSet = lengthOfFieldSetParams || parseInt(getValue(f.title,nameForm))
          emptyDataInStorageFieldSet(f,f.title,lengthOfFieldSet)
        }else{
          emptyDataInStorage(f,fieldTitle,lengthOfFieldSet)

        }
      })
    }else{
      emptyDataInStorage(field,fieldTitle,lengthOfFieldSet)
    }

  }


  const isHiddenFieldSet = (field) => {
    const isHiddenFieldSet = testisHiddenFieldSet(field) ? true : false

    // Hook pour vider le contenu dans le storage
    if(isHiddenFieldSet){
      emptyDataInStorageFieldSet(field,field.title)
    }


    return isHiddenFieldSet
  }


  const testisHiddenFieldSet = (field) => {
    if(field?.visible_if && field?.visible_if?.field_name){
      const val = parseInt(getValue(field.visible_if.field_name,nameForm))
      return field.visible_if.value === val ? false : true
    }
    return false
  }



  const getMostRecentDataOrigin = () => {
    const lastAnswersReload = getValue('lastAnswersReload',nameForm)
    const lastInputSave = getValue('lastInputSave',nameForm)

    if(!lastInputSave){
      return 'answers'
    }

    if(!lastAnswersReload){
      return 'localStorage'
    }

    return lastAnswersReload > lastInputSave ? 'answers' : 'localStorage'
  }


  const testFieldIsRequired = (field) => {
     let isRequired = false
    //console.log('testFieldIsRequired',field)
      if(field.hasOwnProperty('custom_rules')){
      const rules = field.custom_rules
      rules.forEach((rule)=>{
        //console.log(rule)
        
        const test = rule.split(':')[0]
        if(test === 'required_if'){

          const selector = rule.split(':')[1]
          const name = selector.split(',')[0]
          const value = selector.split(',')[1]
          const valueInStorage = getValue(name,nameForm)
          
          // Comparaison peut être un peu bancal
          if(String(value) === String(valueInStorage)){
            isRequired = true
          }else{
            isRequired = false
          }
        }

        if(test === 'required_with'){
          const name = rule.split(':')[1]
          const value = getValue(name,nameForm)
          isRequired  = Boolean(value)
        }

        if(test === 'required_unless'){
          const string = rule.split(':')[1]
          const name = string.split(',')[0]
          const valueToTest = getValue(name,nameForm)
          let values = string.split(',')
          values.shift()

          isRequired = true 
          values = values.map(value =>
          {
            if(value === '0' ){
              return 0
            }
            if(value === 'null'){
              return null
            }
            if(!Number.isNaN(parseInt(value))){
              return parseInt(value)
            }
            return value
          })

          for(const value of values){
            if(value === valueToTest){
              isRequired = false
            }
          }
        }
      })
     
    }

   if(field.hasOwnProperty('required_without_array')){
      const name = convertNotationDotToHook(replaceIndex(field.required_without_array,String(field.index)))
      const valueInStorage = getValue(name,nameForm)
      isRequired = !Boolean(valueInStorage)
    }

    if(field.hasOwnProperty('required_with_array')){
      const fieldsToCheck = field.required_with_array.split(',')
      let isEmpty = true
      //console.log('fieldsToCheck',field.name,fieldsToCheck)
      fieldsToCheck.forEach((fieldToCheck)=>{
        const name = convertNotationDotToHook(replaceIndex(fieldToCheck,String(field.index)))
        const value = getValue(name,nameForm)
        if(value && isEmpty){
          isEmpty = false
        }
      })
      //console.log('required_with_array',field.name,(isNotEmpty === fieldsToCheck.length))
      isRequired = !isEmpty
      // TO DO
    }

    if(field.hasOwnProperty('required_if_array')){
      const name = field.required_if_array.split(',')[0]
      let valueToCompare = Number(field.required_if_array.split(',')[1])

      // Si c'est pas un nombre
      if(isNaN(valueToCompare)){
        valueToCompare = field.required_if_array.split(',')[1]
        valueToCompare = valueToCompare.replace('"','').replace('"','') 
      }

      const nameInStorage = convertNotationDotToHook(replaceIndex(name,String(field.index)))
      const valueInStorage = getValue(nameInStorage,nameForm)
      isRequired =  valueToCompare === valueInStorage
    }
    return isRequired
  }

  
  const resetFieldCountable = () => {
    console.log(fieldCountable)
    setFieldCountable([])
  }


  useEffect(()=>{
    if(isDebug){
      debugFichierTemporaires(filesInTemp)
    }
  },[filesInTemp,isDebug])



  return (
    <FormContext.Provider
      value={{
        form,
        nameForm,
        initForm,
        formIsLoading,
        setFormIsLoading,
        candidateTarget,
        saveValue,
        getValue,
        getMultiplesValues,
        saveMultiplesValues,
        deleteRowInMultiplesValues,
        submitFormulaire,
        responseFormulaire,
        progress,
        getErrorsMessage,
        filterField,
        addFileinFileTemp,
        getFileinTemp,
        removeFileinFileTemp,
        addFieldCountable,
        fieldCountable,
        getTotalinFieldCountable,
        needReload,
        reload,
        getValueFromAnswers,
        hasFieldsReadOnly,
        isHiddenField,
        isHiddenFieldSet,
        getRegionsSelected,
        reloadField,
        fieldToUpdate,
        finishReloadField,
        getParentObject,
        getRepetableLength,
        getMostRecentDataOrigin,
        testFieldIsRequired,
        resetFieldCountable,
        fieldsLoading,
        setFieldsLoading,
        getSaveDateOfValue,
        getFieldCountableLength,
        fieldTotalHasDependency,
        getTotal
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
