import React, { useState } from 'react';
import './TextFieldCustom.scss';
import { InputUnstyled } from '@mui/base';
import { Box, InputLabel,Typography } from '@mui/material';
import { transformValue } from '../../services/utils';
import {  uniqueId } from 'lodash';

const TextFieldCustom = (props) => {

  let className = 'TextFieldCustom'

  //console.log(props)
  if(props.isError){
    className += ' isError'
  }
  if(props.readOnly){
    className += ' readOnly'
  }

  if(props.value?.toString().length > 0 || props.value === 0){
    className += ' hasContent'
  }


  const [value,setValue] = useState(transformValue(props.value))

  const handleChange = (e) =>{
    setValue(e.target.value)
    props.onChange(e)
  }

  const inputProps = {
    onChange:handleChange,
    value:value,
    type:props.type,
    placeholder:props.placeholder,
    name:props.name ? props.name : uniqueId()
  }



  return(
    <Box className={className}>
      <InputLabel htmlFor={props.name}>
        <Typography variant="inputLabel">{props.label} {props.isRequired && !props.readOnly && '*'}</Typography>
      </InputLabel>
      
      <Box className="input-area">
        {props.children && !props.readOnly && <Box className="inputChildren">{props.children}</Box>}
        {!props.children && !props.readOnly &&  <InputUnstyled {...inputProps}/>}
        {props.readOnly && props.value}
        {props.endIcon && props.endIcon}
      </Box>
    </Box>)
};
export default TextFieldCustom;
