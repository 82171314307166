import React, { useEffect, useState } from "react";
import "./RepeatableField.scss";
import { Box } from "@mui/system";
import { Button, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Field from "../Field/Field";
import { useForm } from "../../../services/formContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTheme } from "@emotion/react";
import { replaceIndex } from "../../../services/utils";
import { useUser } from "../../../services/userContext";

const RepeatableField = (props) => {
  const theme = useTheme();

  const {field} = props
  const {isDebug} = useUser()
  
  const { nameForm, getValue, saveValue, filterField} = useForm();
  const [isInit, setIsInit] = useState(false);

  const [row, setRow] = useState([]);
  const [numberRow, setNumberRow] = useState(getValue(field?.name, nameForm) || 1);

  const addRow = (number) => {
    for (let i = 0; i < (number ? number : 1); i++) {
      setRow((arr) => [...arr, field]);
    }
  };

  useEffect(()=>{
    //console.log('numberRow',numberRow)
    if(numberRow === 1){
      updateNumberRow(0)
    }
     // eslint-disable-next-line
  },[numberRow])

  const updateNumberRow = (number) => {
    let oldValue = getValue(field.name, nameForm)
      ? getValue(field.name, nameForm)
      : 1;
    let newValue = oldValue + number;
    saveValue(field.name, newValue, nameForm);
    setNumberRow(newValue);
  };

  const handleDeleteRow = (index) => {
    // Delete in row
    let newRow = row.filter((item, i) => i !== index);
    console.log('Handle Delete Row')
    // Update data in storage
    // Décalage d'un élement en avant si c'est pas le dernier
    if (index !== numberRow - 1) {
      for (let i = index + 1; i < numberRow; i++) {
        const valueNext = getValue(replaceIndex(field.name, i), nameForm);
        saveValue(replaceIndex(field.name, i - 1), valueNext, nameForm, "text");
        console.log(
          replaceIndex(field.name, i),
          replaceIndex(field.name, i - 1),
          valueNext
        );
      }
    }

    saveValue(replaceIndex(field.name, numberRow - 1), "", nameForm, "text");
    setRow(newRow);
    updateNumberRow(-1);
  };

  if (!isInit) {
    addRow(numberRow);
    setIsInit(true);
  }

  return (
    <Box>
      <Box>
        {isDebug && field.name}
        <Grid container spacing={2}>
          {row.map((fieldInRow, index) => (
            <Grid key={index} item xs={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "flex-end", gap: 2 }}>
                <Box sx={{ width: index !== 0 ? "calc(100% - 40px)" : "100%" }}>
                  <>
                  <Field
                    isRecursive={true}
                    parent={"repeatableField"}
                    field={filterField(fieldInRow, index)}
                  />
                  </>

                </Box>
                {index !== 0 && (
                  <Box sx={{ width: "40px" }}>
                    <IconButton
                      onClick={() => {
                        handleDeleteRow(index);
                      }}
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ marginY: 2 }}>
        <Button
          onClick={() => {
            addRow(1);
            updateNumberRow(1);
          }}
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
        >
          {field?.repeat_label}
        </Button>
      </Box>
    </Box>
  );
};

export default RepeatableField;
