import React from 'react';
import { useUser } from '../../services/userContext';
import Page404Connect from './404Connect';
import Page404Disconnect from './404Disconnect';


const Page404 = () => {
  const {isConnect} = useUser()
  return isConnect ? <Page404Connect/> : <Page404Disconnect />
};


export default Page404;
