import React from 'react';
import './MapFrance.scss';
import carteFrance from '../../assets/img/map-france/france.svg'

const MapFrance = () => {
  return(
  <div className="MapFrance">
    <img src={carteFrance} alt="carte de france" />
  </div>
)};


export default MapFrance;
