import React, { useCallback, useEffect, useState } from 'react';
import './Etape.scss';
import { Typography, Box } from '@mui/material';
import jMoment from 'moment-jalaali'
import {useNavigate } from 'react-router-dom';
import navItems from "../../components/NavItems/NavItems";
import { getForm, getFormGroup } from '../../services/api';

const EtapeDeprecated = (props) => {
  
  const {step,candidacy} = props
  const navigate = useNavigate()
  const [currentStatus,setCurrentStatus] = useState()
  const [labelComponent,setLabelComponent] = useState()

  const isCurrentStep = step.id === candidacy.next_step 


  const getStatus = useCallback(async () =>{


    if(isCurrentStep){
      if(isCurrentStep && step.title === 'Dossier administratif'){
        const formGroup = await getFormGroup('dossier-administratif')
        if(formGroup.is_completed && formGroup.has_pending_exports){
          setCurrentStatus('Envoyé')
        }else{
          setCurrentStatus('A compléter')
        }
      }else if(isCurrentStep && step.title === 'Dossier financier'){
        const formGroup = await getFormGroup('dossier-financier')
        if(formGroup.is_completed && formGroup.has_pending_exports){
          setCurrentStatus('Envoyé')
        }else{
          setCurrentStatus('A compléter')
        }

      }else if(isCurrentStep && step.title === 'Découverte point de vente'){
        const form = await getForm('decouverte-point-de-vente')
        if(form.has_pending_exports ){
          setCurrentStatus('Envoyé')
        }
      }else{
        setCurrentStatus('A compléter')
      }
    }

    if(!isCurrentStep  && step.title === 'Découverte point de vente'){
    
      
      const form = await getForm('decouverte-point-de-vente')
      if(form.has_pending_exports && form.is_unlocked && !form.are_files_validated){
        console.log('Current Step','Bahh 1')
        setCurrentStatus('Envoyé')
      }else{
        console.log('Current Step','Bahh 2')
        setCurrentStatus('A compléter')
      }
    }

    if(!isCurrentStep  && step.title === 'Dossier financier'){
    
      const form = await getForm('dossier-financier')
      if(form.has_pending_exports && form.is_unlocked && !form.are_files_validated){
        setCurrentStatus('Envoyé')
      }else{
        setCurrentStatus('A compléter')
      }
    }






  },[isCurrentStep,step])

  useEffect(() =>{
    getStatus()
  },[getStatus])
  

  const isClickable = (step) => {
    if(step.id < candidacy.next_step){
      return false
    }
    return navItems.find(item => item.title === step.title) ? true : false
  }

  const handleClickStep = (step) => {
    if(isClickable(step)){
      const item = navItems.find(item => item.title === step.title)
      navigate(item.link)
    }
  }
  
  const getStyleEtape = (step) => {return {textDecoration : isClickable(step) && step.is_next ? 'underline' : 'inherit', color:step.is_next ?  'white' : 'rgb(97, 97, 81)'}}


  const getLabel = async () => {


    let formGroup
    if(step.title === 'Dossier administratif'){
      formGroup = await getFormGroup('dossier-administratif')
    }else if(step.title === 'Dossier financier'){
      formGroup = await getFormGroup('dossier-financier')
    }
    
    if(formGroup?.has_pending_exports && formGroup?.is_completed){
      return <Typography variant="etape" sx={getStyleEtape(step)} >Envoyé</Typography>
    }
    
    if(step.id === candidacy.next_step && step.id !== 20){
      return <Typography variant="etape" sx={{...getStyleEtape(step),textDecoration:'underline'}} >{currentStatus}</Typography>
    }

    if(step.id === 20 && step.id === candidacy.next_step){
      return <Typography variant="etape" sx={getStyleEtape(step)}>À venir</Typography>
    }

    if(step.id === 10 && candidacy.next_step  === 10){
      return (<Box>
        <Typography component="p" variant="etape" sx={{...getStyleEtape(step),marginBottom:0.5}}>À venir</Typography>
        <Typography component="p" variant="etape">(sous réserve que votre dossier administratif soit validé)</Typography>
      </Box>)
    }

    if(step.id === 50 && candidacy.next_step  === 50){
      return (<Box>
        <Typography component="p" variant="etape" sx={{...getStyleEtape(step),marginBottom:0.5}}>À venir</Typography>
      </Box>)
    }

    if(step.id === 60 && candidacy.next_step  === 60){
      return (<Box>
        <Typography component="p" variant="etape" sx={{...getStyleEtape(step),marginBottom:0.5}}>À venir</Typography>
      </Box>)
    }

    if((step.id === 40 || step.id === 30) && candidacy.next_step  === 20){
      return <Typography variant="etape" sx={{...getStyleEtape(step),textDecoration:'underline'}} >{currentStatus}</Typography>
    }

    if((step.id === 40 || step.id === 30) && candidacy.next_step  === 30){
      return <Typography variant="etape" sx={{...getStyleEtape(step),textDecoration:'underline'}} >{currentStatus}</Typography>
    }

    if((step.id === 40 || step.id === 30) && candidacy.next_step  === 20){
      return <Typography variant="etape" sx={getStyleEtape(step)} >{currentStatus}</Typography>
    }


    if(step.id === candidacy.next_step){
      return <Typography variant="etape" sx={getStyleEtape(step)} >{currentStatus}</Typography>
    }

    if(step.id > candidacy.next_step){
      return <Typography variant="etape" sx={getStyleEtape(step)} >À venir</Typography>
    }

  }

  useEffect(() =>{
    const handleLabel = async () => {
      setLabelComponent(await getLabel())
    }
    handleLabel()
  },[candidacy])
  

  // textDecoration:step.id === candidacy.next_step ? 'underline' : 'inherit'
  return (
    <Box className="etape" sx={{cursor:isClickable(step) && step.id >= candidacy.next_step ? 'pointer' : 'inherit',backgroundColor:step.is_next ? '#ED5052' : '#EDEDED'  ,color:step.is_next ? 'white' : 'black',border: step.isCompleted ? '1px solid #C5282A' : '1px solid #E0E0E0' ,borderRadius:'12px',height:['inherit','inherit','124px'],width:['100%','100%','12%'],overflow:'hidden',display:'flex',alignItems:'center',justifyContent:'center',paddingY:[2,2,1],paddingX:[0,0,0],textAlign:'center'}}>
        <Box sx={{opacity:step.id < candidacy.next_step ? 0.5 : 1}} onClick={()=>{handleClickStep(step)}}>
          <Typography variant="etapeTitle" sx={{color:step.is_next? 'white' : 'black'}}>
            {step.title}  
          </Typography>
          
          <Box sx={{color:step.is_next ? 'white' : 'grey'}}>
            {labelComponent}
            {step.is_next &&  candidacy.next_step_at && <Typography>{jMoment(candidacy.next_step_at).format('LLLL')}</Typography>}
          </Box>
      </Box>
  </Box>
)};





const Etape = ({name,label,subLabel,step,isActive,isClickable,isCompleted,isDisabled,link}) => {

  const cursor = isClickable ? 'pointer' : 'inherit'

  const navigate = useNavigate()
  const handleClickStep = () => {
    if(isClickable){
      navigate(link)
    }
  }

  return (
    <Box className="etape" sx={{cursor:cursor,backgroundColor:isActive ? '#ED5052' : '#EDEDED'  ,color:isActive ? 'white' : 'black',border: isCompleted && !isDisabled ? '1px solid #C5282A' : '1px solid #E0E0E0' ,borderRadius:'12px',height:['inherit','inherit','124px'],width:['100%','100%','12%'],overflow:'hidden',display:'flex',alignItems:'center',justifyContent:'center',paddingY:[2,2,1],paddingX:[0,0,0],textAlign:'center'}}>
      <Box sx={{opacity:isDisabled ? 0.5 : 1}} onClick={handleClickStep}>
          <Typography variant="etapeTitle" sx={{color:isActive ? 'white' : 'black'}}>
            {name}
          </Typography>
          <Box sx={{color:isActive ? 'white' : 'grey'}}> 
            <Typography variant="etape" style={{textDecoration:!isDisabled && isActive  ? 'underline' : 'inherit',color:isActive && !isCompleted ?  'white' : 'rgb(97, 97, 81)'}}>
              {label}
            </Typography>
            {subLabel && <Typography variant="etape"><br />{subLabel}</Typography>} 
          </Box>
      </Box>
    </Box>
  )

}
export {Etape,EtapeDeprecated}
