import { Typography, Box } from '@mui/material';
import React from 'react';
import AppLayout from '../../components/AppLayout/AppLayout';
import './MentionsLegales.scss'

const MentionsLegales = () => {
  return (
  <AppLayout>
    
    <Typography variant="h1">Mentions légales et conditions générales d'utilisation</Typography>

      <Box className="MentionsLegales">
      <p>Date de dernière mise à jour&nbsp;: 28 novembre 2022</p>
      <p>Le site monespacerecrutement disponible à l’adresse <a href="https://monespacerecrutement.changerdevie.mousquetaires.com/">monespacerecrutement.changerdevie.mousquetaires.com</a> (ci-après le «&nbsp;Site&nbsp;») est destiné aux candidats désireux de rejoindre le Groupement les Mousquetaires en tant que chefs d’entreprise indépendants adhérents au Groupement (ci-après les «&nbsp;Utilisateurs&nbsp;»).</p>
      <p>Le Site propose aux Utilisateurs des informations et des actualités sur le Groupement, les valeurs et règles du Groupement Les Mousquetaires, des actualités sur le parcours de recrutement et un compte «&nbsp;candidat&nbsp;» permettant aux Utilisateurs de suivre leur candidature.</p>
      <p>Le Site propose aux Utilisateurs les fonctionnalités suivantes&nbsp;:&nbsp;&nbsp;</p>
      
      <ul>
        <li>L’inscription au parcours de recrutement en complétant le formulaire accessible au lien suivant changerdevie.mousquetaires.com&nbsp;;&nbsp;&nbsp;</li>
        <li>Le suivi du parcours de recrutement&nbsp;;</li>
        <li>Le dépôt les différents justificatifs nécessaires à l’analyse de leur candidature par l’Union des Mousquetaires.&nbsp;</li>
      </ul>

      <ol>
        <li><strong>Edition</strong></li>
      </ol>

      <p>La diffusion du Site est assurée par l’<strong>Union des Mousquetaires</strong>, association régie par la loi de 1901 situé au 2 allée des Mousquetaires, 917078 Bondoufle cedex et dont le numéro de téléphone est le suivant 01.69.64.11.17 (ci-après «&nbsp;l’UDM&nbsp;»).&nbsp;</p>
      

      
      <p>Le directeur de la publication du Site est Madame Olivia SOUDAIS.&nbsp;</p>
      
      <ol start="2">
      <li><strong>Hébergement</strong></li>
      </ol>

      <p>L’hébergement du Site est assuré par la société R2 dont le siège social est situé 19 rue de Sèvres 75006 PARIS et dont le n° de téléphone est le suivant 01.44.39.02.11.&nbsp;</p>
      
      <ol start="3">
      <li><strong>Acceptation et modifications des conditions générales d’utilisation</strong></li>
      </ol>
 
      <p>Les présentes Conditions générales d'utilisation entrent en vigueur à la date de leur mise en ligne. Elles sont opposables à la date de la première connexion de l’Utilisateur au Site et pendant toute la durée d'utilisation du Site et jusqu'à ce que de nouvelles conditions générales d'utilisation les remplacent.&nbsp;</p>
      <p>L’entrée en vigueur d’une nouvelle version des Conditions Générales d’Utilisation correspond à la date à laquelle cette version est mise en ligne sur le Site.&nbsp;</p>
      <p>L’UDM pourra modifier à tout moment les présentes Conditions générales d'utilisation. Ces modifications et évolutions entreront en vigueur à la date de leur mise en ligne et seront opposables à la date de la première connexion à l’Application par l'Utilisateur.</p>
      
      <ol start="4">
      <li><strong>Inscription et utilisation du compte «&nbsp;candidat&nbsp;»</strong></li>
      </ol>

      <p>4.1. L’accès au compte «&nbsp;candidat&nbsp;» est subordonné à une inscription préalable mise en œuvre par l’Union des Mousquetaires&nbsp;à la suite d’échanges entre l’Utilisateur et l’Union des Mousquetaires.&nbsp;</p>
      

      
      <p>L’Utilisateur recevra un courrier électronique l’invitant à se connecter sur le Site et à valider son compte «&nbsp;candidat&nbsp;».&nbsp;</p>
      

      
      <p>L’Utilisateur accédera sur le Site à son compte «&nbsp;candidat&nbsp;» à l’aide d’un identifiant et d’un mot de passe.&nbsp;</p>
      

      
      <p>Ce compte «&nbsp;candidat&nbsp;» permet à l’Utilisateur de&nbsp;:</p>
      

      <ul>
        <li>Gérer et/ou consulter ses informations personnelles et ses coordonnées&nbsp;;</li>
        <li>Compléter son dossier de candidature et télécharger ses justificatifs.</li>
      </ul>

      <p>4.2.&nbsp; L'utilisation de tout élément composant le Site n'est autorisée que pour un usage professionnel destiné au parcours de recrutement. Toute utilisation qui pourrait être effectuée à d'autres fins est expressément interdite.</p>
      <p>Lors de la consultation et de l’utilisation du Site, l’Utilisateur s'interdit, de quelque manière que ce soit, de réaliser des actes illicites, portant atteinte à l’ordre public, aux droits de l’UDM et des tiers.&nbsp;</p>
      <p>Dans ce cadre, l’Utilisateur s’engage, sans que cette liste soit limitative, à&nbsp;:</p>
      

      
      <ul>
      <li>ne pas utiliser de fausse identité en vue de tromper autrui ;</li>
      

      
      <li>ne pas violer les lois en vigueur, ni les droits des tiers, ainsi que les présentes stipulations contractuelles&nbsp;;</li>
      

      
      <li>ne pas porter atteinte, ni violer les droits de propriété intellectuelle afférents aux contenus fournis par l’UDM sur le Site, par les tiers ou par les autres utilisateurs ;</li>
      

      
      <li>ne pas collecter des informations sur des tiers, y compris des adresses électroniques, afin de les utiliser pour l'envoi de sollicitations commerciales ou équivalentes, ou de les intégrer au sein d'un service de référencement ou équivalent, gratuit ou payant, ou encore afin d'effectuer de la veille concurrentielle.</li>
      </ul>
      

      
      <p>L’Utilisateur s’interdit d’éditer, de diffuser, de stocker, de transmettre des contenus illicites susceptibles de porter atteinte :</p>
      

      
      <ul>
      <li>aux personnes, qu’il s’agisse d’autres Utilisateurs ou de tiers (diffamation, injure, atteinte à la vie privée, atteinte à l’image…)&nbsp;;</li>
      

      
      <li>à des droits de propriété intellectuelle et droits de la personnalité&nbsp;;&nbsp;</li>
      

      
      <li>à l’ordre public et aux bonnes mœurs (apologie des crimes contre l’humanité, incitation à la haine raciale, pornographie enfantine…)</li>
      </ul>
      

      
      <p>L’Utilisateur s’engage, le cas échéant, à insérer des liens hypertextes qui ne renvoient pas vers des contenus illicites, contraires à l’ordre public et aux bonnes mœurs, ou portant atteinte aux droits des tiers.</p>
      

      
      <p>L’Utilisateur s’engage à faire preuve de bienveillance à l’égard de l’ensemble des Utilisateurs et plus généralement à l’égard du Groupement.</p>
      

      
      <p>4.3. L’Utilisateur reconnaît accepter les caractéristiques et les limites d'un site internet accessible en ligne, et en particulier reconnaît :</p>
      

      
      <ul>
      <li>qu'il a connaissance des aléas de la consultation en ligne ;</li>
      

      
      <li>qu'il lui appartient de prendre toutes les mesures nécessaires pour s'assurer que les caractéristiques techniques de son ordinateur et/ou de son réseau informatique lui permettent l'accès au Site ;</li>
      

      
      <li>être seul responsable de ses accès sur Internet ;</li>
      

      
      <li>qu'il lui appartient de prendre toutes les mesures utiles de façon à protéger ses propres données et/ou logiciels de la contamination par d'éventuels virus qui pourraient affecter son ordinateur ou son matériel informatique, suite notamment à un accès ou une utilisation du Site, un téléchargement d’éléments du Site ou des interventions illicites dans les systèmes informatiques.</li>
      </ul>
      
      <ol start="5">
      <li><strong>Accès au Site</strong></li>
      </ol>
      

      
      <p>L’accès au Site est gratuit à l’exception des frais de connexion qui sont à la charge exclusive des Utilisateurs.&nbsp;</p>
      

      
      <p>L’UDM s'engage à faire ses meilleurs efforts pour que le Site soit accessible à tout moment. L’UDM est seulement tenue à une obligation de moyens concernant l’accessibilité du Site et n'est en aucun cas responsable des éventuelles interruptions et des conséquences qui peuvent en découler pour l'Utilisateur ou tout tiers.</p>
      

      
      <p>En cas de maintenance, d’actions correctives ou de problèmes liés aux réseaux de communications électroniques, l’accès au Site pourra cependant être temporairement interrompu ou suspendu.</p>
      

      
      <p>L’UDM se réserve le droit, sans préavis, de suspendre temporairement l'accès à tout ou partie au Site pour des raisons techniques liées notamment à la nécessité de mise à jour, de modification, de maintenance de tout ou partie des fonctionnalités et, de manière générale, pour toute autre cause technique et organisationnelle. En conséquence, la responsabilité de l’UDM ne pourra être ni recherchée ni retenue en cas d’indisponibilité temporaire ou totale de tout ou partie de l'accès au Site, en cas de perte de données, en présence d’une difficulté liée au temps de réponse, et d'une manière générale, d'un défaut de performance quelconque.</p>
      

      
      <p>L’UDM ne saurait par ailleurs être déclarée responsable d'une quelconque difficulté de transmissions ou, plus généralement, de toute perturbation du réseau.</p>
      
      <ol start="6">
      <li><strong>Sécurité et confidentialité</strong></li>
      </ol>
      

      
      <p>6.1. Pour accéder au Site, l’Utilisateur doit disposer d'un identifiant et d’un mot de passe transmis par l’UDM ou son prestataire.&nbsp;</p>
      

      
      <p>Le mot de passe est strictement personnel.</p>
      

      
      <p>L'Utilisateur est seul responsable de la préservation et de la confidentialité de son mot de passe.</p>
      

      
      <p>L'Utilisateur s'engage à&nbsp;:</p>
      

      
      <ul>
      <li>prendre toutes mesures utiles pour en assurer une parfaite confidentialité&nbsp;;</li>
      

      
      <li>ne pas communiquer, céder, vendre ou sous-louer son mot de passe à un tiers.</li>
      </ul>
      

      
      <p>En tout état de cause, toute utilisation de son mot de passe et de son identifiant par l'Utilisateur fait présumer de manière irréfragable que l'Utilisateur bénéficie d'un accès au Site.&nbsp;</p>
      

      
      <p>L'Utilisateur s'engage à notifier sans délai à l’UDM ou à son prestataire toute communication à des tiers, utilisation frauduleuse de son identifiant et de son mot de passe dont il aurait connaissance.</p>
      

      
      <p>En tout état de cause, l'Utilisateur est seul responsable de l'utilisation qui sera faite sous son identifiant et son mot de passe jusqu'à ce que l’Utilisateur demande à l’UDM ou à son prestataire de lui attribuer un nouveau mot de passe.</p>
      

      
      <p>L’Utilisateur reconnaît expressément que l’UDM conservera une copie des identifiants et mots de passe attribués à l’Utilisateur.&nbsp;</p>
      

      
      <p>6.2. Les messages que l’Utilisateur adresse à l’UDM par l'intermédiaire d'Internet peuvent être interceptés sur le réseau. Jusqu'à ce qu'ils parviennent à l’UDM, leur confidentialité ne peut être garantie.</p>
      

      
      <p>L’Utilisateur doit s’assurer qu’il ne divulgue pas d'informations personnelles ou confidentielles inutiles, sensibles ou provenant de tiers.&nbsp;</p>
      

      
      <p>6.3. L’Utilisateur reconnaît que les informations communiquées sur le Site et/ou entre les Utilisateurs sont confidentielles.&nbsp;</p>
      

      
      <p>A cet égard, l’Utilisateur s’interdit de divulguer à des tiers au Groupement Les Mousquetaires toutes informations confidentielles transmises ou recueillies sur le Site du fait de son appartenance au Groupement.</p>
      
      <ol start="7">
      <li><strong>Propriété intellectuelle</strong></li>
      </ol>
      

      
      <p>Le Site et chacun des éléments qui le compose, notamment mais non exclusivement, les logos, marques, images, textes, vidéo, animations, logiciels, bases de données, sons et autres signes distinctifs sont protégés par des droits de propriété Intellectuelle.</p>
      

      
      <p>Toute reproduction, représentation, adaptation, totale ou partielle du Site ou de son contenu, notamment les logos, marques, images, textes, photographies, icônes et autres signes distinctifs, par quelque procédé que ce soit, et sur quelque support que ce soit, sans l’autorisation expresse écrite de l’UDM et/ou de son prestataire, est interdite et susceptible de constituer une contrefaçon sanctionnée par le code de la propriété Intellectuelle et engager la responsabilité civile et / ou pénale de l'auteur de la contrefaçon.</p>
      

      
      <p>Dans l'hypothèse où l’Utilisateur souhaiterait utiliser à des fins autres que privées un des contenus du Site (texte, image, etc.), l’Utilisateur doit obtenir l'autorisation écrite, expresse et préalable de l’UDM.</p>
      

      
      <p>Les présentes Conditions générales n'emportent aucune cession d'aucune sorte de droits de propriété sur les éléments appartenant à l’UDM au bénéfice des Utilisateurs.</p>
      

      
      <p>Les Utilisateurs bénéficient d’une licence d’accès et d’utilisation limitée à tout ou une partie du Site.</p>
      
      <ol start="8">
      <li><strong>Responsabilité</strong></li>
      </ol>
      

      
      <p>L’Utilisateur est seul responsable des commentaires qu’il édite, diffuse, publie, transmet. En conséquence, l’UDM ne saurait être en aucun cas responsable ni, des commentaires édités, diffusés, publiés, transmis par l’Utilisateur.</p>
      

      
      <p>L’UDM ne pourra être tenue pour responsable des dommages directs ou indirects résultant de l’accès ou de l’utilisation du Site et des fonctionnalités, y compris dans les conséquences d’un virus qui pourrait affecter l’ordinateur ou le matériel informatique de l’Utilisateur à la suite d’un accès ou une utilisation du Site, un téléchargement d’élément du Site ou des interventions illicites dans les systèmes informatiques.</p>
      
      <ol start="9">
      <li><strong>Liens</strong></li>
      </ol>
      

      
      <p>Le Site peut contenir des liens hypertextes ou des contenus de sites de partenaires de l’UDM.</p>
      

      
      <p>Bien que le choix des liens vers des sites Internet extérieurs ait fait l’objet d’une vigilance particulière, l’UDM décline toute responsabilité relative au contenu de ces sites, à leur légalité, exactitude ou fonction et ne peut être tenue pour responsable de l’usage qui pourra en être fait par l’Utilisateur. Ainsi, il est conseillé à l’Utilisateur qui utiliserait l’un de ces liens de se reporter aux mentions légales et conditions d’utilisation présentes sur chacun de ces sites.</p>
      

      
      <p>Toute création d’hyperliens avec le Site est interdite sans accord préalable et écrit de l’UDM.</p>
      
      <ol start="10">
      <li><strong>Modifications et évolutions des fonctionnalités</strong></li>
      </ol>
      

      
      <p>L’UDM se réserve le droit d'apporter des modifications et/ou des améliorations et/ou des évolutions sur le Site et les fonctionnalités.&nbsp;&nbsp;&nbsp;</p>
      

      
      <p>En conséquence, l'Utilisateur reconnaît et accepte qu'aucune indemnité ne pourra lui être accordée au titre de l'une quelconque des modifications ou suppressions des fonctionnalités mises en œuvre par l’UDM.&nbsp;</p>
      


      <ol start="11">
      <li><strong>Données personnelles et Cookies</strong></li>
      </ol>
      

      
      <p>La politique de protection des données personnelles du Site est disponible en <a style={{color:'inherit'}} href="/politique-donnees">cliquant ici.</a></p>

      <ol start="12">
      <li><strong>Stipulations diverses</strong></li>
      </ol>
      

      
      <p>L’Utilisateur ne peut en aucun cas céder tout ou partie de ses droits objets des présentes Conditions générales d'utilisation.</p>
      

      
      <p>En conséquence, l'Utilisateur s'interdit de louer, sous-louer ou de mettre à la disposition d'un tiers d'une manière quelconque le droit d'accès au Site et aux fonctionnalités.&nbsp;&nbsp;</p>
      

      
      <p>En cas de difficultés d'interprétation entre l'un quelconque des titres figurant en tête des clauses est l'une quelconque des clauses, les titres seront déclarés inexistants.</p>
      

      
      <p>Si une ou plusieurs stipulations des présentes Conditions Générales d'Utilisation étaient tenues pour non valide(s) ou déclarée(s) comme telle(s) en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.</p>
      

      
      <p>Le fait pour l'une des parties de tolérer une situation n'a pas pour effet d'accorder d'autres parties et droits acquis</p>
      

      
      <p>De plus une telle tolérance ne peut être interprétée comme une renonciation à faire valoir des droits en cause.</p>
      

      
      <p>En cas de litige et après une tentative de recherche d’une solution amiable, le tribunal de commerce de Paris sera compétent, nonobstant pluralité de défendeur ou appel en garantie même pour les procédures d’urgence ou les procédures conservatoires, en référé ou par requête.&nbsp;</p>
      

      
      <p>Les présentes Conditions Générales d'Utilisation sont régies par la loi française.</p>
      
      <ol start="13">
      <li><strong>Contact</strong></li>
      </ol>
    
      <p>Pour toute question relative au Site, l’Utilisateur peut contacter l’UDM à l’adresse udm_recrutement@mousquetaires.com</p>
    
      <p>L’UDM répondra dans les meilleurs délais.&nbsp;</p>
      </Box>
    
  </AppLayout>
)};

export default MentionsLegales;
