import { Box, Button, Dialog, DialogContent, FormControlLabel, FormGroup, Typography} from '@mui/material';
import React, { useState } from 'react';
import { saveConsentRGPD } from '../../services/localStorage';
import { useUser } from '../../services/userContext';
import { BpCheckbox } from '../Fields/FieldCheckbox/FieldCheckbox';

const ModalOnboarding = (props) => {
  const {isOpen,setIsOpen } = props
  const [isChecked,setIsChecked] = useState(false)
  const {user} = useUser()

  const handleValidate = () => {
    if(isChecked){
      saveConsentRGPD(user.email,true)
      setIsOpen(false)
    }
    
  }

  return(
  <Dialog open={isOpen} maxWidth="md">
    <DialogContent sx={{paddingY:[5,5,9],paddingX:[3,3,10],textAlign:'center'}}>
      <Typography variant="modalTitle" sx={{fontSize:[22,36,36]}}>Bienvenue {user?.first_name} {user?.last_name} sur votre espace recrutement Mousquetaires !</Typography>
      <Typography variant="body1" sx={{marginY:2}}>Merci de l'intérêt que vous portez au Groupement Les Mousquetaires. <br /> Cet espace vous permettra de suivre votre parcours de recrutement et de compléter les éléments nécessaires à votre candidature.</Typography>
      <Typography variant="body1" sx={{marginY:2}}>Nous restons à votre disposition en cas de questions supplémentaires.</Typography>
      <Typography variant="body1" sx={{marginY:2,marginBottom:4}}>L’équipe recrutement Mousquetaires.</Typography>
      <Box sx={{color:'black',textAlign:'left',padding:1,display:'flex',flexDirection:'column',gap:2,border:'2px solid #ED5052'}}>
        <Typography sx={{fontWeight:'bold',color:'black'}}>Toutes les mentions requises dans ce dossier sont obligatoires.</Typography>
        <Typography sx={{color:'black'}}>
          Le défaut d’une des mentions pourrait compromettre l’instruction de votre dossier. Les informations transmises doivent êtres exactes et sincères. En remplissant ce dossier, vous reconnaissez le traitement par l’Union des Mousquetaires (UDM), sise au Parc de Tréville, 2 allée des Mousquetaires 91078 Bondoufle Cedex, responsable de traitement, des informations recueillies. Ce traitement, fondé sur l’exécution de mesures précontractuelles et les intérêts légitimes du responsable de traitement, vise à gérer le recrutement des adhérents, le suivi de votre projet, ainsi qu’à des fins de statistiques. Le destinataire de ces données est l’UDM et les entités du Groupement Les Mousquetaires. Les données collectées sont conservées le temps nécessaire à leur finalité, ne sont pas cédées à un tiers et ne font pas l’objet d’un transfert dans un État non membre de l’Union européenne.
        </Typography>
        <Typography sx={{color:'black'}}>
        Conformément au Règlement général pour la protection des données n°2016/679 et à la loi dite « Informatique et libertés » du 6 janvier 1978 dans sa dernière version, vous disposez d’un droit d’accès, de rectification, d’effacement aux données à caractère personnel qui vous concernent, un droit d’opposition et un droit à la limitation du traitement, ainsi qu’un droit de définir des directives relatives à la conservation, à l’effacement et à la communication de vos données à caractère personnel après votre décès. Vous pouvez exercer ces droits en contactant le responsable de traitement à l’adresse susmentionnée ou par courriel à l’adresse <Typography sx={{color:'#0086D1',fontWeight:600, overflowWrap:'break-word'}} component="a" href={'mailto:udm_contact@mousquetaires.com'}>udm_contact@mousquetaires.com</Typography>
        </Typography>
        <Typography sx={{color:'black',overflowWrap:'break-word'}}>
        Pour toute information, vous pouvez contacter notre délégué à la protection des données à l’adresse suivante :  <Typography sx={{color:'#0086D1',fontWeight:600,overflowWrap:'break-word'}}component="a" href={'mailto:udm_contact@mousquetaires.com'}>udm_contact@mousquetaires.com</Typography>
        </Typography>
       
        <Typography sx={{color:'black'}}>
        En cas de réclamation, vous disposez du droit de saisir la CNIL.
        </Typography>
      </Box>
      <Box>
        <FormGroup sx={{display:'flex',alignItems:'center',textAlign:'left',marginY:2}}>
          <FormControlLabel control={
            <BpCheckbox checked={isChecked} onChange={(e)=>{setIsChecked(e.target.checked)}} />
          } label={<Typography>J’ai pris connaissance et accepte les <Typography component="a" href="/mentions-legales">CGU du site</Typography></Typography>} />
        </FormGroup>
        <Typography sx={{textAlign:'left'}}>Pour en savoir plus sur le traitement de vos données personnelles, <Typography component="a" href="/politique-donnees">cliquez ici</Typography></Typography>
      </Box>
      <Box sx={{marginTop:3.5}}>
        <Box  sx={{marginY:2}}>
          <Button variant="contained" onClick={()=>{handleValidate()}}disabled={!isChecked}>J'accède à mon espace</Button>
        </Box>  
        <Box>
          <Typography component="a" style={{color:'black',fontWeight:700,fontSize:'12px'}} href="https://changerdevie.mousquetaires.com">Revenir à l’accueil du site Changer de Vie</Typography>
        </Box>

      </Box>
    </DialogContent>
  </Dialog>
)};
export default ModalOnboarding;
