import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import React from 'react';
import AppLayout from '../../components/AppLayout/AppLayout';


const Page404Connect = () => {
  
  const theme = useTheme()
  
  return (
  <AppLayout>
    <Box sx={{minHeight:'calc(100vh - 300px)',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Box>
        <Typography variant="h1" sx={{fontSize:'80px',textAlign:'center'}} color={theme.palette.primary.main}>Erreur 404</Typography>
        <Typography  sx={{fontSize:'20px',textAlign:'center'}} >Rien a voir ici !</Typography>
      </Box>
    </Box>
  </AppLayout>
)};



export default Page404Connect;
