import {Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, {useCallback, useEffect, useState} from 'react'
import Dropzone from 'react-dropzone'
import UploadIcon from '@mui/icons-material/Upload';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm } from '../../../services/formContext';
import { v4 as uuidv4 } from 'uuid';
import ErrorsField from '../../ErrorsField/ErrorsField';
import { useNotifications } from '../../../services/notificationsContext';
import { convertInputTypesAcceptedToList, getFileExtension } from '../../../services/utils';
import { useUser } from '../../../services/userContext';
import slugify from 'slugify';

const FieldFile = (props) => {

  const {field,onChange} = props
  
  const [isDrop,setIsDrop] = useState(false)

  const isInAnswers = field.value &&  field.value !== 'to_empty_before_send' ? true : false

  const [fileToSend,setFileToSend] = useState()
  const [fileBase64,setFileBase64] = useState()
  const [nameFile,setNameFile] = useState()

  const {addFileinFileTemp,removeFileinFileTemp,setFieldsLoading} = useForm()
  const {pushNotifications} = useNotifications()
  const [errorsLocal,setErrorsLocal] = useState([])

  const {isDebug} = useUser()

  /* Problème lors de l'envoi de fichier déja enregistré -> A gérér */
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      let time = Date.now()
      console.log('Start File Read')

      setFieldsLoading(true)

      reader.onabort = () => pushNotifications('file reading was aborted','error')
      reader.onerror = () => pushNotifications('file reading was aborted','error')
      reader.onload = () => {

        console.log('End File Read %s ms',Date.now() - time)
        
        //console.log('Document loaded')
        const binaryStr = reader.result
        const newName = uuidv4()
        setFileBase64(binaryStr)
        setNameFile(newName)
        setFieldsLoading(false)


      }
      reader.readAsDataURL(file)

      if(file.size > field.max_size*1000000){
        setErrorsLocal([...errorsLocal, 'Le fichier est trop lourd, merci de compresser le fichier'])
      }
      if(file.size < field.max_size*1000000){
        setErrorsLocal([])
      }
      setFileToSend(file)
    })
    setIsDrop(true)
    // eslint-disable-next-line
  }, [])

  

  const handleChange = () => {

    if(field.name && fileToSend && fileToSend.name){
      onChange(field.name,nameFile,'file')
      addFileinFileTemp(field.name,nameFile,fileBase64)
    }
  }

  useEffect(()=>{
    handleChange()
    // eslint-disable-next-line
  },[fileBase64])

  const maxSize = field.max_size * 1000000 || 50000000
  const acceptedFiles = convertInputTypesAcceptedToList(field.accept)

  const onDropRejected = (reject) => {
    console.log('Explication du rejet',reject,acceptedFiles)
    reject.forEach((file)=>{ 
      //console.log(field)
      file.errors.forEach((error)=>{
        if(error.code === 'file-too-large'){
          setErrorsLocal([...errorsLocal, `La taille maximale du fichier attendu dépasse les ${field.max_size} Mo.`])
        }else{
          setErrorsLocal([...errorsLocal, 'Le type de fichier est invalide.'])
        } 
      })
    })


  } 

  //setErrorsLocal([...errorsLocal, 'Le fichier est trop lourd, merci de compresser le fichier'])

  const removeFile = (e) => {
    console.log('removeFile')
    e.preventDefault()
    setFileBase64(null) 
    if(fileToSend){
      removeFileinFileTemp(field.name,fileToSend.name)
    }
    setFileToSend(null)
    onChange(field.name,'to_empty_before_send','file')
    setIsDrop(false)
  }


  /* To do : Border Red quand y'a une erreur */
  const borderField = () => {
    if(field.errors || errorsLocal.length > 0){
      return '1px dashed #ED5052'
    }else if(isDrop || isInAnswers){
      return '1px solid #C2C2C2'
    }else{
      return '1px dashed #C2C2C2'
    }
  }

  return (
    <Box>
        {isDebug && <Typography>Taille des fichiers accepté : {maxSize/1000000}mo</Typography>}
        <Typography variant="inputLabel">{field.label} {field.isRequired && '*'}</Typography>
        {isDebug && 
        <Box>
          <Box>
            {field.name}
          </Box>
          <Box>
           {JSON.stringify(field.value)}
          </Box>
          <Box>
            isInAnswers : {JSON.stringify(isInAnswers)}
          </Box>
          <Box>
            Source: {field.source}
          </Box>

        </Box>}

        <Dropzone
              onDrop={onDrop}
              onDropRejected={onDropRejected}
              accept={acceptedFiles}
              maxSize={maxSize}
            >
              {({ getRootProps, getInputProps, isDragActive, open}) => (
                <Box sx={{backgroundColor:'white',padding:1,display:'flex',justifyContent:'space-between',
                alignItems:'center',
                width:'100%',
                borderRadius:'6px',
                marginTop:0.5,
                border:borderField(),
                cursor:'pointer'
                }}>
                  <Box sx={{width:'90%'}}  {...getRootProps()} >
                    <input {...getInputProps()}/>

                    {((!isDragActive && !isDrop && !isInAnswers) || (isDragActive && !isDrop)) &&
                      <Typography sx={{textOverflow: 'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>Insérer un justificatif</Typography>
                    }      

                    {isDrop && 
                      <Typography sx={{textOverflow: 'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>{fileToSend?.name}</Typography>
                    }
                    {isInAnswers && !isDrop && 
                      <Typography sx={{textOverflow: 'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>
                        {slugify(field.label,{lower:true,remove:/[*+~.()°'"!:@]/g})+'.'+getFileExtension(field.value)}
                      </Typography>
                    }

                  </Box>
                  <Box sx={{width:'10%',display:'flex',justifyContent:'flex-end'}}>
                    {(isDrop || isInAnswers) ? <CancelIcon onClick={(e)=>{removeFile(e)}} /> : <UploadIcon onClick={open}/>}
                  </Box>
                </Box>
              )}
            </Dropzone>
    <ErrorsField field={field} errors={errorsLocal} />
    {isDebug && JSON.stringify(field.errors)}
    {field.help &&
    <Box sx={{marginTop:1}}>
      <Typography>{field.help}</Typography>
    </Box>
    }
  </Box>
  )
};

export default FieldFile;
