import React, { useEffect, useState } from 'react';
import './HeaderUpload.scss';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const HeaderUpload = (props) => {

  const {field,headerUpload,isModal}= props
  const [infos,setInfos] = useState()

  useEffect(()=>{

    if(headerUpload){
      setInfos(headerUpload)
    }

    if(field){
      setInfos(field.header_upload)
    }

  },[field,headerUpload])

  return (
    <Box sx={{paddingLeft:'20px',width:"100%"}}>
    <Box sx={{backgroundColor:'#BCDFFF',marginTop:2, border:'1px #EDEDED solid', paddingX:"30px",paddingY:"20px", width:"100%",borderRadius:'12px',color:'#115479'}}>
      <Box sx={{display:'flex',alignItems:'center',gap:1, marginBottom:1}}>
        <InfoIcon fontSize={"small"} sx={{color:'black'}}/>
        <Typography sx={{color: '#115479',fontWeight:600,fontSize:'16px'}}>{infos?.title}</Typography>
      </Box>
      <Box>

      {!isModal && 
        <Box sx={{display:'flex',gap: 1,flexDirection:['column','row','row']}}>
          <Typography sx={{fontWeight:600, color:'#115479',fontSize:'14px'}}>Formats acceptés :</Typography>
          <Typography sx={{textTransform:'uppercase',fontStyle:'italic',color:'#115479', marginBottom:[1,0,0], fontSize:'14px'}}>{infos?.accept.join(', ')}</Typography>
        </Box>
        }

        {isModal &&
        
        <Box>
          <Typography sx={{fontSize:'14px',color:'#115479',fontStyle:'italic',textAlign:'left',marginBottom:1}}>
            {isModal && 'Le poids total des fichiers acceptés est de '+String(Number(process.env.REACT_APP_PHP_MAX_SIZE)/1000000 +' Mo')}
          </Typography>
        </Box>
        
        }
        
        {infos?.info_accept && 
        <Box>
          <Typography sx={{fontSize:'14px',color:'#115479',fontStyle:'italic',textAlign:'left'}}>
            {infos?.info_accept} 
          </Typography>
        </Box>
        }

        {infos?.info && 
          <Box sx={{marginTop:2}}>
            <Typography sx={{fontWeight:600, color:'#ED5052', fontSize:'14px',textAlign:'left'}}>
              {infos?.info}
            </Typography>
          </Box>
        }
        
      </Box>
    </Box>
  </Box>
)
};


export default HeaderUpload;
