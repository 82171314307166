import { Dialog, Typography, Box, Button } from '@mui/material';
import React from 'react';
import './ModalFormCompleted.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const ModalFormCompleted = (props) => {
  const {form,setModalFormCompleted} = props
  const navigate = useNavigate()

  const handleValidate = () => {
      navigate(form.path)
  }
  
  return (
  <>
  {form && 
  <Dialog open={form ? true : false} maxWidth="md" onClose={()=>{setModalFormCompleted(false)}} >
      <Box sx={{backgroundColor:'#EDEDED'}}>
        <Box sx={{padding:2}}>
          <Box onClick={()=>{setModalFormCompleted(false)}}sx={{display:'flex',alignItems:'center',gap:1,cursor:'pointer'}}>
            <ArrowBackIcon  fontSize="small"/>
            <Typography variant="body1" sx={{textDecoration:'underline'}}>Revenir à l’étape précédente</Typography>
          </Box>
        </Box>
        <Box sx={{backgroundColor:'white',paddingX:[4,4,10],paddingY:[3,3,8],borderRadius:2}}>
          <Box sx={{textAlign:'center',marginBottom:1}}>
            <Typography variant="h2">Ce formulaire contient déjà une sauvegarde.</Typography>
          </Box>
          <Box sx={{textAlign:'center',marginBottom:3}}>
            <Typography variant="body1">
            Vous souhaitez modifier une donnée liée au formulaire <span style={{fontWeight:600}}>“{form.form.name} - {form.target && form.target.title}”.</span> Souhaitez-vous vraiment le modifier ? 
            </Typography>
          </Box>
          <Box sx={{textAlign:'center'}}>
            <Button onClick={handleValidate}variant="contained">Valider</Button>
          </Box>
        </Box>
      </Box>
  </Dialog>
  }
  </>
  
)};


export default ModalFormCompleted;
