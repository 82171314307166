import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../services/userContext';

const ProtectedRoute = ({children}) => {
  
  const {isConnect} = useUser()

  if(!isConnect){
    return <Navigate to="/" replace />
  }

  return children
};

export default ProtectedRoute;
