import React from 'react';
import './DebugComptable.scss';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const DebugComptable = (props) => {
  
  const {fields} = props

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Valeur</TableCell>
              <TableCell>To Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {fields.map((field)=>(
            <TableRow>
              <TableCell>{field.name}</TableCell>
              <TableCell>{field.value}</TableCell>
              <TableCell>{field.to_total}</TableCell>
            </TableRow>
          ))}

          </TableBody>
        </Table>
      </TableContainer>
      

    </Box>
)};


export default DebugComptable;
