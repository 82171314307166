import React,{useEffect, useState} from 'react'
import { Box } from '@mui/material'
import { Etape } from '../Etape/Etape'
import { getForm, getFormGroup } from '../../services/api';
import navItems from "../../components/NavItems/NavItems";
import jMoment from 'moment-jalaali'
import LoadingFullPage from '../LoadingFullPage/LoadingFullPage';

function Etapes({candidacy}) {

   const [forms,setForms] = useState({})
   const [isLoading,setIsLoading] = useState(false)

   useEffect(()=>{
    const initForms = async () => {
        setIsLoading(true)
        const dossierFinancier = await getFormGroup('dossier-financier')
        const dossierAdministratif = await getFormGroup('dossier-administratif')
        const decouvertePointDeVente = await getFormGroup('decouverte-point-de-vente')
        setForms({dossierFinancier,dossierAdministratif,decouvertePointDeVente})
        setIsLoading(false)
    }
    initForms()



    },[])




  return (
    <Box>
        {isLoading && 
        <Box sx={{display:'flex',justifyContent:'center'}}>
            <LoadingFullPage />
        </Box>
        }
        {!isLoading &&
        <Box className="etapes-container" sx={{display:'flex',gap:2,boxSizing:'border-box',width:'100%',justifyContent:'space-between',flexWrap:['wrap','wrap','inherit']}}>
            <div className='line'></div>
                {candidacy && candidacy.steps.map((step,key)=> (
                <>
                    <Etape 
                        name={step.title}
                        isActive={getEtapeIsActive(step, candidacy,forms)}
                        isCompleted={getEtapeIsCompleted(step, candidacy,forms)}
                        isDisabled={getEtapeIsDisabled(step, candidacy,forms)}
                        label={getEtapeLabel(step, candidacy,forms)}
                        subLabel={getEtapeSubLabel(step, candidacy,forms)}
                        link={getEtapeLink(step)}
                        isClickable={getIsClickable(step,candidacy,forms)}
                        date={getEtapeDate(step, candidacy)}
                    />
                </>

                ))}
        </Box>
        }
    </Box>

  )
}

export default Etapes



/* Si l'étape est considérer comme active, on affiche le fond rouge et le texte en blanc  */
const getEtapeIsActive = (step,candidacy) => {
    return step.is_next ? true : false
}

/* Si le numéro de l'étape dépasse le numéro de l'étape actuelle, on considère l'étape comme complété */

const getEtapeIsCompleted = (step,candidacy) => {
    
    return candidacy.next_step > step.id ? true : false
}


/* Si l'étape est complété, on considère l'étape comme désactivé */

const getEtapeIsDisabled = (step,candidacy,forms) => {
    return getEtapeIsCompleted(step,candidacy) ? true : false
}


/* On affiche le label de l'étape en fonction de l'état de l'étape

- Si l'étape est complété, on affiche "Envoyé"
- Si l'étape en question est Dossier Adminsitratif, Dossier Financier ou Découverte Point de vente:
    - Si le formulaire a des exports en cours et que les fichiers ne sont pas validés, on affiche "Envoyé"
    - Si le formulaire est complété, on affiche "Envoyé"
    - Si le formulaire est débloqué, on affiche "A compléter"
    - Si l'étape est active, on affiche "A compléter"
- Sinon, on affiche "A venir"


*/

const getEtapeLabel = (step,candidacy,forms) => {
    // Cas spécifique Dossier Administratif

    if(getEtapeIsCompleted(step,candidacy)){
        return 'Complété'
    }


    if(step.title === 'Dossier administratif'){
        const form = forms?.dossierAdministratif
        if(form?.are_files_validated || form?.has_pending_exports){
            return 'Envoyé'
        }
        if(form?.is_unlocked){
            return 'A compléter'
        }
        if(getEtapeIsActive(step,candidacy)){
            return 'A compléter'
        }
    }

    // Cas spécifique Decouverte point de vente
    if(step.title === 'Découverte point de vente'){
        const form = forms?.decouvertePointDeVente
        if(form?.are_files_validated || form?.has_pending_exports){
            return 'Envoyé'
        }
        if(form?.is_unlocked){
            return 'A compléter'
        }
        if(getEtapeIsActive(step,candidacy)){
            return 'A compléter'
        }
    }

    // Cas spécifique Dossier Financier
    if(step.title === 'Dossier financier'){
        const form = forms?.dossierFinancier
        if(form?.are_files_validated || form?.has_pending_exports){
            return 'Envoyé'
        }
        if(form?.is_unlocked){
            return 'A compléter'
        }
        if(getEtapeIsActive(step,candidacy)){
            return 'A compléter'
        }
    }

    return 'A venir'
}

/*
Si l'étape est active et qu'il y a un lien, on considère l'étape comme clickable
Si l'étape a le label "A compléter" et qu'il y a un lien, on considère l'étape comme clickable
 */

const getIsClickable = (step,candicacy,forms) => {

    const link = getEtapeLink(step)
    const isCompleted = getEtapeIsCompleted(step,candicacy)
    const label = getEtapeLabel(step,candicacy,forms)

    if(label === 'A compléter' && link){
        return true
    }

    return link && !isCompleted ? true : false

}

/* Si l'étape en cours est Entretien 1, on affiche "(sous réserve que votre dossier administratif soit validé)" */

const getEtapeSubLabel = (step,candidacy) => {
    if(step.id === 10 && candidacy.next_step  === 10){
        return "(sous réserve que votre dossier administratif soit validé)"
    }
    return null
}

/* Si l'étape est active et qu'il y a une date de prochaine étape, on affiche la date de la prochaine étape */

const getEtapeDate = (step,candidacy) => {

    if(step.is_next &&  candidacy.next_step_at){
        return  jMoment(candidacy.next_step_at).format('LLLL')
    }
    return null
}

const getEtapeLink = (step) => {

    return navItems.find(item => item.title === step.title) ? navItems.find(item => item.title === step.title).link : null
}