import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import './LoadingFullPage.scss';

const LoadingFullPage = () => (
  <Box>
    <CircularProgress />
  </Box>
);

export default LoadingFullPage;
