import { Box,Stepper,Step,Typography,StepLabel, useMediaQuery} from '@mui/material';
import React from 'react';
import './StepDossier.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@emotion/react';

const StepDossier = (props) => {
  
  const {forms} = props
  const theme = useTheme();
  const isDesktop =  useMediaQuery(theme.breakpoints.up('sm'))


  const icon = (props) => {
    if(props.completed){
      return <CheckCircleIcon sx={{width:'20px',height:'20px',color:'#67B870'}} />
    }else{
      return <Box sx={{width:'20px',height:'20px',backgroundColor:'#E0E0E0',borderRadius:'50%',marginLeft:'2px'}}></Box>
    }
  }

  const isCompleted = (form) => {
    let count_target_completed = 0
    if(form?.targets){
      form?.targets?.forEach((target)=>{
        if(target.is_completed){
          count_target_completed++
        }
      })
    }
    return form?.targets?.length === count_target_completed
  }

  return (
    <Box>
      <Stepper alternativeLabel={isDesktop} orientation={isDesktop ? 'horizontal' : 'vertical'}>
      {forms?.map((form,index)=>(
        <Step key={form.slug} color="inherit " completed={isCompleted(form)}>
          <StepLabel StepIconComponent={icon}>
              <Typography sx={{color:isCompleted(form) ? '#67B870' : '#0A0A0A',fontSize:['16px','16px','12px'],fontWeight:700}}>
                {form.name}
              </Typography>
            </StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
  )
};




export default StepDossier;
