import React from 'react';
import { Box } from '@mui/system';
import { Button,Typography, useTheme } from '@mui/material';
import ConnexionLayout from '../../../components/ConnexionLayout/ConnexionLayout';




const ConfirmationPassword = (props) => {

  const theme = useTheme();
  return (
      <ConnexionLayout {...props}>
          <Box sx={{zIndex:2,width:'800px',maxWidth:'90vw',borderRadius:theme.shape.borderRadius,backgroundColor:theme.palette.primary.main}}>
              <Box sx={{paddingX:'100px',paddingY:'40px',backgroundColor:theme.palette.background.default,borderRadius:theme.shape.borderRadius}}>
                  <Box sx={{textAlign:'center',marginBottom:'20px'}}>
                    <Typography variant="h1" sx={{marginBottom:'10px'}}>
                      Mon espace recrutement
                    </Typography>
                    <Typography>
                    Votre mot de passe a bien été initialisé. Vous pouvez maintenant vous connecter. 
                    </Typography>
                    <Button sx={{marginTop:'20px'}} variant='contained'>Me connecter</Button>
                  </Box>
              </Box>
          </Box>
        </ConnexionLayout>
    )
};

export default ConfirmationPassword;
