import React, {useContext,useEffect,useState} from "react"
import { getCandidacy, getCandidateMe, getLoginToken, getNotifications, getRefreshToken, logOutToken } from "./api"
import { toEmptyCandidateData, getSelfCandidate,getTokenDataInLocalStorage, setTokenDataInLocalStorage, setCandidateInLocalStorage, setCandidacyInLocalStorage, getCandidacyInLocalStorage, setDebugBarStatus, getDebugBarStatus } from "./localStorage"
import { useNotifications } from "./notificationsContext"
import { useCurrentWindow } from "./useCurrentWindow"
import { tokenIsRevoked } from "./utils"
const UserContext = React.createContext()


export const useUser = () => {
    return useContext(UserContext)
}

export const UserProvider = ({children}) => {

    const testIfUserIsConnect = () => {
        if(tokenIsRevoked()){
            return false
        }
        if(user){
            return true
        }else{
            return false
        }
    }

    const [user,setUser] = useState(getSelfCandidate());
    const [needReload,setNeedReload] = useState(false)
    const [notifications,setNotifications] = useState()
    const [token,setToken] = useState()
    const [candidacy,setCandidacy] = useState(getCandidacyInLocalStorage() || false)
    const [isConnect,setIsConnect] = useState(testIfUserIsConnect())

    const [isDebug,setIsDebug] = useState(getDebugBarStatus() || false)
    const toggleDebug = () => {
        setDebugBarStatus(isDebug ? false : true)
        setIsDebug(isDebug ? false : true)
    }

    useEffect(()=>{

        const initUser = async () => {
            const candidate = await getCandidateMe()
            setUser(candidate || null)
        }
        initUser()

    },[])



    const {pushNotifications} = useNotifications()

    useEffect(()=>{
        // Update token in local storage
        if(token && token !== 'undefined'){
            setTokenDataInLocalStorage(token)

        }else if(token === 'undefined' || token === ''){
            const tokenInLocalStorage = getTokenDataInLocalStorage()?.token
            setToken(tokenInLocalStorage)
        }   
        // eslint-disable-next-line
    },[token])

    useEffect(()=>{
       if(user && tokenIsRevoked()){
           refreshToken()
       }
       // eslint-disable-next-line
    },[isConnect])



    useEffect(()=>{
        if(user && !tokenIsRevoked()){
            const initCandicacy = async () => {
                const _candidacy = await getCandidacy(user.candidacy_id,token)
                setCandidacy(_candidacy)
                setCandidacyInLocalStorage(_candidacy)
            }
            initCandicacy()
        }
        // eslint-disable-next-line
    },[user])

    useEffect(()=>{
        setIsConnect(testIfUserIsConnect())
         // eslint-disable-next-line
    },[])


    const logOut = async () => {
        const {token} = getTokenDataInLocalStorage()
        toEmptyCandidateData();
        setUser(false)
        setCandidacy(false)
        setIsConnect(false)
        setToken(false)
        setNotifications(false)
        const response = await logOutToken(token)
        pushNotifications(response.message)

    }




    const refreshToken = async () => {
        const refreshToken = await getRefreshToken(user.mail);
        if(refreshToken && !refreshToken.message){
            setIsConnect(true)
            setToken(refreshToken.access_token)
            pushNotifications("Token reinitialisé !",'info');
            return true
        }else{
            return false
        }
    };

  
   
    const connectUser = async (mail,password) => {

        toEmptyCandidateData()
        // On refresh le token si il est déja connecté en local
        const candidate = getSelfCandidate()
        if(candidate){
            refreshToken()
        }

        // On connecte l'utilisateur
        const response = await getLoginToken(mail,password)
        if(response.access_token){
            setCandidateInLocalStorage(response.candidate)
            setToken(response.access_token)
            setUser(response.candidate)

            const _candidacy = await getCandidacy(response.candidate.candidacy_id,response.access_token)
            setCandidacyInLocalStorage(_candidacy)
            setCandidacy(_candidacy)

            setIsConnect(true)
            initNotifications()
        }
        
        return response;
    }
    

    const initNotifications = async () => {

        const response = await getNotifications()

        if(response.message){
            pushNotifications(response.message,'error')
            setIsConnect(false)
            setUser(null)
        }
        setNotifications(response)
    }

    useEffect(()=>{
        if(!notifications && user){
            initNotifications()
        }
        // eslint-disable-next-line
    },[notifications])



    useEffect(() => {

        if(user && !tokenIsRevoked()){
            reloadNotifications(true)
            const interval = setInterval(() => {
                reloadNotifications(true)
            }, 30000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
      }, [user]);


    const isCurrentWindow = useCurrentWindow(document)

    const reloadNotifications = (force) => {

        const needIfIsCurrentWindow = force || isCurrentWindow

        if(user && !tokenIsRevoked() && needIfIsCurrentWindow){
            initNotifications()
        }else{
            setUser(false)
        }
    };



    useEffect(() => {
        const initUser = async () => {
            if(needReload){
                if(!user && token.token){
                    const candidate = await getCandidateMe(token.token)
                    setUser(candidate)
                    setIsConnect(true)
                }
                setNeedReload(false)
            }
        }
        initUser()
    },[user,token,needReload])

    const getTypeParcours = () => {
        return candidacy?.mercure_type 
    }



    return(
        <UserContext.Provider value={{isConnect,user,token,connectUser,refreshToken,logOut,notifications,reloadNotifications,getTypeParcours,candidacy,toggleDebug,isDebug}} >
            {children}
        </UserContext.Provider>
    )
}
