
import { Typography,Box } from '@mui/material';
import React,{useEffect, useState} from 'react';
import AppLayout from '../../../components/AppLayout/AppLayout';
import CardRounded from '../../../components/CardRounded/CardRounded';
import { useTheme } from '@emotion/react';
import { getArea } from '../../../services/api';
import { useUser } from '../../../services/userContext';


const Contact = () => {
  const theme = useTheme()

  const {user} = useUser()

  const [isLoading,setIsLoading] = useState(false)
  const [area,setArea] = useState(false)

  useEffect(()=>{

    if(user?.area_id){
      const initArea = async () => {
        setIsLoading(true)
        const areas = await getArea(user?.area_id)
        setIsLoading(false)
        setArea(areas)
      }

      if(!isLoading && !area){
      initArea()
      }
    }

    // eslint-disable-next-line
  },[isLoading])


  return (
  <AppLayout isLoading={isLoading}>
    <Typography sx={{marginBottom:1,fontWeight:600}} variant="h2">Vos contacts</Typography>
    <Typography sx={{marginBottom:5}} variant="body1">Vous avez une question ? <br />N'hésitez pas à contacter nos équipes. Nous répondrons à toutes vos questions dans les plus brefs délais. </Typography>
    <CardRounded>
      <Typography sx={{color:theme.palette.primary.main,fontWeight:500,marginBottom:1}} variant="h4">Équipe Tréville</Typography>

      <Box sx={{display:'flex',flexDirection:['column','row','row'],gap:0.5,overflowWrap:'anywhere'}}>
        <Typography variant="body2">Mail : </Typography>
        <Typography component="a" variant="body2" sx={{fontWeight:500,marginBottom:1}} href={'mailto:udm_recrutement@mousquetaires.com'}>udm_recrutement@mousquetaires.com</Typography>
      </Box>
      <Box sx={{display:'flex', gap:0.5, flexDirection:['column','row','row']}}>
        <Typography variant="body2">Tel : </Typography>
        <Typography component="a" variant="body2" sx={{fontWeight:500,marginBottom:1,overflowWrap:'anywhere'}} href={'tel:0169641020'}>01.69.64.10.20</Typography>
      </Box>
    </CardRounded>

    {user?.area_id && 
    <CardRounded>
      <Typography sx={{color:theme.palette.primary.main,fontWeight:500}} variant="h4">Équipe {area.name}</Typography>
      <Box>
      {area?.contacts?.map((contact,key)=>(
          <Box sx={{marginY:1}} key={key}>
            <Typography component="span" variant="body2" sx={{overflowWrap:'anywhere'}}>{contact.first_name} {contact.last_name} : </Typography>
            <Typography component="a" href={'mailto:'+contact.email} variant="body2" sx={{fontWeight:500,overflowWrap:'anywhere'}}>{contact.email}</Typography>
          </Box>

      ))}
      </Box>
    </CardRounded>
    }

    {!user.area_id &&
      <Box sx={{marginY:2}}>
        <Typography>Aucune région n'a été renseigné pour votre compte !</Typography>
      </Box>
    }
  </AppLayout>
)};

export default Contact;
