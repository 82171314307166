import React from 'react';
import './FieldDownload.scss';
import { Box, InputLabel, Typography, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { download } from '../../services/utils';
import { useNotifications } from '../../services/notificationsContext';
import HTMLComponent from '../HTMLComponent/HTMLComponent';

const FieldDownload = (props) => {

  const {field} = props
  const {pushNotifications} = useNotifications

  const handleClick = () => {
    download(field.url,field.file_name)
    pushNotifications('Fichier en cours de téléchargement')
  }

  return (<Box>
      <InputLabel htmlFor={props.name}>
        <Typography variant="inputLabel">{field.label}</Typography>
          <Box sx={{color:'#0A0A0A'}} variant="body1">
            <HTMLComponent>{field.help}</HTMLComponent>
          </Box>
        <Button onClick={handleClick} variant="outlined" startIcon={<DownloadIcon />}>{field.file_name}</Button>
      </InputLabel>
  </Box>)
  };


export default FieldDownload;
