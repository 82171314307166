import { Dialog,Box, Typography, Button, IconButton } from '@mui/material';
import React from 'react';
import './ModalSendingDossier.scss';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import MailUDM from '../MailUDM/MailUDM';
import { useNavigate } from 'react-router-dom';


const ModalSendingDossier = (props) => {

  const {open} = props
  const navigate = useNavigate()

  const handleCloseModal = () => {
    navigate('/dashboard')
  }
  const handleClickButton = () => {
    navigate('/dashboard')
  }

  
  return(
  <Dialog open={open} maxWidth="md">
     <Box sx={{position:'absolute',right:5,top:5}}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
    <Box sx={{paddingX:[3,3,10],paddingY:[3,3,8], textAlign:'center'}}>
      <Box>
        <Typography variant="h1">Votre dossier a bien été envoyé</Typography>
        <Typography sx={{marginTop:2}}variant="body1">Nous vous tiendrons informé(s) de la bonne conformité de votre dossier. <br/>
        Dans le cas contraire, il vous sera demandé de le modifier. </Typography>
      </Box>

      <Box sx={{marginY:4, border: '1px solid #EDEDED',borderRadius:'12px',padding:[2,2,4]}}>
        <Box sx={{display:'flex',alignItems:'center',gap:1,marginBottom:2}}>
            <Box><HelpIcon /></Box>
            <Box><Typography sx={{fontWeight:600,color:'black',textAlign:['left','center','center ']}}>Vous avez une question ?</Typography></Box>
        </Box>
        <Box sx={{overflowWrap:'break-word'}}>
            <Typography sx={{textAlign:'left'}}>
            Si vous souhaitez apporter une modification à votre dossier avant qu'il soit vérifié, n'hésitez pas à nous contacter via : <MailUDM />
            </Typography>
        </Box>
      </Box>

      <Box sx={{marginY:4}}>
        <Typography>
        Vos données personnelles font l’objet d'un traitement par l’Union des Mousquetaires à des fins de gestion de votre candidature. Ce traitement est fondé sur les mesures précontractuelles. Pour en savoir plus sur vos droits, <Typography component="a" sx={{color:'black',fontWeight:'600'}} href="/politique-donnees">cliquez ici</Typography>.
        </Typography>
      </Box>

      <Box>
        <Button onClick={handleClickButton} variant="contained">Retourner au tableau de bord</Button>
      </Box>
      

    </Box>
  </Dialog>
)};


export default ModalSendingDossier;
