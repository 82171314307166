import React, { useState, useEffect } from "react";
import { Box, InputLabel, Typography, Autocomplete, Chip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./FieldSelect.scss";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { useForm } from "../../../services/formContext";
import { getDepartmentsByRegions, replaceIndex } from "../../../services/utils";
import ErrorsField from "../../ErrorsField/ErrorsField";
import { useUser } from "../../../services/userContext";

const FieldSelect = (props) => {
  const { onChange,fieldInProps = props.field} = props;
  const [field,setField] = useState(fieldInProps)
  const options = field.options;

  const getValueInOptions = (value) => {
    const optionsWithValue = options.find(option => option.value === value)
    if(optionsWithValue){
      return optionsWithValue
    }else{
      return value
    }
  }

  //const [value, setValue] = useState(getValueInOptions(field.value ? field.value : null));
  const [value, setValue] = useState(field.value);
  const [open, setOpen] = useState(false);

  const {saveValue,getValue,getMultiplesValues,nameForm,fieldToUpdate} = useForm()

  const {isDebug} = useUser()


  useEffect(()=>{
    if(field.name === fieldToUpdate){
      setField(props.field)
      console.log('update fieldselect')
    }
    // eslint-disable-next-line
  },[fieldToUpdate])

  useEffect(()=>{
    setField(fieldInProps)
    setValue(getValueInOptions(fieldInProps.value ? fieldInProps.value : null))
    // eslint-disable-next-line
  },[fieldInProps])


  if (field.type === "select") {
    const toggleOpen = () => {
      setOpen(open ? false : true);
    };

    let className = "TextFieldCustom FieldSelect";
    if (value?.length && value.length > 0) {
      className += " hasContent";
    }
    if(props.field.errors){
      className += " isError";
    }

    const handleChange = (event, newValue) => {
      //console.log('handleChange',newValue)
      setValue(newValue || '');
      if(newValue?.value){
        onChange(field.name, newValue.value);
      }else{
        onChange(field.name, newValue);
      }
    }

    return (
      <>
        <label>
          {isDebug && field.name + ' : ' + field.value}
          <Autocomplete
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            options={options}
            onOpen={() => {
              setOpen(true);
            }}
            onChange={handleChange}
            value={value || null}
            renderInput={(params) => (
              <Box className={className}>
                <InputLabel htmlFor={field.name}>
                  <Typography variant="inputLabel">{field.label} {field.isRequired && '*'}</Typography>
                </InputLabel>
                <div className="select-container" ref={params.InputProps.ref}>
                  <div className="click-area" onClick={toggleOpen}></div>
                  <input type="text" {...params.inputProps} />
                  <ArrowDropDownIcon onClick={toggleOpen} fontSize="small" />
                </div>
              </Box>
            )}
          />
          <ErrorsField errors={props.field.errors} />
        </label>
      </>
    );
  }

  if (field.type === "select_multiple") {

    const handleChange = (data) => {
      // On clean les données précédentes
      const countValuesInStorage = getValue(field.name,nameForm)
      for(let i = 0; i < countValuesInStorage; i++){
        saveValue(replaceIndex(field.name,i.toString()),'',nameForm)
      }

      // Enregistrement des values dans un tableau muliple
      const countValues = data.length
      saveValue(field.name,countValues,nameForm,'count')
      for(let i = 0; i < countValues; i++){
        saveValue(replaceIndex(field.name,i.toString()),data[i],nameForm)
      }
    }

    const filterSelectMultiple = (field) => {
      // On filtre ici pour faire le fonctionneemnt avec les departement
      if(field.name === 'departments[%index%]'){
       
        const countRegionsSelected = getValue('wanted_areas[%index%]',nameForm)
        const regionsSelected = getMultiplesValues('wanted_areas[%index%]',nameForm,null,countRegionsSelected)
        field.options = getDepartmentsByRegions(regionsSelected)
      }
      //field.value = getMultiplesValues(field.name,nameForm)
      return field
    }
    let newField = filterSelectMultiple(field)

   

    return (
      <>
        <SelectMultiple field={newField} onChange={handleChange} />
      </>
    );
  }
};

export default FieldSelect;

function Tag(props) {
  const { label, onDelete } = props;
  return <Chip label={label} onDelete={onDelete} />;
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: white;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 5;

  & li {
    padding: 10px 15px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.grey[100]};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.primary.light};
    font-weight: 600;

    & svg {
      color: ${theme.palette.primary.main};
    }
  }
`
);

function SelectMultiple(props) {
  const { field,onChange } = props;


  //console.log('Field Multiple',field)

  const [errors,setErrors] = useState(props.field.errors || [])
  //const [valueField,setValueField] = useState(field.value || [])
  const [inputValue,setInputValue] = useState(field.value || [])

  useEffect(()=>{
    setInputValue(field.value)
      if(props.field.errors){
        setErrors(props.field.errors)
      }
      // eslint-disable-next-line
  },[field])

  const onClose = () => {
    console.log('onClose')
  }

  // eslint-disable-next-line
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    setAnchorEl,
  } = useAutocomplete({
    id: field.name,
    defaultValue:inputValue,
    multiple: true,
    options: field.options,
    onClose,
    getOptionLabel: (option) => option
  });

  const handleOpenListBox = () => {
    const inputsProps = getInputProps();
    inputsProps.onMouseDown();
  };


 

  let className = "TextFieldCustom FieldSelect";
  if (value?.length && value.length > 0) {
    className += " hasContent";
  }

  if (errors.length > 0) {
    className += " isError";
  }

  const [isInit,setIsInit] = useState(false)

  useEffect(() => {

    if(isInit){
      if(field.hasOwnProperty('maxlength')){
        let textError = `Ce champ n'accepte que ${field.maxlength} valeurs`
        if(value.length <= field.maxlength){
          console.log('onChange on ',value )
          onChange(value);
          // On remove l'erreur
           let newErrors = errors?.filter(error => error !== textError)
           setErrors(newErrors)
        }else{
          setErrors([textError])
        }
      }else{
        onChange(value);
      }
    }
    if(!isInit && value.length !== 0){
      setIsInit(true)
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(()=>{
    if(!isInit){
      onChange(value);
    }
     // eslint-disable-next-line
  },[value])

  const {isDebug} = useUser()

  return (
    <Box>
    <Box sx={{ position: "relative" }}>
      <div {...getRootProps()}> 
        <Box className={className}>
          {isDebug && (field.name + ':' + JSON.stringify(field.value))}
          <InputLabel htmlFor={field.name} {...getInputLabelProps()}>
            <Typography variant="inputLabel">{field.label} {field.isRequired && '*'}</Typography>
          </InputLabel>
          <div className="select-container" ref={setAnchorEl}>
            <div className="click-area" onClick={handleOpenListBox}></div>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                width: "calc(100% - 20px)",
                flexWrap: "wrap",
              }}
            >
              {field && Array.isArray(field.value) && field?.value?.map((option, index) => (
                <><Tag label={option} {...getTagProps({ index })} /></>
              ))}

              {field.value.length < 1 && value && value.map((option, index) => (
                <><Tag label={option} {...getTagProps({ index })} /></>
              ))}
              <input className="multiple" {...getInputProps()} />
            </Box>

            <ArrowDropDownIcon fontSize="small" onClick={handleOpenListBox} />
          </div>
        </Box>
      </div>

      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })} key={index}>
              <span>
                <Typography variant="body1">{option}</Typography>
              </span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
       
    </Box>
    <ErrorsField errors={errors} />
   </Box>
  );
}
