import React, {useState} from 'react';
import { renderEmail } from "react-html-email";
import './GenerateEmail.scss';
import { Autocomplete, Box,TextField,Button, Typography, Grid } from '@mui/material';

import InvitationMail from '../../emails/Invitation';
import ReussitePremierEntretien from '../../emails/ReussitePremierEntretien';
import { download } from '../../services/utils';
import JourneeInformation from '../../emails/JourneeInformations';
import DecouvertePDV from '../../emails/DecouvertePDV';
import RenouvellementMDP from '../../emails/RenouvellementMDP';

const GenerateEmail = () => {

  const [selectedEmail, setSelectedEmail] = useState('')
  const [renduHTML,setRenduHTML] = useState('')
  
  const listesEmail = [{
    label:'Invitation',
    nomFichier:'invitation.html',
    component:<InvitationMail />
  },{
    label:'Réussite Premier entretien',
    nomFichier:'premierentretien.html',
    component:<ReussitePremierEntretien />
  },
  {
    label:'Journée d\'information',
    nomFichier:'journeeinformation.html',
    component:<JourneeInformation />
  },
  {
    label:'Découverte point de vente',
    nomFichier:'decouvertepointdevente.html',
    component:<DecouvertePDV />
  },
  {
    label:'Renouvellement MDP',
    nomFichier:'renouvellementmdp.html',
    component:<RenouvellementMDP />
  }]

  const handleChangeSelect = (e,newEmail) => {
    setSelectedEmail(newEmail)
    const htmlRender = renderEmail(getCurrentComponent(newEmail))
    setRenduHTML(htmlRender)
  }

  const getCurrentComponent = (newEmail) => {
    const currentEmail = listesEmail.find((email) => email.label === (newEmail ? newEmail : selectedEmail))
    return currentEmail?.component
  }

  const handleDownloadTemplate = () => {
    const currentEmail = listesEmail.find((email) => email.label === selectedEmail)
    const htmlRender = renderEmail(getCurrentComponent())
    console.log(htmlRender)
    download('data:text/html;charset=UTF-8,'+encodeURIComponent(htmlRender),currentEmail.nomFichier)
  }

  const handleDownloadAllTemplate = () => {
    listesEmail.forEach((email)=>{
      console.log(email)
      const htmlRender = renderEmail(getCurrentComponent(email.label))
      console.log(htmlRender)
      download('data:text/html;charset=UTF-8,'+encodeURIComponent(htmlRender),email.nomFichier)
    })
  }


  return (
    <Box>
    <Box sx={{width:'500px',margin:'auto',marginTop:'30px'}}>
      <Autocomplete
        disablePortal
        options={listesEmail.map((element)=>(element.label))}
        renderInput={(params) => <TextField {...params} label="Email a générer" />}
        onChange={handleChangeSelect}
      />
      <Box sx={{marginTop:'10px'}}>
        <Button variant="contained" onClick={handleDownloadTemplate}>Télécharger la template</Button>
        <Button variant="contained" sx={{marginLeft:'10px'}} onClick={handleDownloadAllTemplate}>Tout télécharger</Button>
      </Box>

      <Box sx={{marginTop:'20px'}}>
        <Typography variant="h2">Prévisualisation</Typography>
      </Box>
    </Box>

    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box sx={{marginBottom:'20px'}}>
          <Typography variant="h2">Rendu React</Typography>
        </Box>
          {getCurrentComponent()}
      </Grid>
      <Grid item xs={6}>
        <Box sx={{marginBottom:'20px'}}>
          <Typography variant="h2">Rendu HTML</Typography>
        </Box>
        {<div dangerouslySetInnerHTML={{__html: renduHTML}} />}
      </Grid>
    </Grid>



    </Box>
)};

export default GenerateEmail;
