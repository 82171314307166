import React from 'react';
import { AppBar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Logo from '../Logo/Logo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


const HeaderConnexion = (props) => {
  const navigate = useNavigate();
  const handleBackPage = () => {
   navigate(-1)
  }

  return (<>
  <AppBar color="secondary" sx={{borderRadius:'0px',position:'fixed'}}>
    <Box sx={{height:['fit-content','fit-content','125px'],
    display:'flex',
    justifyContent:props.isHome || props.isPage ? 'space-between' : 'center',
    flexDirection:['column-reverse','column-reverse','row'],
    alignItems:'center',
    paddingX:5,
    boxSizing:'border-box',
    paddingY:[1.5,1.5,null],
    gap:[1,1,null]}}>

      {props.isHome && 
        <Box sx={{display:'flex',alignItems:'center',width:'300px',justifyContent:['center','center','flex-start']}} color='black'>
          <ArrowBackIcon  sx={{marginRight:1}}/>
          <a target="_blank" rel="noreferrer" style={{color:"#616151"}} href="https://changerdevie.mousquetaires.com/">
            <Typography sx={{fontSize:'18px',fontWeight:400,textDecoration:'none'}}>
              Revenir à l'accueil du site
            </Typography>
          </a>
        </Box>
      }

    {props.isPage && 
        <Box onClick={handleBackPage} sx={{display:'flex',cursor:'pointer',alignItems:'center',width:'300px',justifyContent:['center','center','flex-start']}} color='black'>
          <ArrowBackIcon sx={{marginRight:1}}/>
            <Typography sx={{fontSize:'18px',fontWeight:400,textDecoration:'underline',color:"#616151"}}>
              Retour
            </Typography>
        </Box>
      }

      <Box sx={{width:['150px','150px','200px']}}>
        <Logo />
      </Box>
      {(props.isHome || props.isPage) && <Box sx={{width:'300px'}}></Box>}
      
    </Box>
  </AppBar>
  </>)
  };

export default HeaderConnexion;
