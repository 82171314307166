import { Box, Grid, Typography,CircularProgress, Link  } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { getForm } from '../../services/api';
import './FieldFormLink.scss';
import EditIcon from '@mui/icons-material/Edit';


const FieldFormLink = (props) => {

  const {field} = props
  const [targets,setTargets] = useState([]);
  const [isInit,setIsInit] = useState(false)
  const [isLoading,setIsLoading] = useState(false)

  useEffect(()=>{
      const initFormGroup = async () => {
        if(!isLoading){
          setIsLoading(true)
          console.log('FLAG 1',field.form+'/targets')
          const targets = await getForm(field.form+'/targets')
          const _targets = targets.targets
          setTargets(_targets)
          setIsLoading(false)
          setIsInit(true)
        }
      }

      // if(!isInit && !isLoading && !currentFormGroup){
      //   initFormGroup()
      // }

        if(!isInit && !isLoading){
        initFormGroup()
      }

  },[field,isLoading,isInit])


  
  return(
    
    <Box>
      {!isLoading &&
      <Grid container  spacing={2}>
                  {targets?.length > 0 && targets?.map((target,key)=>(
                        <Grid item key={key} sx={{cursor:'pointer'}} xs={12} md={target.target_id && targets.length >= 2 ? 6  : 12} >
                           <Link href={target.target_id ? '/formulaire/'+field.form+'/'+target.target_id : '/formulaire/'+field.form} sx={{textDecoration:'none'}}>
                            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',background:'white',paddingX:4,paddingY:3,borderRadius:'12px',border:'2px solid',borderColor:target.is_completed ? '#67B870' : '#EDEDED'}}>
                              <Box><Typography sx={{fontSize:'16px',fontWeight:'500',color:'#0A0A0A'}}>Patrimoine {target.title}</Typography></Box>
                              <Box><EditIcon sx={{width:'18px',height:'18px'}}/></Box>
                            </Box>
                          </Link>
                        </Grid>
                  ))}
                </Grid>
      }
      {isLoading && 
      <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',padding:4}}>
        <CircularProgress />
      </Box>
      }
    </Box> 
  )
};

export default FieldFormLink;
