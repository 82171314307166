import { Dialog, Typography,Box, Button, FormControlLabel} from '@mui/material';
import React,{useState} from 'react';
import { setModalInfoParcoursCoupleIsViewed} from '../../services/localStorage';
import { BpCheckbox } from '../Fields/FieldCheckbox/FieldCheckbox';
import './ModalInfoParcoursCouple.scss';


const ModalInfoParcoursCouple = (props) => {
  const {open,setOpen,candidateTarget} = props
  const [isChecked,setIsChecked] = useState(false)

  const handleClose = () => {
    if(isChecked){
      setModalInfoParcoursCoupleIsViewed(candidateTarget?.target_id)
    }
    setOpen(false)
  }

  return (
    <Dialog open={open} maxWidth="md" onClose={handleClose} >
        <Box sx={{paddingX:[3,3,10],paddingY:[3,3,8],textAlign:'center'}}>
            <Typography sx={{marginBottom:4}}variant="h2">Vous êtes dans un parcours couple</Typography>

            <Box>
              <Typography variant="body1">Les deux candidats vont devoir compléter chacun un formulaire. Un formulaire commun est également disponible.</Typography>
              <Typography variant="body1" sx={{fontWeight:500}}>{candidateTarget ? 'Complétez ici votre patrimoine à titre individuel.' : 'Complétez ici votre patrimoine commun.' }</Typography>
            </Box>

            <Box sx={{marginY:2}}>
              <FormControlLabel control={<BpCheckbox size="small" checked={isChecked} onChange={(e)=>setIsChecked(e.target.checked)} />} label={<Typography sx={{fontWeight:'400'}}>J'ai compris, ne plus afficher ce message.</Typography>} />
            </Box>

            <Box>
              <Button onClick={handleClose} variant="contained">Fermer</Button>
            </Box>



        </Box>
    </Dialog>
)};


export default ModalInfoParcoursCouple;
