import { Typography } from '@mui/material';
import React from 'react';
import AppLayout from '../AppLayout/AppLayout';
import {ButtonCopyDebugInfos, DebugInfosDisplayTable } from '../debugInfos/debugInfos';

const DebugPage = () => {

  
  
return (
  <AppLayout>
    <Typography variant='h1'>Debug Page</Typography>
    <ButtonCopyDebugInfos />


    <DebugInfosDisplayTable />


  </AppLayout>
)};

export default DebugPage;
