import React, { useState } from 'react';
import './LogoutButton.scss';
import { LoadingButton } from '@mui/lab';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../services/userContext';

const LogoutButton = () => {
  const navigate = useNavigate()
  const [loadingLogOut,setLoadingLogOut] = useState(false)

  const {logOut} = useUser()

  const handleLogOutButton = async () => {
    setLoadingLogOut(true)
    logOut()
    navigate('/')
  }


  
  
  return (
    <LoadingButton 
    loading={loadingLogOut}
    variant="contained" 
    onClick={()=>{handleLogOutButton()}} endIcon={<LogoutIcon />}>
      Se déconnecter
    </LoadingButton>
)};


export default LogoutButton;
