import React from "react";
import { Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const AlertInfoCustom = ({ children }) => {
  return (
    <Box
      sx={{
        marginY: 2,
        backgroundColor: "#BCDFFF",
        border: "1px solid #94B7D7",
        borderRadius: "12px",
        padding: [2, 2, 3],
        display: "flex",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Box>
        <InfoIcon fontSize="small" style={{ color: "#115479" }} />
      </Box>

      <Box sx={{ color: "#115479" }}>{children}</Box>
    </Box>
  );
};

export default AlertInfoCustom;
