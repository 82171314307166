const customWidth = 
[
  {
    type:'textarea',
    width:'12'
  },
  {
    type:'checkbox',
    width:'12'
  },
  {
    type:'field_set',
    width:'12'
  },
  {
    type:'radio',
    width:'12'
  },
  {
    type:'address_number',
    width:'2'
  },
  {
    type:'zipcode',
    width:'2'
  },
  {
    type:'address_street',
    width:'9'
  },
  {
    type:'city',
    width:'5'
  },
  {
    type:'country',
    width:'5'
  },
  {
    type:'child_birth_date[]',
    width:'12'
  },
  {
    type:'file',
    width:'12',
    parent:'fieldSet'
  },
  {
    type:'money',
    width:'6',
  },
  {
    type:'money',
    width:'12',
    parent:'formulaireLayout'
  },
  {
    type:'download',
    width:'12'
  },
  {
    type:'download',
    width:'12'
  },
  {
    type:'form_link',
    width:'12'
  },
  {
    type:'file',
    width:'6',
    parent:'fieldSet',
    slug:'actifs-financiers',
  },
  {
    type:'file',
    width:'12',
    parent:'subFieldSetCard',
    slug:'biens-immobiliers',
  },
  {
    type:'money',
    width:'12',
    parent:'subFieldSetCard',
    slug:'biens-immobiliers',
  },
  {
    type:'total',
    width:'12',
    parent:'subFieldSetCard',
    slug:'biens-immobiliers',
  },
  {
    type:'text',
    width:'12',
    parent:'subFieldSetCard',
    slug:'biens-immobiliers',
  },
  {
    type:'file',
    width:'12'
  },
  {
    type:'info',
    width:'12'
  },
  {
    type:'total',
    width:'12'
  },
  {
    type:'total',
    width:'6',
    parent:'subFieldSetCard'
  },
  {
    name:'brands[%index%]',
    width:'12'
  },
  {
    name:'assets_common',
    width:'12'
  },
  {
    name:'real_estate_common',
    width:'12'
  },
  {
    name:'other_documents[%index%][file]',
    width:'6'
  },
  {
    name:'provision_composition',
    width:'12'
  },
  {
    name:'wanted_provision',
    width:'12'
  },
  {
    name:'group_discovered',
    width:'12'
  },
  {
    name:'real_estate_properties[%index%][type]',
    width:'12'
  },
  {
    name:'other_incomes_common',
    width:'12'
  }

]

const getSizeField = (field,parent,form) =>{
    const slug = form.slug
    const widthWithParentAndSlug = customWidth.find(element => (element.type === field.type && element.parent === parent && element.slug === slug))
    const widthWithParent = customWidth.find(element => (element.type === field.type || element.type === field.name) && (element.parent === parent) && !element.slug)
    const withWithoutParent = customWidth.find(element => (element.type === field.type || element.type === field.name) && !element.slug)
    const widthWithName = customWidth.find(element => (element.name === field.name))
    let width = []
  
    if(widthWithParentAndSlug){
      width = widthWithParentAndSlug
    }else if(!widthWithParentAndSlug && widthWithParent){
      width = widthWithParent
    }else if(widthWithName){
      width = widthWithName
    }else{
      width = withWithoutParent
    }
  
    let widthFinal  = 6
  
    if(field.repeatable){
      widthFinal = 12
    }else if(width){
      widthFinal =  width?.width
    }else{
      widthFinal = 6
    }
  
    widthFinal = parseInt(widthFinal)
    //widthFinal = 12
    return widthFinal
}

export default getSizeField;