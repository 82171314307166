import React, { useEffect,useState } from 'react';
import './ModalMaxWeightOfSize.scss';
import { Dialog, Box, Typography, Button } from '@mui/material';
import HeaderUpload from '../HeaderUpload/HeaderUpload';
import { findByKey } from '../../services/utils';

const ModalMaxWeightOfSize = (props) => {
  const {open,onClose,form} = props

  const [headerUpload,setHeaderUpload] = useState(null)

  const handleClose = () => {
    onClose();
  }


  useEffect(()=>{
    if(form){
      const object = findByKey(form,'header_upload')
      if(object){
        setHeaderUpload(object)
      }
    }
  },[form])


  return (
  <Dialog maxWidth="md" open={open} fullWidth onClose={handleClose}>
    <Box sx={{backgroundColor:'white',borderRadius:1,paddingY:[2,2,10],paddingBottom:[2,2,6],paddingX:[2,2,8],textAlign:'center'}}>
        <Box>
          <Typography variant='h1'>Attention,<br /> le poids total des pièces justificatives chargées a atteint la limite maximale !</Typography>
        </Box>
        <Box sx={{marginY:4}}>
          <HeaderUpload isModal headerUpload={headerUpload} />
        </Box>
        <Box> 
          <Button variant="contained" onClick={handleClose}>Fermer</Button>
        </Box>
    </Box>
  </Dialog>
)};


export default ModalMaxWeightOfSize;
