import { Badge, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../Logo/Logo';
import './Sidebar.scss';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutButton from '../LogoutButton/LogoutButton';
import navItems from '../NavItems/NavItems';
import DebugBar from '../../services/debugBar';
import { getCurrentFormGroup } from '../../services/localStorage';
import { useUser } from '../../services/userContext';
import { useNotifications } from '../../services/notificationsContext';
import IdleTimer from '../IdleTimer/IdleTimer';


const Sidebar = () => {

  const location = useLocation()
  const navigate = useNavigate()
  const [menuMobileOpen,setMenuMobileOpen] = useState(false)
  const [itemActive,setItemActive] =  useState(false)

  const {notifications} = useUser()
  const {pushNotifications} = useNotifications()




  useEffect(()=>{

    const findItemActive = () => {
      const currentSlugInNavItems = navItems.find((item)=>item.link === location.pathname)
      const currentFormInNavItems = navItems.find((item)=>item.form_group_id === getCurrentFormGroup())

      if(currentSlugInNavItems){
        setItemActive(currentSlugInNavItems)
      }else if(currentFormInNavItems && location.pathname.includes('formulaire')){
        setItemActive(currentFormInNavItems)
      }else{
        setItemActive(false)
      }
    }
    findItemActive()
  },[location])


  const handleClickLink = (event,link) => {
    event.preventDefault()
    const isFormulaire = location.pathname.includes('formulaire')
    navigate(link)

    if(isFormulaire){
      pushNotifications('Enregistrez votre saisie avant de quitter le formulaire','warning')
    }
    
  }

  const countNotifications = notifications ? notifications?.filter(notif=>notif.is_read === false).length : 0 


  //findItemActive()

  return (
    <Box className={menuMobileOpen ? 'open sidebar' : 'sidebar'} >
      <IdleTimer />
      <Box  sx={{borderRight:'1px solid #EDEDED',
      boxSizing:'border-box',
      height:[menuMobileOpen ? '100%' : 'fit-content',menuMobileOpen ? '100%' : 'fit-content','100%','100%'],
      backgroundColor:'white',
      paddingX:3,
      paddingY:2,
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',
      overflowY:'scroll',
      boxShadow:['0px 6px 10px -3px rgba(0,0,0,0.1)','0px 6px 10px -3px rgba(0,0,0,0.1)','none'],
      zIndex:100}}>
        <Box>
          <Box sx={{display:'flex',alignItems:'center', justifyContent:['space-between','space-between','center']}}>
            <Box sx={{display:['block','block','none']}}>
                {!menuMobileOpen && <IconButton onClick={()=>{setMenuMobileOpen(true)}}><MenuIcon /></IconButton> }
                {menuMobileOpen && <IconButton onClick={()=>{setMenuMobileOpen(false)}}><CloseIcon /></IconButton> }
              </Box>
            <Box sx={{width:['150px','150px','220px']}}>
              <Logo />
            </Box>
          </Box>
          <Typography variant="h3" sx={{fontWeight:600,marginY:[0,0,2]}}>Mon espace recrutement</Typography>

            <Box sx={{display:[menuMobileOpen ? 'block' : 'none',menuMobileOpen ? 'block' : 'none','block']}}>
              <List sx={{marginTop:5}}>
              {navItems.map((item)=>(

                <ListItem key={item.slug} sx={{paddingLeft:0, marginY:[1.5,1.5,0.5]}}>
                  <ListItemText>
                    <Link onClick={(e)=>{handleClickLink(e,item.link)}} to={item.link} 
                    style={{
                    textDecoration:'none',
                    }}>
                      <Badge badgeContent={item?.slug === 'alertes' ? countNotifications : 0} color="primary">
                          <Typography variant="navLink" 
                          sx={{paddingRight:1,
                            fontWeight:item?.slug === itemActive?.slug ? '600' : '400',
                            color:item?.slug === itemActive?.slug ? '#ED5052' : '#0A0A0A',
                            '&:hover':{
                              fontWeight:600
                            }
                            
                            }}
                          > {item.title}</Typography>
                      </Badge>
                    </Link>
                  </ListItemText>
                </ListItem>
              ))}

              </List>
            </Box>
          </Box>
          
          <Box sx={{display:[menuMobileOpen ? 'block' : 'none',menuMobileOpen ? 'block' : 'none','flex'],justifyContent:'center',flexDirection:'column',paddingY:5}}>
            {process.env.REACT_APP_ENV === 'dev'  && <DebugBar />}
            <LogoutButton />
          </Box>
      </Box>
    </Box>
)};
export default Sidebar;
