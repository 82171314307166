import React, {useState} from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SubFieldSetCard from '../SubFieldSetCard/SubFieldSetCard';
import { useForm } from '../../../services/formContext';
import { useUser } from '../../../services/userContext';


const RepeatableSubFieldSet = (props) => {
  const {field} = props
  const [row,setRow] = useState([])
  const [isInit,setIsInit] = useState(false)

  const {isDebug} = useUser()

  const {nameForm,saveValue,reloadField,getRepetableLength,deleteRowInMultiplesValues} = useForm()

  const addRow = (number) =>{
    for(let i = 0; i < (number || 1);i++){
      setRow(arr => [...arr,field])
    }
    saveValue(field.title,row.length+1,nameForm,'count','count')
  }

  const handleDeleteRow = (idRow) => {
    console.log('DELETE ROW')
    const newRows = row.filter((r,index)=>index !== idRow)
    setRow(newRows)
    deleteRowInMultiplesValues(idRow,nameForm,field.title,row.length-1)
    reloadField(field.title)
  }

  /* Ajout des ligne a l'initialisation de la page */
  if(!isInit){
    const numberRow = getRepetableLength(field)

    //console.log('Init Repetable Field Set',numberRow)
    //console.log('getRepetableLength',getRepetableLength(field))

    for(let i = 0; i < (numberRow || 1);i++){
      setRow(row => [...row,field])
    }
    setIsInit(true)
  }



 
  const handleAddRow = () => {
    addRow(1)
  }

  return(
   <Box>
      {isDebug && 'Repeatable SubFieldSet - ' + (field.name || field.title)}
      {isDebug && <br />}
      {isDebug && 'Number row : ' + row.length}
     {row.map((fieldInRow,index)=>(
      <SubFieldSetCard key={index} field={fieldInRow} deleteRow={()=>{handleDeleteRow(index)}} index={index}/>
     ))}
       

     <Box sx={{display:'flex',justifyContent:'center',marginY:3}}>
       <Button onClick={()=>{handleAddRow()
         }}variant="outlined" color="primary" startIcon={<AddIcon />}>{field.repeat_label}</Button>
     </Box>
   </Box>
)};

export default RepeatableSubFieldSet;
