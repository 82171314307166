
import {getTokenDataInLocalStorage} from "./localStorage";
import mime from 'mime'

export const replaceIndex = (text, index) => {
  const newText = copyObject(text)
  return newText.replace(/%index%/, index ? index : "");
};

export const replaceNumByIndex = (text) => {
  if(text){
    let newText = ''
    for(let i = 0; i < text.length;i++){
      const isNumber = isNaN(parseInt(text[i])) ? false : true
      newText += isNumber ? '%index%' : text[i]
    }
    return newText
  }else{
    return text
  }
}

export const getIndexInName = (text) => {
  if(text){
    for(let i = 0; i < text.length;i++){
      const isNumber = isNaN(parseInt(text[i])) ? false : true 
      if(isNumber){
        return Number(text[i])
      }
    }
  }
  return null
}

export const hasIndexSelector = (text) => {
  if(text){
    return text.includes("%index%") ? true : false;
  }else{
    return false
  }
  
};


export const getNameInResponse = (text) => {
  if(text){
    text = convertNotationBracketToDot(text)
    text = text.split('.')
    return Array.isArray(text) ? text[0] : text
  }
}

export function copyToClipboard(text) {
  const cb = navigator.clipboard;
  cb.writeText(text).then();
}



export function isJson(data) {
  try {
    const testIfJson = JSON.parse(data);
    if (typeof testIfJson === "object") {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

export function copyObject(object) {
  return object ? JSON.parse(JSON.stringify(object)) : null ;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const flattenObject = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

export const download = (fileUrl, fileName) => {
  var a = document.createElement("a");
  a.href = fileUrl;
  a.setAttribute("download", fileName);
  a.setAttribute("target", "_blank");
  a.click();
};

export const countAlertes = (notifications) => {
  return notifications
    ? notifications?.filter((notif) => notif.is_read === false).length
    : 0;
};

export const getDepartmentsByRegions = (regionsSelected) => {
  let departmentsFiltered = [];
  const regionsUDM = getRegionsUDM()
  const objectRegionsSelected = regionsSelected.map((id) => regionsUDM.find(r => r.idRegion === parseInt(id) ))
  objectRegionsSelected.forEach((region)=>{
    region.departments.forEach((departement)=>{
      departmentsFiltered.push(departement)
    })
  })
  
  departmentsFiltered.sort();
  return departmentsFiltered;
};

export const getRegionsUDM = () => {

  return [
    {
      idRegion: 1,
      nameRegion: "Est",
      departments: [
        "08-Ardennes",
        "51-Marne",
        "10-Aube",
        "52-Haute-Marne",
        "55-Meuse",
        "54-Meurthe-et-Moselle",
        "57-Moselle",
        "88-Vosges",
        "70-Haute-Saône",
        "90-Territoire-de-Belfort",
        "67-Bas-Rhin",
        "68-Haut-Rhin",
      ],
    },
    {
      idRegion: 2,
      nameRegion: "Nord",
      departments: [
        "80-Somme",
        "59-Nord",
        "60-Oise",
        "62-Pas-de-Calais",
        "02-Aisne",
      ],
    },
    {
      idRegion: 9,
      nameRegion: "Paris",
      departments: [
        "76-Seine-Maritime",
        "75-Paris",
        "77-Seine-et-Marne",
        "78-Yvelines",
        "27-Eure",
        "28-Eure-et-Loir",
        "45-Loiret",
        "89-Yonne",
        "91-Essonne",
        "92-Hauts-de-Seine",
        "93-Seine-Saint-Denis",
        "94-Val-de-Marne",
        "95-Val-D'Oise",
      ],
    },
    {
      idRegion: 3,
      nameRegion: "Ouest",
      departments: [
        "14-Calvados",
        "29-Finistère",
        "22-Côtes d'Armor",
        "35-Ille-et-Vilaine",
        "44-Loire-Atlantique",
        "49-Maine-et-Loire",
        "50-Manche",
        "53-Mayenne",
        "56-Morbihan",
        "61-Orne",
        "72-Sarthe",
      ],
    },
    {
      idRegion: 21,
      nameRegion: "Centre-Ouest",
      departments: [
        "85-Vendée",
        "17-Charente-Maritime",
        "79-Deux-Sèvres",
        "16-Charente",
        "24-Dordogne",
        "19-Corrèze",
        "86-Vienne",
        "87-Haute-Vienne",
        "36-Indre",
        "37-Indre-et-Loire",
        "23-Creuse",
        "41-Loir-et-Cher",
        "18-Cher",
      ],
    },
    {
      idRegion: 5,
      nameRegion: "Sud-Ouest",
      departments: [
        "33-Gironde",
        "40-Landes",
        "64-Pyrénées-Atlantiques",
        "46-Lot",
        "47-Lot-et-Garonne",
        "81-Tarn",
        "82-Tarn-et-Garonne",
        "12-Aveyron",
        "15-Cantal",
        "65-Hautes-Pyrénées",
        "09-Ariège",
        "31-Haute-Garonne",
        "32-Gers",
      ],
    },
    {
      idRegion: 6,
      nameRegion: "Sud-Est",
      departments: [
        "66-Pyrénées-Orientales",
        "04-Alpes-de-Haute-Provence",
        "05-Hautes-Alpes",
        "06-Alpes-Maritimes",
        "07-Ardèche",
        "11-Aude",
        "13-Bouches-du-Rhône",
        "26-Drôme",
        "34-Hérault",
        "83-Var",
        "84-Vaucluse",
        "30-Gard",
        "48-Lozère",
      ],
    },
    {
      idRegion: 4,
      nameRegion: "Centre-Est",
      departments: [
        "01-Ain",
        "03-Allier",
        "21-Côte-d'Or",
        "25-Doubs",
        "38-Isère",
        "39-Jura",
        "42-Loire",
        "43-Haute-Loire",
        "58-Nièvre",
        "63-Puy-de-Dôme",
        "69-Rhône",
        "71-Saône-et-Loire",
        "73-Savoie",
        "74-Haute-Savoie",
      ],
    },
  ];
}


export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const memorySizeOf = (obj) => {
  var bytes = 0;

  function sizeOf(obj) {
      if(obj !== null && obj !== undefined) {
          // eslint-disable-next-line default-case
          switch(typeof obj) {
          case 'number':
              bytes += 8;
              break;
          case 'string':
              bytes += obj.length * 2;
              break;
          case 'boolean':
              bytes += 4;
              break;
          case 'object':
              var objClass = Object.prototype.toString.call(obj).slice(8, -1);
              if(objClass === 'Object' || objClass === 'Array') {
                  for(var key in obj) {
                      if(!obj.hasOwnProperty(key)) continue;
                      sizeOf(obj[key]);
                  }
              } else bytes += obj.toString().length * 2;
              break;
          }
      }
      return bytes;
  };

  /*function formatByteSize(bytes) {
      if(bytes < 1024) return bytes + " bytes";
      else if(bytes < 1048576) return(bytes / 1024).toFixed(3) + " KiB";
      else if(bytes < 1073741824) return(bytes / 1048576).toFixed(3) + " MiB";
      else return(bytes / 1073741824).toFixed(3) + " GiB";
  };*/

  function fileConvertSize(aSize){
    aSize = Math.abs(parseInt(aSize, 10));
    var def = [[1, 'octets'], [1024, 'ko'], [1024*1024, 'Mo'], [1024*1024*1024, 'Go'], [1024*1024*1024*1024, 'To']];
    for(var i=0; i<def.length; i++){
      if(aSize<def[i][0]) return (aSize/def[i-1][0]).toFixed(2)+' '+def[i-1][1];
    }
  } 
  return fileConvertSize(sizeOf(obj));
  
};

export const byteSize = str => new Blob([str]).size;


export const debugFichierTemporaires = (filesInTemp) => {
  console.group('Debug des fichiers temporaires')
    if(filesInTemp.length>0){
      console.log('Poid des fichiers temporaires :',memorySizeOf(filesInTemp))
      console.table(filesInTemp)
    }else{
      console.log('Pas de fichiers temporaires pour le moment')
    }
    console.groupEnd()
}



/**
 * Determine whether the given `input` is iterable.
 *
 * @returns {Boolean}
 */
export function isIterable(input) {  
  if (input === null || input === undefined) {
    return false
  }

  return typeof input[Symbol.iterator] === 'function'
}


export const tokenIsRevoked = () => {
  return !tokenIsNotRevoked()
}

export const tokenIsNotRevoked = () => {
  const {token,saveDate} = getTokenDataInLocalStorage()
  return token && ((Date.now() - saveDate) < 3600000)
}


export const findPath = (obj, name, val, currentPath) => {
  currentPath = currentPath || ''

  let matchingPath

  if (!obj || typeof obj !== 'object') return

  if (obj[name] === val) return `${currentPath}.${name}`

  for (const key of Object.keys(obj)) {
    if (key === name && obj[key] === val) {
      matchingPath = currentPath
    } else {
      matchingPath = findPath(obj[key], name, val, `${currentPath}.${key}`)
    }
    if (matchingPath) break
  }
  return matchingPath
}

export const getParentPath = (path) => {
  if(path){
    let new_path = path.split('.')
    new_path = new_path.slice(1,-3)
    new_path = new_path.join('.')
    return new_path
  }
  return path
}

export const getObjectPath = (path) => {
  if(path){
    let new_path = path.split('.')
    new_path = new_path.slice(1,-1)
    new_path = new_path.join('.')
    return new_path
  }
  return path
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const convertNotationBracketToDot = (string) => string?.replace(/]/g, "").replace(/\[/g, ".")

export const convertNotationDotToHook = (string) => {
  if(string && string.includes('.')){
    const array = string.split('.');
    let convertString = ''
    for(let i = 0; i < array.length; i++){   
      if(i === 0){
        convertString += array[i]+"["
      }else if(i === array.length-1){
        convertString +=  array[i]+']'
      }else{
        convertString += array[i]+']['
      }
    }
    return convertString
  }else{
    return string
  }
}

export const convertInputTypesAcceptedToList = (types) => {
  let array = []
  types?.forEach((type)=>{
    const mimeType = mime.getType(type)
    array[mimeType] = array.hasOwnProperty(mimeType) ? [...array[mimeType],'.'+type] : ['.'+type]
  })
  return { ...array };
}

export const isCurrentWindow = () => {
  console.log(window.top,window.self)
  
  return window.top === window.self
}


export const copyFormatted = (html) => {
  // Create container for the HTML
  // [1]
  var container = document.createElement('div')
  container.innerHTML = html

  // Hide element
  // [2]
  container.style.position = 'fixed'
  container.style.pointerEvents = 'none'
  container.style.opacity = 0

  // Detect all style sheets of the page
  var activeSheets = Array.prototype.slice.call(document.styleSheets)
    .filter(function (sheet) {
      return !sheet.disabled
    })

  // Mount the container to the DOM to make `contentWindow` available
  // [3]
  document.body.appendChild(container)

  // Copy to clipboard
  // [4]
  window.getSelection().removeAllRanges()

  var range = document.createRange()
  range.selectNode(container)
  window.getSelection().addRange(range)

  // [5.1]
  document.execCommand('copy')

  // [5.2]
  for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true

  // [5.3]
  document.execCommand('copy')

  // [5.4]
  for (let i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false

  // Remove the container
  // [6]
  document.body.removeChild(container)
}

export const getBrowserName = (userAgent) => {
  // The order matters here, and this may report false positives for unlisted browsers.

  if (userAgent.includes("Firefox")) {
    // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
    return "Mozilla Firefox";
  } else if (userAgent.includes("SamsungBrowser")) {
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
    return "Samsung Internet";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
    return "Opera";
  } else if (userAgent.includes("Trident")) {
    // "Mozilla/4.0 (compatible; MSIE 7.0; Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; .NET CLR 2.0.50727; .NET CLR 3.0.30729; .NET CLR 3.5.30729)"
    return "Microsoft Internet Explorer";
  } else if (userAgent.includes("Edge")) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    return "Microsoft Edge (Legacy)";
  } else if (userAgent.includes("Edg")) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
    return "Microsoft Edge (Chromium)";
  } else if (userAgent.includes("Chrome")) {
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
    return "Google Chrome or Chromium";
  } else if (userAgent.includes("Safari")) {
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
    return "Apple Safari";
  } else {
    return "unknown";
  }
}

export const findByKey = (obj, kee) => {
  if (obj.hasOwnProperty(kee)){
    return obj[kee]
  }else{
    for(let n of Object.values(obj).filter(Boolean).filter(v => typeof v === 'object')) {
        let found = findByKey(n, kee)
        if (found) return found
    }

  }
}

// Return value after traitement, if 0 we need 0 but if is null we need an empty chain
export const transformValue = (value) => {
  if(value === null){
    return ''
  }else if(value === 0){
    return '0'
  }else{
    return value
  }
}

export const getFileExtension = (filename) => (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined
