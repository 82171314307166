import { Box } from '@mui/material';
import React, {useEffect} from 'react';
import BackgroundFullSize from '../BackgroundFullSize/BackgroundFullSize';
import HeaderConnexion from '../HeaderConnexion/HeaderConnexion';
import './ConnexionLayout.scss';
import backgroundHero from '../../assets/img/background-hero.jpeg'
import { useUser } from '../../services/userContext';
import { useNavigate } from 'react-router-dom';

const ConnexionLayout = (props) => {

  const {isConnect} = useUser()
  const navigate = useNavigate()
  useEffect(()=>{
    if(isConnect){
      navigate('/dashboard')
    }
    // eslint-disable-next-line
  },[])


  return(
    <Box className='connexion-layout'>
      <HeaderConnexion {...props}/>
      <BackgroundFullSize src={backgroundHero} alt={"Site de tréville"} />
      <Box sx={{position:'relative',
      width:'100%',
      minHeight:'100vh',
      paddingTop:'140px',
      paddingX:1,
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      zIndex:1
      }}>
        
        <Box>
          {props.children}
        </Box>
      </Box>
    </Box>
)};


export default ConnexionLayout;
