import React,{useEffect, useState} from 'react';
import AppLayout from '../../../components/AppLayout/AppLayout';

import { Typography } from '@mui/material';
import { markAsReadNotifications } from '../../../services/api';
import { Box } from '@mui/system';
import { useTheme } from '@emotion/react';
import DeleteIcon from '@mui/icons-material/Delete';
import Alerte from '../../../components/Alerte/Alerte';
import { countAlertes } from '../../../services/utils';
import { useUser } from '../../../services/userContext';
import { LoadingButton } from '@mui/lab';
import { useNotifications } from '../../../services/notificationsContext';

const Alertes = () => {
  const theme = useTheme()
  const {notifications,reloadNotifications} = useUser()
  const {pushNotifications} = useNotifications()

  const [isLoading,setIsLoading] = useState(false)

  const handleClickMarkAllAsRead = async () => {
    setIsLoading(true)

    for (const notif of notifications){
      const response = await markAsReadNotifications(notif.id)
      pushNotifications(response.message,'success')
    }

    await reloadNotifications(true)
    setIsLoading(false)
  }

  useEffect(()=>{
    console.log('Update Notifications',notifications)
  },[notifications])

  return(
    <AppLayout>
      <Box>
        <Typography sx={{marginBottom:1,fontWeight:600}} variant="h2">Mes alertes</Typography>
        <Typography sx={{marginY:3}} variant="body1">Ici, vous retrouverez vos alertes qui vous guideront tout au long de votre parcours de recrutement.</Typography>
      </Box>
      <Box>
        <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <Box>
            <Typography sx={{marginBottom:1,fontWeight:600,color:theme.palette.primary.main}} >Vous avez {notifications ? countAlertes(notifications).toString() : '0'} nouvelle(s) alerte(s)</Typography>
          </Box>
          <Box>
            <LoadingButton loading={isLoading} startIcon={<DeleteIcon />} variant="outlined" onClick={handleClickMarkAllAsRead}>Tout marquer comme lu</LoadingButton>
          </Box>
        </Box>

        <Box>

          {notifications && notifications?.map((alerte,key)=>(
            <Alerte key={key} alerte={alerte}/>
          ))}
          
          
        </Box>

      </Box>


    </AppLayout>
)};

export default Alertes;
