const localStorageKeys = ['EspaceRecrutement/debugBarStatus','EspaceRecrutement/validateRGPD','EspaceRecrutement/token','EspaceRecrutement/saveDate','EspaceRecrutement/candidate']

export const toEmptyLocalStorage = () => {
    localStorageKeys.forEach((key)=>{
        localStorage.removeItem(key)
    })
}

export const toEmptyCandidateData = () => {
    ['EspaceRecrutement/token',
    'EspaceRecrutement/saveDate',
    'EspaceRecrutement/candidate',
    'EspaceRecrutement/candidacy',
    'EspaceRecrutement/currentForm',
    'EspaceRecrutement/currentFormGroup',
    'EspaceRecrutement/formValues',
    'EspaceRecrutement/debugBarStatus',
    'EspaceRecrutement/modalParcoursCoupleIsViewed',
    'EspaceRecrutement/currentFormId',
    ].forEach((key)=>{
        localStorage.removeItem(key)
    })
}

// Stocker par adresses mail pour les différent utilsateurs.
export const setValidateRGPD = (val) => {
    localStorage.setItem('EspaceRecrutement/validateRGPD',val)
}

export const saveConsentRGPD = (email,validate) => {
    const consentRGPD = getConsentRGPD() || []
    if(!hasConsentRGPD(email)){
        const tab = [...consentRGPD,{email,validate}]
        localStorage.setItem('EspaceRecrutement/consentRGPD',JSON.stringify(tab))
    }
}

export const getConsentRGPD = () => {
    return JSON.parse(localStorage.getItem('EspaceRecrutement/consentRGPD'))
}

export const hasConsentRGPD = (email) => {
    const consentRGPD = getConsentRGPD() || []
    const hasConsent = consentRGPD.find(user => user.email === email && user.validate === true ) ? true : false
    return hasConsent
}


export const setTokenDataInLocalStorage = (token) => {
    localStorage.setItem('EspaceRecrutement/token',token)
    localStorage.setItem('EspaceRecrutement/saveDate',Date.now())
}


export const getTokenDataInLocalStorage = () => {
    return {token:localStorage.getItem('EspaceRecrutement/token'),saveDate:localStorage.getItem('EspaceRecrutement/saveDate')}
}
  
export const setCandidateInLocalStorage = (candidate) => localStorage.setItem('EspaceRecrutement/candidate',JSON.stringify(candidate))


export const setCandidacyInLocalStorage = (candidacy) =>  localStorage.setItem('EspaceRecrutement/candidacy',JSON.stringify(candidacy))
export const getCandidacyInLocalStorage = () => localStorage.getItem('EspaceRecrutement/candidacy') ? JSON.parse(localStorage.getItem('EspaceRecrutement/candidacy')) : false

export const getSelfCandidate = () => {

   

    const saveDate = Number(localStorage.getItem('EspaceRecrutement/saveDate'))
    const now = Date.now()
    const tokenIsValid = now - saveDate < (3600 * 1000)
    
    if(tokenIsValid){
        let candidate = localStorage.getItem('EspaceRecrutement/candidate') ? JSON.parse(localStorage.getItem('EspaceRecrutement/candidate')) : false
        if(candidate){
            candidate.saveDate = localStorage.getItem('EspaceRecrutement/saveDate') ? localStorage.getItem('EspaceRecrutement/saveDate') : false ;
            candidate.token = localStorage.getItem('EspaceRecrutement/token') ? localStorage.getItem('EspaceRecrutement/token') : false;
        }
        return candidate;

    }else{
        return null
    }
   
}


export const setFormInStorage = (form,name) => {
    localStorage.setItem(name,JSON.stringify(form))
}

export const getFormInStorage = (name_form) => {
    return localStorage.getItem(name_form) ? JSON.parse(localStorage.getItem(name_form)) : false 
}


export const setCurrentForm = (form) => {
    localStorage.setItem('EspaceRecrutement/currentForm',JSON.stringify(form))
}

export const getCurrentForm = (form) => {
    return localStorage.getItem('EspaceRecrutement/currentForm') ? JSON.parse(localStorage.getItem('EspaceRecrutement/currentForm')) : false 
}

export const setCurrentFormGroup = (form) => {
    if(form){
        localStorage.setItem('EspaceRecrutement/currentFormGroup',JSON.stringify(form))
    }
}

export const getCurrentFormGroup = () => {
    return localStorage.getItem('EspaceRecrutement/currentFormGroup') ? JSON.parse(localStorage.getItem('EspaceRecrutement/currentFormGroup')) : false 
}

export const parcoursCoupleIsViewed = () =>{
    return localStorage.getItem('EspaceRecrutement/modalParcoursCoupleIsViewed') ? JSON.parse(localStorage.getItem('EspaceRecrutement/modalParcoursCoupleIsViewed')) : false
}

export const setParcoursCoupleIsViewed = () => {
    localStorage.setItem('EspaceRecrutement/modalParcoursCoupleIsViewed',JSON.stringify(true))
}

export const getModalInfoParcoursCoupleIsViewed = (target = 'commun') => {
    const infos = JSON.parse(localStorage.getItem('EspaceRecrutement/modalInfoParcoursCoupleIsViewed')) || {}
    return  infos[target] || false
}

export const setModalInfoParcoursCoupleIsViewed = (target = 'commun') => {
    const infos = JSON.parse(localStorage.getItem('EspaceRecrutement/modalInfoParcoursCoupleIsViewed')) || {}
    infos[target] = true
    console.log(infos)
    localStorage.setItem('EspaceRecrutement/modalInfoParcoursCoupleIsViewed',JSON.stringify(infos))
} 

export const saveValuesInStorage = (values) => {
    localStorage.setItem('EspaceRecrutement/formValues',JSON.stringify(values))
}

export const getValuesInStorage = () => {
    return JSON.parse(localStorage.getItem(`EspaceRecrutement/formValues`))
}

export const emptyValuesInStorage = () => {
    return localStorage.setItem(`EspaceRecrutement/formValues`,JSON.stringify([]))
}

export const setCurrentFormId = (id) => {
    localStorage.setItem('EspaceRecrutement/currentFormId',JSON.stringify(id))
}

export const getCurrentFormId = () => {
    return localStorage.getItem(`EspaceRecrutement/currentFormId`) ?parseInt(JSON.parse(localStorage.getItem(`EspaceRecrutement/currentFormId`))) : false
}

export const setDebugBarStatus = (status) => localStorage.setItem('EspaceRecrutement/debugBarStatus',JSON.stringify(status))
export const getDebugBarStatus = () => JSON.parse(localStorage.getItem('EspaceRecrutement/debugBarStatus'))