import { LoadingButton } from '@mui/lab';
import {  Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/AppLayout/AppLayout';
import { getFormGroup } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';
import { Link, useNavigate } from 'react-router-dom';
import ModalFormCompleted from '../../../components/ModalFormCompleted/ModalFormCompleted';
import ModalSendDossier from '../../../components/ModalSendDossier/ModalSendDossier';
import ModalSendingDossier from '../../../components/ModalSendingDossier/ModalSendingDossier';
import AlertInfoCustom from '../../../components/AlertInfoCustom/AlertInfoCustom';
import MailUDM from '../../../components/MailUDM/MailUDM';
import StepDossier from '../../../components/StepDossier/StepDossier';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useUser } from '../../../services/userContext';
import AlerteWarningCustom from '../../../components/AlerteWarningCustom/AlerteWarningCustom';
import ModalDossierForbiden from '../../../components/ModalDossierForbiden/ModalDossierForbiden';
import ModalDossierValidateAndForbiden from '../../../components/ModalDossierValidateAndForbiden/ModalDossierValidateAndForbiden';
import { setCurrentFormGroup,setCurrentFormId } from '../../../services/localStorage';
import HTMLComponent from '../../../components/HTMLComponent/HTMLComponent';



const Dossier = (props) => {

  const {name} = props
  const {notifications,getTypeParcours} = useUser()
  const navigate = useNavigate();
  
  const [formGroup,setFormGroup] = useState(null)
  const [modalFormCompleted,setModalFormCompleted] = useState(false)
  const [modalSendDossier,setModalSendDossier] = useState(false)
  const [modalSendingDossier,setModalSendingDossier] = useState(false)

  const [isLoading,setIsLoading] = useState(false)


  const initFormGroup = async (name) =>{
      setIsLoading(true)
      const formGroup = await getFormGroup(name);
      setFormGroup(formGroup)
      setIsLoading(false)
      setCurrentFormGroup(formGroup.id)

      
      if(formGroup?.has_pending_exports){
        setModalSendingDossier(true)
      }
  }

  const goToForm = (form,target,index) => {
    let path = target.target_id ? '/formulaire/'+form.slug+'/'+target.target_id : '/formulaire/'+form.slug
    setCurrentFormId(index)
    if(!target.is_completed){
      navigate(path)
    }else{
      setModalFormCompleted({form:form,target:target,path:path})
    }
  }

  const handleClickSendDossier = () => (
    setModalSendDossier(true)
  )
  
  useEffect(()=>{
      setFormGroup(false)
      initFormGroup(name)
  },[name])

  const getNotificationsInDossier = (dossier_id) => {
    const notificationsFilter = notifications ? notifications.filter((notif)=> notif.form_group_id === dossier_id && notif.is_form_error === true) : []
    return notificationsFilter
  }

  const getBorderColorTarget = (target,form) => {
    const isCompleted = target.is_completed
    const isWarning = notifications && notifications.find(notif => notif.candidate_id === target.target_id && notif.form_slug === form.slug) ? true : false

    let color
    if(isWarning){
      color = '#EEC313'
    }else if(isCompleted){
      color =  '#67B870'
    }else{
      color = '#EDEDED'
    }

    return color

  }

  const handleDossierSend = (response) => {
    setModalSendDossier(false)
    console.log('handleDossierSend',response)
    setModalSendingDossier(true)

  }

  // Gérér la modal SendingDossier 
  
  return (
  <AppLayout isLoading={isLoading}>

      {formGroup && 
      <>
      {modalFormCompleted && <ModalFormCompleted form={modalFormCompleted} setModalFormCompleted={setModalFormCompleted}/>}
      {modalSendDossier && <ModalSendDossier formGroup={formGroup} modalSendDossier={modalSendDossier} setModalSendDossier={setModalSendDossier} handleDossierSend={handleDossierSend} />}
      
      {modalSendingDossier && <ModalSendingDossier open={modalSendingDossier} />}

      {formGroup.is_completed && formGroup.are_files_validated && <ModalDossierValidateAndForbiden open={formGroup.is_completed && formGroup.are_files_validated} formGroup={formGroup} />}
      {!formGroup.is_unlocked &&  <ModalDossierForbiden open={!formGroup.is_unlocked} formGroup={formGroup} />}

      <Typography variant="h2" sx={{marginBottom:2}}>{formGroup.name}</Typography>
      <Typography variant="bodyBlack" sx={{marginY:3}}>{formGroup.description}</Typography>
      <Box>

        <Box sx={{marginY:3}}>
          <StepDossier forms={formGroup.forms} />
        </Box>
        
        {getTypeParcours() === 'Couple' &&
        <Box sx={{marginY:2}}>
          <AlertInfoCustom>
              <Typography sx={{fontWeight:500,color:'#115479'}}>Ci-dessous, les formulaires à compléter par chaque candidat. Attention, certains formulaires doivent être complétés en commun.</Typography>
          </AlertInfoCustom>
        </Box>
       }


        <Box sx={{marginY:2}}>
          {getNotificationsInDossier(formGroup.id).map((notification,key)=>(
            <React.Fragment key={key}>
              <AlerteWarningCustom >
                  <Typography sx={{fontWeight:500,color:'#855C00'}}>{notification.title}</Typography>
                  <Typography sx={{color:'#855C00'}}><HTMLComponent>{notification?.content}</HTMLComponent></Typography>
              </AlerteWarningCustom>
            </React.Fragment>
          ))}
        </Box>

        <Box sx={{marginTop:6}}>
          {formGroup?.forms?.map((form,index)=>(
                <Box key={index} sx={{marginBottom:6}}>
                  <Typography variant="h4">{index+1}. {form.name}</Typography>
                  <Typography variant="body1" sx={{fontSize:'14px',marginBottom:1}}>{form.description}</Typography>

                  <Grid container  spacing={2}>
                    {form.targets.map((target,key)=>(
                          <Grid key={key} item xs={12} md={target.target_id && form.targets.length >= 2 ? 6  : 12} >
                            <Box onClick={()=>{goToForm(form,target,index+1)}} sx={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between',background:'white',paddingX:4,paddingY:3,borderRadius:'12px',border:'2px solid',borderColor:getBorderColorTarget(target,form)}}>
                              <Box sx={{display:'flex',gap:1,alignItems:'center'}}>
                                <Typography sx={{fontSize:'16px',fontWeight:'500',color:'#0A0A0A',maxWidth:['160px','inherit','inherit']}}>{target.title}</Typography>
                                {target.is_completed && 
                                <Box sx={{color:'#67B870',display:'flex',alignItems:'center'}}>
                                  <CheckCircleIcon fontSize="small"/>
                                </Box>
                                }
                              </Box>
                              <Box><EditIcon sx={{width:'18px',height:'18px'}}/></Box>
                            </Box>
                          </Grid>
                    ))}
                  </Grid>
                </Box>
          ))}
        </Box>
        <Box sx={{marginY:2,display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center', paddingY:4}}>
          <Typography variant="body1" sx={{textAlign:'center'}}>Pour que votre dossier soit envoyé au service recrutement, l'ensemble des formulaires doivent être complétés.</Typography>
          <LoadingButton sx={{marginTop:2}} onClick={handleClickSendDossier} disabled={!formGroup.is_completed || formGroup.are_files_validated} variant="contained">Envoyer mon dossier</LoadingButton>
        </Box>
        <Box sx={{background:'white',border:'1px solid',borderColor:'#EDEDED',paddingX:3,paddingY:4,marginY:2,borderRadius:'12px'}}>
            <Box sx={{display:'flex',alignItems:'center',gap:1,marginBottom:'12px'}}>
              <Box >
                  <HelpIcon fontSize="small" sx={{color:'#424242'}}/>
              </Box>
              <Box>
                <Typography variant='h4' sx={{color:'#424242'}}>Vous avez une question ?</Typography>
              </Box>
            </Box>
            <Box>
            N'hésitez pas à contacter notre équipe : <MailUDM />
            Nous répondrons à toutes vos questions dans les plus brefs délais.<br />
            Pour connaître le contact de votre région, <Link to='/contact' style={{color:'black',textDecorationColor:'black'}}><Typography sx={{textDecorationColor:'black',color:'black',display:'inline',fontWeight:'700',cursor:'pointer'}}>rendez-vous ici.</Typography></Link>
            </Box>
        </Box>
      </Box>
      </>}
  </AppLayout>
)};

export default Dossier;
