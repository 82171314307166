import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import React from 'react';
import ConnexionLayout from '../../components/ConnexionLayout/ConnexionLayout';


const Page404Disconnect = () => {

  const theme = useTheme()
  
  return (
  <ConnexionLayout>
    <Box sx={{zIndex:2,width:'800px',maxWidth:'100vw',padding:7,borderRadius:[0,0,theme.shape.borderRadius],backgroundColor:'white'}}>
      <Typography variant="h1" sx={{fontSize:'80px',textAlign:'center'}} color={theme.palette.primary.main}>Erreur 404</Typography>
      <Typography  sx={{fontSize:'20px',textAlign:'center'}} >Rien a voir ici !</Typography>
    </Box>
  </ConnexionLayout>
)};



export default Page404Disconnect;
