import React,{useState,useEffect} from 'react';
import HeaderConnexion from '../../../components/HeaderConnexion/HeaderConnexion';
import backgroundHero from '../../../assets/img/background-hero.jpeg'
import { Box } from '@mui/system';
import BackgroundFullSize from '../../../components/BackgroundFullSize/BackgroundFullSize';
import {Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TextFieldCustom from '../../../components/TextFieldCustom/TextFieldCustom';
import { forgotPassword } from '../../../services/api';
import useKeyPress from '../../../services/useKeyPress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';



const PremiereConnexion = (props) => {
  const theme = useTheme();
  const [mail,setMail] = useState()
  const [isSending,setIsSending] = useState(false)
  const [isSended,setIsSended] = useState(false)
  const [responseMessage,setResponseMessage] = useState(false)
  const [error,setError] = useState(false)
 
  const {title,textButton,type} = props

  const navigate = useNavigate()

  const handleRecoveryPassword = async () => {
    setIsSending(true)
    const response = await forgotPassword(mail)
    console.log(response)
    if(response.error){
      setError(response.message)
    }else{
      setResponseMessage(response.message)
      setIsSended(true)
      setError(false)
    }
    setIsSending(false)

  }

  const enterPress = useKeyPress('Enter')
  useEffect(() => {
    if(enterPress && !isSending){
      handleRecoveryPassword()
    }
    // eslint-disable-next-line
  }, [enterPress,isSending])


  const handleReturn = () => {
    navigate('/')
  }

  

  return (
    <div className="Connexion">
      <HeaderConnexion {...props}/>
        <BackgroundFullSize src={backgroundHero} alt={"Site de Tréville"} />
        <Box sx={{position:'relative', minHeight:'100vh',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',zIndex:2}}>
          <Box sx={{zIndex:2,width:'800px',maxWidth:'90vw',borderRadius:theme.shape.borderRadius,backgroundColor:theme.palette.primary.main}}>
            <Box sx={{backgroundColor:'#EDEDED',borderRadius:theme.shape.borderRadius}}>
              <Box onClick={handleReturn} sx={{paddingX:2,paddingY:2,display:'flex',alignItems:'center',gap:1,cursor:'pointer'}}>
                <ArrowBackIcon fontSize='small' />
                <Typography>Revenir à la connexion</Typography>
              </Box>
              <Box sx={{paddingX:'100px',paddingY:'40px',backgroundColor:theme.palette.background.default,borderRadius:theme.shape.borderRadius}}>
                  <Box>
                      <Box sx={{textAlign:'center',marginBottom:'20px'}}>
                        <Typography variant="h1" sx={{marginBottom:'10px'}}>
                          {title}
                        </Typography>
                      </Box>
                      {!isSended &&
                      <>
                      <Box sx={{textAlign:'center',marginBottom:'20px'}}>
                        {type === 'premiere-connexion' &&
                          <Typography>
                          Bienvenue dans votre espace recrutement Mousquetaires.<br />
                          Veuillez définir votre mot de passe pour vous connecter.
                          </Typography>
                        } 
                        {type === 'mot-de-passe-oublie' &&
                          <Typography>
                          Veuillez compléter votre adresse mail.<br />
                          Un mail vous sera envoyé afin de réinitaliser votre mot de passe.
                          </Typography>
                        } 
                      </Box>

                    <Box sx={{width:'100%',marginY:2}}>
                      <TextFieldCustom label="Adresse mail" type='email' value={mail} onChange={(e)=>{setMail(e.target.value)}} />
                    </Box>

                    

                    {error &&
                    <Box sx={{textAlign:'center',marginY:2}}>
                      <Typography sx={{color:'#ED5052',fontWeight:500}}>{error}</Typography>
                    </Box>
                    }


                    <Box sx={{textAlign:'center',marginBottom:'20px'}}>
                      <LoadingButton
                      loading={isSending}
                      onClick={handleRecoveryPassword}
                      variant="contained">
                      {textButton}
                      </LoadingButton>
                    </Box>
                    </>
                    }

                    {isSended && responseMessage &&
                    <Box sx={{textAlign:'center',marginY:3}}>
                      <Typography>{responseMessage}</Typography>
                      <Typography>Cliquez sur le lien disponible pour définir un nouveau mot de passe</Typography>
                    </Box>
                    }
                    
                  </Box>
              </Box>
            </Box>
          </Box>
        </Box>
    </div>
    )
};

export default PremiereConnexion;
