import React from 'react';
import './FieldEmail.scss';
import TextFieldCustom from '../../TextFieldCustom/TextFieldCustom';


const FieldEmail = (props) => {
  const {field,onChange} = props

  const handleChange = (value) => {
    onChange(field.name,value)
  }

  return (
    <TextFieldCustom
    readOnly={field.read_only ? true : false}
    onChange={(e) => handleChange(e.target.value)} 
    inputProps={{ inputMode: 'email', pattern: field.pattern }}  
    name={field.name} 
    label={field.label} 
    value={field.value}
    optional={field.optional}
    isRequired={field.isRequired}
    fullWidth/>
  )
};

export default FieldEmail;

