import React from 'react';
import './FieldSet.scss';
import { Box} from '@mui/system';
import { Grid, Typography } from '@mui/material';
import Field from '../Field/Field';
import RepeatableFieldSet from '../RepeatableFieldSet/RepeatableFieldSet';
import { useForm } from '../../../services/formContext';

import { isIterable} from '../../../services/utils';
import HeaderUpload from '../../HeaderUpload/HeaderUpload';
import getSizeField from '../../../services/getSizeField';

const FieldSet = (props) => {
  const {field} = props
  const {form,isHiddenField,isHiddenFieldSet} = useForm();

  //console.log(field)

  return(
  <>
  {!isHiddenFieldSet(field) &&
  <Box sx={{marginY:2}}>
    
      <Typography sx={{marginY:2}} variant="h4">{field.title}</Typography>
      {field.description && 
        <Typography sx={{marginY:2}}>{field.description}</Typography>
      }
    
    <Grid container spacing={2}>
      
      {field.header_upload && <HeaderUpload field={field} />}

      {!field.repeatable && field.fields && isIterable(field.fields) && field.fields.map((field,key)=> !isHiddenField(field) && (
        
        <Grid key={key} item xs={12} md={getSizeField(field,'fieldSet',form)} >
            <Field field={field} parent={'fieldSet'} key={key} />
        </Grid>
      ))}

      {field.repeatable && !isHiddenFieldSet(field) && 
        <Grid item xs={12}>
           <RepeatableFieldSet field={field} />
        </Grid>
      }     
      
    </Grid>
  </Box>
  }
  </>
)};


export default FieldSet;
