import React from 'react';
import './PolitiqueDonnees.scss';
import AppLayout from '../../components/AppLayout/AppLayout';
import { Typography,Box, TableContainer, Paper, Table,TableHead,TableRow,TableCell, TableBody } from '@mui/material';

const PolitiqueDonnees = () => {
  
  return (
    <AppLayout>
    <Box className="PolitiqueDonnees">

    <Typography variant="h1">Politique de traitement des données personnelles et cookies - Candidats</Typography>

    <Typography variant="h3">Préambule</Typography>
                  
    <Typography>La présente Politique a pour objet d’informer les Candidats des Traitements de Données Personnelles mis en œuvre conformément à la règlementation applicable à la protection des données et en particulier le Règlement Général sur la Protection des Données n°2016/679 et la&nbsp;loi « Informatique et Libertés » du 6 janvier 1978 dans sa dernière version.&nbsp;</Typography>

    <Typography variant="h3">Politique données personelles</Typography>  

    <Box component="ol">
      <Typography component="li">Définitions</Typography>
    </Box>

    <Typography>Pour l’application de la présente Politique doi(ven)t être considéré(s) comme :</Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Termes</TableCell>
            <TableCell>Définitions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Candidat</TableCell>
            <TableCell>Tout futur chef d’entreprise souhaitant devenir adhérent du Groupement Les Mousquetaires dont le dossier de candidature est à l’étude par le Responsable de Traitement</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Consentement</TableCell>
            <TableCell>Toute manifestation de volonté, libre, spécifique, éclairée et univoque par laquelle la personne concernée accepte, par une déclaration ou par un acte positif clair, que des Données Personnelles la concernant fassent l'objet d'un Traitement</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Destinataire </TableCell>
            <TableCell>Toute personne physique ou morale, l'autorité publique, le service ou tout autre organisme qui reçoit communication de Données Personnelles, qu'il s'agisse ou non d'un tiers </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Données Personnelles</TableCell>
            <TableCell>Toute information se rapportant à une personne identifiée ou identifiable, c’est-à-dire qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant tel qu’un nom, un numéro d’identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Groupement Les Mousquetaires</TableCell>
            <TableCell>ITM Entreprises (RCS Paris 722 064 102) ainsi que l’ensemble des sociétés qu’elle contrôle directement ou indirectement et toute autre société détenue directement ou indirectement à plus de 10% par ITM Entreprises, ainsi que toute société exploitant à titre d’enseigne une marque d’ITM Entreprises et toutes les associations, fondations, ou organismes à but non lucratif dont les activités sont consacrées au Groupement Les Mousquetaires ou à ses actions.</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Responsable de Traitement</TableCell>
            <TableCell>La Société qui, seule ou conjointement avec d'autres, détermine les finalités et les moyens du Traitement de données à caractère personnel</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Traitement</TableCell>
            <TableCell>Toute(s) opération(s) ou tout ensemble d’opérations réalisée(s) sur les Données Personnelles, quel que soit le procédé utilisé, et notamment la collecte, l’enregistrement, la conservation, l’adaptation ou la modification, l’extraction, la consultation, l’utilisation, la communication par transmission, diffusion ou toute autre forme de mise à disposition, le rapprochement ou l’interconnexion, ainsi que le verrouillage, l’effacement ou la destruction </TableCell>
          </TableRow>
        </TableBody>
      </Table> 
    </TableContainer>

    <Box component="ol" start="2">
      <Typography component="li">Qui est responsable le Responsable de Traitement</Typography>
    </Box>
    
    <Typography>Le Responsable de Traitement est&nbsp;:</Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ENTITE AGISSANT EN QUALITE DE RESPONSABLE DE TRAITEMENT</TableCell>
            <TableCell>ADRESSE DU SIEGE SOCIAL DE L’ENTITE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Union Des Mousquetaires (UDM)RCS : 327 656 005 </TableCell>
            <TableCell>PARC DE TREVILLE 2 ALLÉE DES MOUSQUETAIRES91078 BONDOUFLE Cedex</TableCell>
          </TableRow>
        </TableBody>
      </Table> 
    </TableContainer>

    <Typography>L’UDM met en œuvre des Traitements portant sur les Données Personnelles des Candidats dans les conditions décrites ci-dessous.</Typography>
    <Typography>A ce titre, elle intervient en qualité de Responsable de Traitement dans la mise en œuvre de traitement des données personnelles</Typography>

    <Box component="ol" start="3">
      <Typography component="li">Quelles sont les données personnelles traitées ?</Typography>
    </Box>

    <Typography>Les Traitements de Données Personnelles portent sur les catégories de données suivantes :</Typography>
    
    <Box component="ul">
      <Typography component="li">des données permettant votre identification (civilité, nom, prénom, date de naissance et lieu de naissance, nationalité, adresse postale, adresse électronique, numéro de téléphone, photo, documents d’identité)</Typography>
      <Typography component="li">des données relatives à votre situation personnelle et familiale (situation de famille, nombre d’enfants et dates de naissance)</Typography>
      <Typography component="li">des données relatives à votre situation professionnelle (diplômes, formations, parcours professionnel, CV, catégorie socio-professionnelle)</Typography>
      <Typography component="li">des données relatives à votre situation bancaire et patrimoniale (apports, revenus mobiliers et immobiliers, patrimoine immobilier).</Typography>
    </Box>

    <Typography>Les Données Personnelles sont obtenues directement auprès de vous.</Typography>
    <Typography>Dans l’hypothèse où vos Données Personnelles sont collectées par le biais d’un formulaire, vous serez informé(e)s du caractère obligatoire des informations par un astérisque (*) ou toute autre indication à proximité des champs concernés</Typography>
    <Typography>Faute de réponse de votre part, le traitement ne pourra pas être pris en compte et votre demande ne pourra pas aboutir. Les informations relatives aux Traitements de Données Personnelles qui résultent de la collecte via ce formulaire seront rappelées lorsque celui-ci est complété</Typography>
    <Typography>Seules les Données Personnelles qui sont strictement nécessaires au regard des finalités décrites sont collectées.</Typography>


    <Box component="ol" start="4">
      <Typography component="li">Bases légales des Traitements mis en œuvre et durées de conservation des données</Typography>
    </Box>

    <Typography>Les traitements de vos données personnelles reposent sur différentes bases légales selon les finalités poursuivies détaillées ci-après. Ainsi les dites bases légales sur lesquelles les traitements peuvent être mis en œuvre sont les suivantes</Typography>

    <Box component="ul">
      <Typography component="li">le <strong>consentement</strong>&nbsp;: la personne concernée donne son consentement libre, spécifique, éclairé, et univoque au traitement de ses données personnelles pour une ou plusieurs des finalités décrites ci-dessous</Typography>
      <Typography component="li"><strong>l’exécution de mesures précontractuelles ou d’un contrat</strong> : la personne concernée est partie au contrat ou est à l’origine des mesures précontractuelles</Typography>
      <Typography component="li">le respect d’une ou plusieurs <strong>obligations légales</strong> qui incombent au responsable de traitement</Typography>
      <Typography component="li">la réalisation de<strong> l’intérêt légitime du responsable de traitement</strong>&nbsp;: les traitements des données personnelles de la personne concernée sont réalisés par exemple à des fins de lutte contre les fraudes etc.&nbsp;</Typography>
    </Box>

    <Typography>Par ailleurs, vos données personnelles ne sont conservées que pour la durée strictement nécessaire à l’accomplissement des finalités pour lesquelles elles sont traitées décrites ci-dessous. Cette durée varie en fonction de la finalité du Traitement.</Typography>

    <Typography>Cette durée peut être augmentée de la durée nécessaire à l’exercice ou la défense de l’UDM dans ses droits en justice</Typography>
           
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Finalités</TableCell>
            <TableCell>Bases légales</TableCell>
            <TableCell>Durées de conservation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Communication et échanges d’information en vue de répondre aux demandes de contact (formulaire de candidature et prospect) :Réception de la candidature et rappel </TableCell>
            <TableCell>Consentement</TableCell>
            <TableCell>Les données sont anonymisées à la clôture du dossier ou au retrait du consentement</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Communication directe pour des actualités concernant le Groupement Les Mousquetaires et le parcours de recrutement des prospects et candidats</TableCell>
            <TableCell>Consentement</TableCell>
            <TableCell>Les données sont anonymisées à la clôture du dossier ou au retrait du consentement</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Gestion de la candidatureValidation de la candidatureCréation et gestion du compte « candidat »Gestion de la candidature et des étapes Suivi de la relation avec le Candidat </TableCell>
            <TableCell>Mesures précontractuelles</TableCell>
            <TableCell>Clôture classique (arrêt parcours) : 2 ans à compter de la date de clôture du dossier <br /> Clôture en Région : 2 ans à compter de la date de clôture du dossier <br/> Clôture pour refus d’agrément : Suppression des justificatifs à la date de la clôture du dossier Conservation du dossier de candidature pour une durée illimitée (archivage) </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Elaboration de statistiques </TableCell>
            <TableCell>Intérêt légitime</TableCell>
            <TableCell>Sans limitation de durée, les Données étant anonymisées</TableCell>
          </TableRow>
          
          <TableRow>
            <TableCell>Gestion des demandes d’exercice des droits</TableCell>
            <TableCell>Obligations légales</TableCell>
            <TableCell>1 an pour les droits d’accès, de rectification, d’effacement et droit à la limitation du Traitement6 ans pour l’exercice du droit d’opposition au Traitement pour toute autre finalité</TableCell>
          </TableRow>

        </TableBody>
      </Table> 
    </TableContainer>


    <Box component="ol" start="5">
      <Typography component="li">Quels sont les destinataires de vos données</Typography>
    </Box>

    <Typography>Vos données personnelles peuvent être communiquées, selon leurs attributions et en fonction des finalités poursuivies, aux destinataires exposés ci-dessous</Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Catégorie des destinataires des données</TableCell>
            <TableCell>Destinataires des données</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          <TableRow>
            <TableCell>Destinataires internes au Groupement Les Mousquetaires</TableCell>
            <TableCell>Les personnels chargés des services internes de l’Union des Mousquetaires La STIMELes filiales du Groupement Les Mousquetaires (ITM MOBILITE, ITM ALIMENTAIRE INTERNATIONAL et SAS EQUIPEMENT DE LA MAISON)</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Destinataires externes au Groupement Les Mousquetaires </TableCell>
            <TableCell>Les sous-traitants en charge de la mise en œuvre des traitements de données personnelles ou dans le cadre des missions de conseil et d’assistance, agissant strictement sur les instructions de l’UDM</TableCell>
          </TableRow>

        </TableBody>
      </Table> 
    </TableContainer>

    <Box component="ol" start="6">
      <Typography component="li">Quels sont vos droits concernant vos données personnelles ?</Typography>
    </Box>

    <Typography>Vous disposez des droits suivants sur vos données personnelles :</Typography>
    
    <Box component="ul">
      <Typography component="li"><strong>Droit d’accès</strong>&nbsp;: vous pouvez à tout moment obtenir une copie de vos données personnelles ainsi que des informations, sur la nature, l’origine et l’usage de celles-ci, l’identité ou les catégories des éventuels destinataires de celles-ci&nbsp;;&nbsp;</Typography>
      <Typography component="li"><strong>Droit de rectification</strong>&nbsp;: vous pouvez demander à ce que des données vous concernant qui seraient inexactes ou incomplètes soient modifiées ou complétées&nbsp;;&nbsp;</Typography>
      <Typography component="li"><strong>Droit à l’effacement</strong>&nbsp;: vous pouvez demander l’effacement de vos données personnelles, notamment si celles-ci ne sont plus nécessaires aux traitements effectués, sauf si des obligations légales incombent au responsable de traitement de conserver ces données ou qu’elles sont nécessaires à la constatation, à l'exercice ou à la défense de droits en justice&nbsp;;&nbsp;</Typography>
      <Typography component="li"><strong>Droit à la limitation d’un ou plusieurs traitements de vos données personnelles</strong>&nbsp;: vous pouvez demander à ce que vos données personnelles soient rendues temporairement inaccessibles afin de limiter leur traitement futur dans les situations suivantes&nbsp;:&nbsp;</Typography>
      <Typography component="li">lorsque vous contestez l'exactitude de vos données personnelles, pendant une durée permettant au responsable du traitement de vérifier l'exactitude de celles-ci ; </Typography>
      <Typography component="li">lorsque vous estimez que le traitement est illicite et que vous vous opposez à l’effacement de vos données personnelles ; </Typography>
      <Typography component="li">lorsque vos données personnelles n’ont plus de raison d’être conservées mais que vous souhaitez qu’elles le soient pour l’exercice ou la défense de vos droits en justice ; </Typography>
      <Typography component="li">lorsque vous vous êtes opposé(e) au traitement, pendant la vérification portant sur le point de savoir si les motifs légitimes poursuivis par le responsable du traitement prévalent sur les vôtres. </Typography>
      <Typography component="li"><strong>Droit à la portabilité</strong>&nbsp;: vous pouvez demander à recevoir la communication des données personnelles vous concernant que vous aurez fournis au responsable de traitement et traitées par des moyens automatisés, dans un format structuré et couramment utilisé. Ce droit ne peut être exercé que si le traitement est fondé sur votre consentement ou sur l’exécution d’un contrat ou de mesures précontractuelles.</Typography>
      <Typography component="li"><strong>Droit d’opposition</strong>&nbsp;: vous pouvez vous opposer à tout moment, pour des raisons tenant à votre situation particulière, à un traitement de vos données personnelles fondé sur les intérêts légitimes du responsable de traitement sauf s’il existe des motifs légitimes et impérieux pour le traitement qui prévalent sur vos intérêts, droits et libertés, ou pour la constatation, l'exercice ou la défense de droits en justice.&nbsp;</Typography>
    </Box>


    <Typography>Vous disposez&nbsp;à cet égard d’un droit d’opposition à ce que vos données soient traitées à des fins de prospection commerciale, y compris lorsque ce traitement prend la forme d’un profilage&nbsp;;&nbsp;</Typography>


    <Box component="ul">
      <Typography component="li"><strong>Droit de définir des directives en cas de décès</strong>&nbsp;: vous pouvez donner des consignes relatives à la conservation, à l'effacement et à la communication de vos données personnelles. En cas de décès, vos données personnelles seront par principe supprimées, sauf si des obligations légales et règlementaires et/ des délais légaux de prescription imposent de les conserver&nbsp;;&nbsp;</Typography>
      <Typography component="li"><strong>Droit de retrait du consentement</strong>&nbsp;: vous pouvez retirer à tout moment&nbsp;votre consentement concernant le traitement de vos données personnelles reposant uniquement sur celui-ci.</Typography>
    </Box>

    <Box component="ol" start="7">
      <Typography component="li">Comment exercer vos droits&nbsp;?</Typography>
    </Box>
    
    <Typography>Le Candidat dispose d’un droit d’accès, de rectification, d’effacement des Données Personnelles qui le concernent, d’un droit à la limitation du Traitement, d’un droit d’opposition, d’un droit à la portabilité de ses Données Personnelles et du droit de définir des directives relatives à la conservation, à l'effacement et à la communication de ses Données Personnelles après son décès.&nbsp;</Typography>
    <Typography>Le Candidat a également le droit de retirer son Consentement lorsque le Traitement des Données Personnelles est basé sur le Consentement.</Typography>
    <Typography>L’exercice d’un de ces droits peut être refusé au Candidat si sa demande ne remplit pas les conditions posées par la réglementation. Dans cette hypothèse, le Candidat en sera dûment informé.</Typography>
    <Typography>Le Candidat peut exercer ces droits par courriel à l’adresse udm_contact@mousquetaires.com par courrier adressé à&nbsp;: 2 allée des Mousquetaires 91078 BONDOUFLE Cedex</Typography>
    <Typography>Un justificatif d’identité pourra lui être demandé. Le Groupement Les Mousquetaires a désigné un Délégué à la protection des données.&nbsp;</Typography>

    <Box component="ol" start="8">
      <Typography component="li">Comment introduire une réclamation auprès de la CNIL</Typography>
    </Box>

    <Typography>S’il estime que le Traitement de ses Données Personnelles n’est pas conforme aux dispositions légales et règlementaires applicables, le Candidat peut également saisir la CNIL à l’adresse suivante&nbsp;: 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX.</Typography>

    <Typography variant="h2">Politique des cookies</Typography>

    <Box component="ol" start="1">
      <Typography component="li">Qu’est-ce qu’un cookie ?</Typography>
    </Box>

    <Typography>Un cookie est un traceur déposé et lu lors de la consultation de sites internet, de la lecture d’un courrier électronique, de l’installation ou de l’utilisation d’un logiciel, dans l’équipement de votre terminal.</Typography>

    <Box component="ol" start="2">
      <Typography component="li">Quels sont les cookies utilisés ?</Typography>
    </Box>

    <Typography>​Les cookies déposés via le Site peuvent être de plusieurs natures et adresser plusieurs finalités</Typography>


    <Box component="ul">
      <Typography component="li"><strong>Nécessaire au fonctionnement du site</strong> :&nbsp;Ces traceurs permettent de mémoriser vos choix et vos préférences sur votre compte, de fournir des fonctionnalités facilitant l'utilisation du Site ou encore d'en améliorer la performance et la sécurité. Ils sont indispensables au bon fonctionnement du Site.​</Typography>
      <Typography component="li"><strong>Stocker et/ou accéder à des informations sur un terminal</strong> :&nbsp;Les cookies, identifiants de votre terminal ou autres informations peuvent être stockés ou consultés sur votre terminal pour les finalités qui vous sont présentées.​</Typography>
      <Typography component="li"><strong>Développer et améliorer les produits</strong> :&nbsp;Vos données peuvent être utilisées pour améliorer les systèmes et logiciels existants et pour développer de nouveaux produits.​</Typography>
      <Typography component="li"><strong>Mesurer la performance du contenu</strong> :&nbsp;La performance et l’efficacité du contenu que vous voyez ou avec lequel vous interagissez peuvent être mesurées​</Typography>
      <Typography component="li"><strong>Sélectionner du contenu personnalisé</strong> :&nbsp;Du contenu personnalisé peut vous être présenté sur la base de votre profil utilisateur.​</Typography>
      <Typography component="li"><strong>Analyser activement les caractéristiques du terminal pour l’identification</strong> :&nbsp;Votre terminal peut être identifié sur la base d’une analyse de la combinaison unique de caractéristiques de votre terminal.​</Typography>
    </Box>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Cookies</TableCell>
            <TableCell>Fonctionnalités</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          <TableRow>
            <TableCell>Mesure d’audience</TableCell>
            <TableCell>Ces cookies de mesure d’audience permettent de générer des statistiques de fréquentation utiles à l’amélioration du site</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Google Analytics (GA4)</TableCell>
            <TableCell>Ces cookies permettent d'analyser le comportement des internautes qui visitent votre site et de mesurer le retour de vos actions web marketing</TableCell>
          </TableRow>

        </TableBody>
      </Table> 
    </TableContainer>

    <Box component="ol" start="3">
      <Typography component="li">Combien de temps sont conservés les cookies ?</Typography>
    </Box>

    <Typography>Conformément à la réglementation en vigueur, la durée de validité de votre consentement pour un cookie est de treize (13) mois maximum après le premier dépôt du cookie sur votre terminal.</Typography>

    <Box component="ol" start="4">
      <Typography component="li">Comment paramétrer les cookies ?</Typography>
    </Box>

    <Typography>Vous pouvez paramétrer à tout moment vos cookies et revenir sur vos choix à l’aide du gestionnaire de cookies présent sur les sites internet ou à partir du bandeau d’information lorsque vous arrivez sur le Site pour la première fois. </Typography>
    <Typography>Le paramétrage de votre logiciel de navigation permet également de vous informer de la présence de cookie HTTP et de les refuser. Les liens ci-dessous vous aideront à trouver les paramètres pour certains navigateurs courants :</Typography>

    <Box component="ul" start="4">
      <Typography component="li">Gérer les paramètres des cookies dans&nbsp;<a href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</a>,&nbsp;<a href="https://support.google.com/chrome/answer/95647?hl=en&amp;co=GENIE.Platform%3DAndroid&amp;oco=1">Chrome Android</a> et&nbsp;<a href="https://support.google.com/chrome/answer/95647?hl=en&amp;co=GENIE.Platform%3DiOS&amp;oco=1">Chrome iOS</a>.</Typography>
      <Typography component="li">Gérer les paramètres des cookies dans&nbsp;<a href="https://support.mozilla.org/t5/Protect-your-privacy/Enable-and-disable-cookies-that-websites-use-to-track-your/ta-p/2784">Firefox</a>.</Typography>
      <Typography component="li">Gérer les paramètres des cookies dans&nbsp;<a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></Typography>
      <Typography component="li">Gérer les paramètres des cookies dans <a href="https://www.forum-entraide-informatique.com/supprimer-les-cookies-sur-microsoft-edge/">Internet Edge</a></Typography>
      <Typography component="li">Gérer les paramètres des cookies dans&nbsp;<a href="http://www.opera.com/help/tutorials/security/privacy">Opera</a></Typography>
    </Box>

    <Typography>Par ailleurs, si vous souhaitez désactiver ou supprimer un cookie flash, vous pouvez consulter les <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html">Paramètres généraux de stockage Adobe Flash Player&nbsp;</a></Typography>

      </Box>
    </AppLayout>
)};


export default PolitiqueDonnees;
