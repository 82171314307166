import React from 'react';
import { Dialog,Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const ModalFormulaireIsLocked = (props) => {
  const {open} = props
  const navigate = useNavigate()
  const handleClickButton = () => {
    navigate('/dashboard')
  }
  return (
    <Dialog maxWidth="md" open={open} fullWidth>
    <Box sx={{backgroundColor:'white',borderRadius:1,paddingY:[2,2,10],paddingBottom:[2,2,6],paddingX:[2,2,8],textAlign:'center'}}>
        <Box>
          <Typography variant='h1'>Ce formulaire n’est pas accessible à la saisie</Typography>
        </Box>
        <Box sx={{marginTop: 1,marginBottom: 2}}>
          <Typography variant="body1">Votre avancée dans le parcours ne vous permet pas encore de saisir ce dossier. Veuillez finaliser le dossier en cours pour débloquer les prochains formulaires.</Typography>
        </Box>
        <Box> 
          <Button variant="contained" onClick={handleClickButton}>Retourner au tableau de bord</Button>
        </Box>
    </Box>
</Dialog>
)};


export default ModalFormulaireIsLocked;
