import React, { useEffect, useState } from 'react';
import './IdleTimer.scss';
import { Box, Typography } from '@mui/material';
import { useUser } from '../../services/userContext';
import { getTokenDataInLocalStorage } from '../../services/localStorage';

const IdleTimer = () => {

  const {refreshToken,logOut,isDebug} = useUser()

  const tokenSaveDate = getTokenDataInLocalStorage().saveDate*1
  const [currentTime,setCurrentTime] = useState(Date.now())


  useEffect(()=>{
    setInterval(()=>{
      setCurrentTime(Date.now())
    },5000)
  },[])

  useEffect(()=>{

    const handeIntervalUser = async () => {
      const {saveDate} = getTokenDataInLocalStorage()
      const tokenIsValid = currentTime - saveDate < 3600000

      //console.log(currentTime - saveDate)

      if(!tokenIsValid){
          const refreshedTokenIsOk = await refreshToken()

          if(!refreshedTokenIsOk){
            logOut()
          }
      }
    }
    handeIntervalUser()
    // eslint-disable-next-line
  },[currentTime])


  if(isDebug){
    return (
      <Box>
        <Box>
          <Typography> Heure de l'enregistrement du Token : {new Date(tokenSaveDate).toUTCString()} </Typography>
        </Box>

        <Box>
          <Typography> Heure actuelle : {new Date(currentTime).toUTCString()} </Typography>
        </Box>
        
      </Box>
    )
  }
  
};

export default IdleTimer;
