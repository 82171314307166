import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { useUser } from "../services/userContext";
import { ButtonCopyDebugInfos, DebugInfosDisplayBasicInfos} from "../components/debugInfos/debugInfos";


const DebugBar = () => {
    const {isDebug,toggleDebug} = useUser()

    return(
        <Box sx={{marginY:2}}>
            <Box>
                <FormGroup>
                    <FormControlLabel 
                    label="Debug"
                    control={<Switch color="secondary" checked={isDebug} onChange={(e)=>{toggleDebug()}}/>
                    } />
                </FormGroup>
            </Box>
            {isDebug &&<>
            <Box>
                <DebugInfosDisplayBasicInfos />
            </Box>
            <Box sx={{paddingY:2,display:'flex',gap:1,flexDirection:'column'}}>
                
                <Button size="small" target="_blank" href="/debug-storage" variant="contained">Debug Storage</Button>
                <Button size="small" target="_blank" href="/debug-page" variant="contained">Debug Page</Button>
                <ButtonCopyDebugInfos size="small"  />

            </Box>
            </>
            }
            


        </Box>
    )
}


export default DebugBar;
