import React, { useState } from 'react';
import './ModalSendDossier.scss';
import { Dialog, Typography, Box} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { sendFormGroup } from '../../services/api';

const ModalSendDossier = (props) => {
  
  const {modalSendDossier,setModalSendDossier,formGroup,handleDossierSend} = props

  const [isLoading,setIsLoading] = useState(false)
  const [response,setResponse] = useState()

  const handleValidate = async () => {
    setIsLoading(true)
    const response = await sendFormGroup(formGroup?.slug)
    setResponse(response)
    setIsLoading(false)
    handleDossierSend(response)
  }
  
  return (
    <Dialog open={modalSendDossier}  maxWidth="md" onClose={()=>{setModalSendDossier(false)}} >
      <Box sx={{backgroundColor:'#EDEDED'}}>
        <Box sx={{padding:2}}>
          <Box onClick={()=>{setModalSendDossier(false)}}sx={{display:'flex',alignItems:'center',gap:1,cursor:'pointer'}}>
            <ArrowBackIcon  fontSize="small"/>
            <Typography variant="body1">Revenir à l’étape précédente</Typography>
          </Box>
        </Box>
        <Box sx={{backgroundColor:'white',paddingX:10,paddingY:8,borderRadius:2}}>
          <Box sx={{textAlign:'center',marginBottom:1}}>
            <Typography variant="h2">Êtes-vous sûr de vouloir envoyer votre dossier ?</Typography>
          </Box>
          
        <Box sx={{textAlign:'center',marginBottom:3}}>
            <Typography variant="body1">
              Vous allez soumettre votre dossier pour vérification. <br />
              Une fois validé, il ne pourra plus être modifié. <br />
              L'envoi de ce dossier est obligatoire pour accéder à la prochaine étape de votre parcours Mousquetaires.<br />
            </Typography>
          </Box>

          
          <Box sx={{textAlign:'center',marginBottom:1}} >
            <Typography>{response?.message}</Typography>
            
          </Box>
          <Box sx={{marginBottom:2}}>
            <Typography sx={{textAlign:'center'}}>Vos données personnelles font l’objet de traitements par l’Union des Mousquetaires à des fins de gestion de votre candidature. Ce traitement est fondé sur les mesures précontractuelles. Pour en savoir plus sur vos droits, <Typography component="a" href="/politique-donnees">cliquez ici</Typography></Typography>
          </Box>
          <Box sx={{textAlign:'center'}}>
            <LoadingButton loading={isLoading} onClick={handleValidate} variant="contained">Valider</LoadingButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
)};


export default ModalSendDossier;
