import React, { useEffect, useState } from 'react';
import './FieldTotal.scss';
import { Box, Typography } from '@mui/material';
import { useForm } from '../../services/formContext';
import TextFieldCustom from '../TextFieldCustom/TextFieldCustom';
import { useUser } from '../../services/userContext';

const FieldTotal = (props) => {

  const {onChange,parent,field} = props
  const {getTotal,fieldCountable,reloadField} = useForm()


  const {isDebug} = useUser()

  const [total,setTotal] = useState(0)


  useEffect(()=>{

    let newTotal = getTotal(field)
    if(total !== newTotal || newTotal > 0){
      setTotal(newTotal)
      onChange(field.name,newTotal,'total')  
    }
    
    // eslint-disable-next-line
  },[fieldCountable])

  useEffect(()=>{
    //console.log('update total in field',field.name,field,total)
    //setTotal(getTotalinFieldCountable(field) || getValueFromAnswers(field) || 0)
    //setTotal(getTotalinFieldCountable(field) || 0)
    if(field.to_total){
      reloadField(field.to_total)
    }
    
    if(total === 0){
      onChange(field.name,0,'total')
    }

    // eslint-disable-next-line
  },[total])



  if(parent === 'fieldSet' && field.to_total ){
    return(
      <Box sx={{backgroundColor:'#F5F5F5',border:'1px solid #EDEDED',width:'100%',padding:3,borderRadius:3}}>
        {isDebug && field.name + ' - '+field.valueSaveDate+' - '+field.value}
        <Box><Typography sx={{fontSize:'16px',fontWeight:'500',color:'black'}}>{field.label} </Typography></Box>
        <Box><Typography sx={{color:'#ED5052',fontSize:'22px',fontWeight:'600'}}>

            {isNaN(total) && '0 € (erreur)'}
            {!isNaN(total) && total.toLocaleString() + ' €'}
          </Typography></Box>
      </Box>)
  }

  if((parent === 'fieldSet' && !field.to_total) || parent === 'formulaireLayout'){
    return(
      <Box sx={{backgroundColor:'#ED5052',border:'1px solid #C5282A',width:'100%',padding:3,borderRadius:3}}>
        <Box><Typography sx={{fontSize:'16px',fontWeight:'500',color:'white'}}>{field.label}</Typography></Box>
        <Box><Typography sx={{fontSize:'22px',color:'white',fontWeight:'600'}}>
            {isNaN(total) && '0 € (erreur)'}
            {!isNaN(total) && total.toLocaleString() + ' €'}
          </Typography></Box>
      </Box>)
  }

  if(parent === 'subFieldSetCard'){
    return (
      <Box>
        <TextFieldCustom readOnly  name={field.name} label={field.label} value={total.toLocaleString() + '€'} />
      </Box>
    )
  }


    
  
  };

export default FieldTotal;
