import { useTheme } from "@emotion/react";
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { markAsReadNotifications } from "../../services/api";
import { useUser } from "../../services/userContext";
import navItems from "../NavItems/NavItems";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../services/notificationsContext";
import HTMLComponent from "../HTMLComponent/HTMLComponent";

const Alerte = (props) => {

  const {alerte} = props
  const theme = useTheme()
  const {reloadNotifications} = useUser()
  const navigate = useNavigate()

  const {pushNotifications} = useNotifications()
  const [isLoading,setIsLoading] = useState(false)

  const handleClickAlerte = async () => {
    // Redirection
    setIsLoading(true)

    let path 

    path = navItems.find(item => item.form_group_id === alerte.form_group_id)
    if(alerte.form_slug === 'decouverte-point-de-vente'){
      path = {}
      path.link = '/formulaire/decouverte-point-de-vente'
    }

    
    navigate(path ? path.link : '/dashboard')
    await reloadNotifications()
    setIsLoading(false)
   
  }

  const handleViewAlerte = async () => {
    //setAlerte({...alerte,is_read:true})
    setIsLoading(true)
    const response = await markAsReadNotifications(alerte.id)
    if(response.message){
      pushNotifications(response.message,'success')
    }
    await reloadNotifications(true)
    setIsLoading(false)

  }

  return(
    <Box sx={{ backgroundColor: '#F5F5F5', paddingX:4,paddingY:3, border:alerte.is_read === false ? '1px solid #9E9E9E' : '1px solid #EDEDED',borderRadius:'12px',marginY:2}}>
      <Box sx={{display:['inherit','flex','flex'],justifyContent:'space-between',alignItems:'center'}}>
        {!isLoading &&
          <>
        <Box>
          <Box>
           
            <Box sx={{display:'flex',alignItems:'center',gap:1,marginBottom:0.5}}>
              {alerte.is_read === 0 && <InfoIcon sx={{width:"13px",height:"13px", color:theme.palette.primary.main}} /> }
              <Typography sx={{color:theme.palette.primary.main, fontWeight:'500',fontSize:'13px'}}>
                {alerte?.created_at_formatted}
              </Typography>
            </Box>
            <Box sx={{marginBottom:0.5}}>
              <Typography variant="subtitle">
                {alerte?.title}
              </Typography>
            </Box>
            <Box>
                <Typography>
                  <HTMLComponent>
                      {alerte?.content}
                  </HTMLComponent>
                </Typography>
              {alerte.is_read === false &&
                <Typography variant="body1" sx={{textDecoration:'underline',fontWeight:'400',cursor:'pointer',marginTop:1}} onClick={handleViewAlerte}>
                  Marquer comme lu
                </Typography>
              }
            </Box>
            
            
          </Box>
        </Box>
        <Box 
        onClick={handleClickAlerte}
        sx={{display:'flex',color:'#424242',alignItems:'center',gap:1,cursor:'pointer',marginTop:[2,0,0]}}>
          <Typography sx={{fontWeight:400}}>{alerte.action_label}</Typography>
          <ArrowForwardIcon fontSize="small" />
        </Box>
        </>}
        {isLoading && <>
          <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
            <CircularProgress />
          </Box>
          </>}
        
      </Box>
    </Box>
  );
};

export default Alerte;
