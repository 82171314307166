import React, { useContext} from "react";
import { SnackbarProvider, useSnackbar} from 'notistack';


const NotificationsContext = React.createContext();

export const useNotifications = () => {
    return useContext(NotificationsContext)
}

export const NotificationsProvider = ({children}) => {

    const { enqueueSnackbar } = useSnackbar();

    const pushNotifications = (text,type) => {
        if(process.env.REACT_APP_ENV === 'dev'){
            enqueueSnackbar(text,{variant: type || 'default'})
        } 
    }

    return (
            <NotificationsContext.Provider value={{pushNotifications}}>
                {children}
            </NotificationsContext.Provider>
    )
}

export const NotificationsContainer = ({children}) => (
    <SnackbarProvider maxSnack={3}  autoHideDuration={3000} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
        <NotificationsProvider>
            {children}
        </NotificationsProvider>
    </SnackbarProvider>
)
