import React, {useState } from 'react';
import { Box, Button, Grid, Typography} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import Field from '../Field/Field';
import { useForm } from '../../../services/formContext';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useUser } from '../../../services/userContext';
import getSizeField from '../../../services/getSizeField';



const RepeatableFieldSet = (props) => {

  const {field} = props
  const [row,setRow] = useState([])
  const [isInit,setIsInit] = useState(false)
  const {nameForm,saveValue,filterField,form,getRepetableLength, deleteRowInMultiplesValues} =  useForm()
  const {isDebug} = useUser()


  const addRow = (number) =>{

    console.log(field)
    console.log([...row,field])

    for(let i = 0; i < (number || 1);i++){
      setRow(arr => [...arr,field])
    }
    //console.log('Field',field)
    saveValue(field.title,row.length+1,nameForm,'count','count')
  }

  const deleteRow = (idRow) => {
    console.log('Delete row id : ',idRow)
    const newRows = row.filter((r,index)=>index !== idRow)
    setRow(newRows)
    console.log(field)
    deleteRowInMultiplesValues(idRow,nameForm,field.title,row.length-1)
  }

  /* Ajout des ligne a l'initialisation de la page */
  if(!isInit){
    const numberRow = getRepetableLength(field)
    for(let i = 0; i < (numberRow || 1);i++){
      setRow(row => [...row,field])
    }
    setIsInit(true)
  }


  return(
   <Box>
    {isDebug && 'RepeatableFieldSet'}
    {row && row.map((fieldInRow,index)=>(
       <React.Fragment key={index}>
        <Box>
          <Grid  container spacing={2} sx={{marginY:1}}>
          {fieldInRow.fields.map((field,key)=>(
            
            <Grid key={key} item xs={6} md={index > 0 ? getSizeField(field,'repeatableFieldSet',form) - (2 / fieldInRow.fields.length)  : getSizeField(field,'repeatableFieldSet',form) }>
                <Field field={filterField(field,index)} parent={'repeatableFieldSet'}/>
            </Grid>
            
          ))}
          {index > 0 && 
          <Grid item xs={12} md={2} sx={{color:'#880000',display:'flex',gap:0.5, alignItems:'end',paddingBottom:'15px',cursor:'pointer',justifyContent:'center'}} onClick={()=>{deleteRow(index)}}>
              <DeleteOutlineIcon fontSize="small"/>
              <Typography sx={{color:'#880000',fontWeight:500}}>Supprimer</Typography>
          </Grid>}
          </Grid>
          
        </Box>
       </React.Fragment> 

     ))}
       
     <Box sx={{display:'flex',justifyContent:'center',marginY:3}}>
       <Button onClick={()=>{
         addRow(1)
         }} variant="outlined" color="primary" startIcon={<AddIcon />}>
          {field.repeat_label}
        </Button>
     </Box>
   </Box>
)};

export default RepeatableFieldSet;
