import React, { useEffect, useState } from 'react';
import FieldText from '../FieldText/FieldText';
import FieldEmail from '../FieldEmail/FieldEmail';
import FieldSelect from '../FieldSelect/FieldSelect';
import FieldDate from '../FieldDate/FieldDate';
import FieldNumber from '../FieldNumber/FieldNumber';
import FieldTextarea from '../FieldTextarea/FieldTextarea';
import FieldRadio from '../FieldRadio/FieldRadio';
import FieldCheckbox from '../FieldCheckbox/FieldCheckbox';
import RepeatableSubFieldSet from '../RepeatableSubFieldSet/RepeatableSubFieldSet';
import RepeatableField from '../RepeatableField/RepeatableField';
import FieldFile from '../FieldFile/FieldFile';
import FieldArea from '../FieldArea/FieldArea';
import { useForm } from '../../../services/formContext';
import FieldInfo from '../../FieldInfo/FieldInfo';
import FieldTotal from '../../FieldTotal/FieldTotal';
import { replaceIndex } from '../../../services/utils';
import FieldDownload from '../../FieldDownload/FieldDownload';
import FieldFormLink from '../../FieldFormLink/FieldFormLink';

const Field = (props) => {

  const {isRecursive,parent} = props
  const {saveValue,
    needReload,
    nameForm,
    getValue,
    getErrorsMessage,
    responseFormulaire,
    addFieldCountable,
    getValueFromAnswers,
    getMultiplesValues,
    isHiddenField,
    fieldToUpdate,
    finishReloadField,
    getMostRecentDataOrigin,
    testFieldIsRequired,
    resetFieldCountable,
    getSaveDateOfValue,
  } = useForm()
  

  const initFieldValue = (field) => {

    const valueInStorage = getValue(field.name,nameForm,field.type)
    const valueInAnswers = getValueFromAnswers(field)
    /* Init des réponses */

    if(valueInAnswers !== null && getMostRecentDataOrigin() !== 'localStorage'){
      field.value = valueInAnswers
      field.source = 'answers'
    }else{
      field.value = valueInStorage
      field.source = 'storage'
      field.valueSaveDate = getSaveDateOfValue(field.name,nameForm,field.type)
    }

  


   
    

    if(field.type === 'select_multiple' || field.type === 'areas'){
      const length = getValue(field.name,nameForm) || 0
      const valuesMultiples = getMultiplesValues(String(field.name),nameForm,null,Number(length))
      field.value = valuesMultiples
    }

    field.isRequired = field.optional ? testFieldIsRequired(field) : true
    //field.isRequired = testFieldIsRequired(field)

    if(field.type === 'total'){
      //field.value = getTotal(field)
      field.reloadDisabled = true
    }

    if(field.hasOwnProperty('to_total')){
     field.reloadDisabled = true
    }

    // On gère le cas des select multiples
    
    /* Init des errors */
    if(getErrorsMessage(field.name)){
      field.errors = getErrorsMessage(field.name)
    }else{
      field.errors = null
    }

    //console.log('Field',field)

    return field
  }

  const [field,setField] = useState(initFieldValue(props.field))
  const [newValue,setNewValue] = useState('')
  const [updatingValue,setUpdatingValue] = useState(false)
  const [isHidden,setIsHidden] = useState(false)

  const handleChange = (name,value,type,source) => {

    // A refacto, evite les boucles infinies
    if(field.type === 'radio'){
      console.log('Change On Radio')
      resetFieldCountable()
    }
    saveValue(name,value,nameForm,type ? type : 'text', source ? source : 'form',)
    updateFieldWithValue(value)
  }

  const updateFieldWithValue = (value) => {
    setUpdatingValue(true)
    setNewValue(value)
  }

  // Modifie les données et update les totaux
  useEffect(()=>{
    const updateValue = () => {
      if(updatingValue){
        setUpdatingValue(false)
        setField({...field,value:newValue})
        if(field.to_total){
          const money = parseInt(newValue)
          let newField = field
          newField.value = money
          newField.to_total = (field.index === 0 || field.index) ? replaceIndex(newField.to_total,field.index.toString()) : newField.to_total 
          addFieldCountable(newField)
        }
      } 
    }
    updateValue()
    
    // eslint-disable-next-line
  },[newValue])


  // Ajoute le field si il est a compté
  useEffect(()=>{  
    if(field.to_total && field.value){
      addFieldCountable(field)
    }
    // eslint-disable-next-line
  },[field])


  // Ajoute les messages d'erreur a l'envoie du formulaire
  useEffect(()=>{
    if(field){
      if(getErrorsMessage(field.name)){
        //console.log('Debug In UseEffect',field.name,{...field,flag:'update-response',errors:getErrorsMessage(field.name)})
        setField({...field,flag:'error-true',errors:getErrorsMessage(field.name)})
      }else{
        setField({...field,flag:'error-false',errors:false})
      }
    }
    
    // eslint-disable-next-line
  },[responseFormulaire])
 

  // Cache les élements
  useEffect(()=>{
    //setField(initFieldValue(props.field))
    if(!field.reloadDisabled){
      setField(initFieldValue(props.field))
    }else{
      const newValue = initFieldValue(props.field).value
      setField({...field,isRequired:testFieldIsRequired(field),value:newValue})
    }
    setIsHidden(isHiddenField(field))
   // eslint-disable-next-line
  },[needReload])


  // Reload les éléments qui sont call ailleurs
  useEffect(()=>{

    if(field.name === fieldToUpdate || field.title === fieldToUpdate){
      //console.log('reload field',field.name,field.title)
      // Gérer le cas où on supprimer une row pour reload les totaux
      const newField = initFieldValue(props.field)
      setField({...newField})
      finishReloadField()
    }

    // eslint-disable-next-line
  },[fieldToUpdate])

  const propsField = {
    field:field,
    onChange:handleChange,
    parent:parent
  }

  if(field && !isHidden){
    if(field.type !== 'field_set' && field.repeatable && !isRecursive){
      /* Traitement d'un champs répétable */
      return <RepeatableField field={field} isRecursive={true} onChange={handleChange}/>
    }


    if(field.type === 'field_set' && field.repeatable){
      return <RepeatableSubFieldSet field={field} />
    }
  
    if(field.type === 'text'){
      return <FieldText {...propsField}/>
    }

    if(field.type === 'file'){
      return <FieldFile {...propsField}/>
    }
    
    if(field.type === 'tel'){
      return <FieldNumber {...propsField}/>
    }

    if(field.type === 'email'){
      return <FieldEmail {...propsField}/>
    }

    if(field.type === 'select'){
      return  <FieldSelect {...propsField}/>
    }

    if(field.type === 'select_multiple'){
      return  <FieldSelect {...propsField}/>
    }

    if(field.type === 'date'){
      //console.log('Field Date in Field',field,propsField)
      return  <FieldDate {...propsField}/>
    }

    if(field.type === 'number' || field.type === 'money'){
      return  <FieldNumber {...propsField}/>
    }


    if(field.type === 'total'){
      return <FieldTotal {...propsField}/>
    }

    if(field.type === 'textarea'){
      return  <FieldTextarea {...propsField} />
    }

    if(field.type === 'radio'){
      return <FieldRadio {...propsField}/>
    }

    if(field.type === 'checkbox'){
      return <FieldCheckbox {...propsField}/>
    }

    if(field.type === 'areas'){
      return <FieldArea {...propsField}/>
    }

    if(field.type === 'info'){
      return <FieldInfo field={field} />
    }

    if(field.type === 'download'){  
      
      return <FieldDownload  {...propsField} />
    }

    if(field.type === 'form_link'){  
      
      return <FieldFormLink {...propsField} />
    }
    
  }
 
};


export default Field;
