import React from 'react';

import './BackgroundFullSize.scss';

const BackgroundFullSize = (props) => (
  <div className="BackgroundFullSize">
        <img src={props.src} alt={props.alt} />
  </div>
);
export default BackgroundFullSize;
